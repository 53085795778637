import { IPublicClientApplication } from "@azure/msal-browser";
import { jwtDecode } from "jwt-decode";

import { captureError } from "@:lite/helpers/captureError";

export const redirectToLogin = (
  instance: IPublicClientApplication,
): Promise<void> => {
  return instance.acquireTokenRedirect({ scopes: ["User.Read"] });
};

// JWT payload https://jwt.io/introduction
export type JWT = {
  exp: number;
};
export const parseJwt = (token: string | null): JWT | null => {
  if (!token) return null;
  try {
    return jwtDecode(token);
  } catch (error) {
    captureError(error);
    return null;
  }
};
