import React from "react";
import * as Sentry from "@sentry/react";

const SentryFallback: Sentry.FallbackRender = () => {
  return (
    <div
      style={{
        backgroundColor: "#FCF4F4",
        borderRadius: "4px",
        padding: "10px",
      }}
    >
      Oops! We&#39;ve encountered an error, but we got it now and are working on
      a fix
    </div>
  );
};

export default SentryFallback;
