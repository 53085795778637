import React, { FC, memo, useState } from "react";
import { DocumentNode, useMutation, useQuery } from "@apollo/client";
import get from "lodash/get";
import moment from "moment-timezone";

import edit from "@:lite/style/img/icons/edit.svg";
import { Note } from "@:lite/types";
import { useSettingsStore, useUserData } from "@:lite/zustand/useSettingsStore";

import stylesSidePanel from "../sidePanel.module.scss";
import styles from "./index.module.scss";
import textStyles from "@:lite/style/textStyles.module.scss";

/**
 * Notes can be added to 3 different entities: WorkOrderNote, VesselNote
 * They're structure and functionality are identical in the app. For frontend, they only vary in the graphql queries&mutations + variables
 * The data-layer can therefore be abstracted away from the component and will depend on implementaiton
 */
type Props = {
  title: string;
  queryParams: NotesQueryParams;
  mutationParams: NotesMutationParams;
  noteParentId: string;
  readOnly: boolean;
};

export type NotesQueryParams = {
  query: DocumentNode;
  param: "workOrderId" | "vesselId" | "orderNumber";
  payloadIdentifier: NotesQueryPayloadIdentifier;
};

// Identifies levels in tree-structure building on how graphQL stitching works
export type NotesQueryPayloadIdentifier = {
  identifier1: "vesselWorkOrder" | "workOrderById" | "workOrderNotes";
  identifier2: "vesselNotes" | "";
};

export type NotesMutationParams = {
  update: DocumentNode;
  create: DocumentNode;
  createParam: "workOrderId" | "vesselId";
  updateParam: "orderNoteId" | "vesselNoteId";
};

const OrderNotes: FC<Props> = ({
  title,
  mutationParams,
  queryParams,
  noteParentId,
  readOnly,
}) => {
  const userData = useUserData();
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);

  const [updateNote] = useMutation(mutationParams.update);
  const [createNote, { loading }] = useMutation(mutationParams.create);
  const [newNote, setNewNotes] = useState("");
  const [editedNoteText, setEditedNoteText] = useState("");
  const [notes, setNotes] = useState<Note[]>([]);
  const [currentlyEditingNoteId, setCurrentlyEditingNoteId] =
    useState<string>();

  const queryVariables: Record<string, string> = {};
  const createVariables: Record<string, string> = {};
  const updateVariables: Record<string, string> = {};

  const setupQueryVariables = () => {
    queryVariables[queryParams.param] = noteParentId;
  };
  const setupCreateVariables = () => {
    createVariables[mutationParams.createParam] = noteParentId;
    createVariables.facilityId = selectedFacility;
    createVariables.issuedBy = userData.mail;
    createVariables.text = newNote;
  };
  const setupUpdateVariables = () => {
    updateVariables[mutationParams.createParam] = noteParentId;
    updateVariables[mutationParams.updateParam] = currentlyEditingNoteId ?? "";
    updateVariables.facilityId = selectedFacility;
    updateVariables.issuedBy = userData.mail;
    updateVariables.text = editedNoteText;
  };

  setupQueryVariables();
  setupCreateVariables();

  const handleOnRefetchCompleted = (queryRes) => {
    const objectPath = `${queryParams.payloadIdentifier.identifier1}${
      queryParams.payloadIdentifier.identifier2
        ? `.${queryParams.payloadIdentifier.identifier2}`
        : ""
    }`;
    setNotes(get(queryRes, objectPath));
  };

  const { refetch } = useQuery(queryParams.query, {
    variables: queryVariables,
    onCompleted: (queryRes) => handleOnRefetchCompleted(queryRes),
  });

  const handleChangeNewNote = (value: string) => {
    setNewNotes(value);
  };

  const addNote = async () => {
    if (newNote !== "") {
      await createNote({ variables: createVariables });
      setTimeout(() => refetch(queryVariables), 1700);
      setTimeout(() => refetch(queryVariables), 4000);
      setNewNotes("");
    }
  };

  const editNote = async (noteId: string) => {
    if (editedNoteText !== "") {
      setupUpdateVariables();

      await updateNote({
        variables: updateVariables,
      });
      const updatedNotes = notes.map((el) => {
        return el.id === noteId ? { ...el, text: editedNoteText } : el;
      });
      setNotes(updatedNotes);
      setTimeout(() => refetch(queryVariables), 1700);
      setTimeout(() => refetch(queryVariables), 4000);
      setCurrentlyEditingNoteId("");
    }
  };

  const renderEachNote = (note: Note) => {
    return (
      <div key={note.id} className={styles.noteMessage}>
        {!readOnly && currentlyEditingNoteId === note.id ? (
          <div className={styles.noteInput}>
            <textarea
              className={styles.noteInput}
              value={editedNoteText}
              onChange={(e) => setEditedNoteText(e.target.value)}
              onBlur={() => editNote(note.id)}
            />
          </div>
        ) : (
          <>
            <div>
              <div className={styles.noteMessageDate}>
                {`${note.modifiedBy}: ${moment(note.lastModified).format(
                  "DD-MM-YYYY HH:mm",
                )}`}
              </div>
              <div className={styles.noteMessageValue}>{note.text}</div>
            </div>
            {!readOnly && (
              <button
                onClick={() => {
                  setCurrentlyEditingNoteId(note.id);
                  setEditedNoteText(note.text);
                }}
                type="button"
                title="Edit note"
              >
                <img src={edit} alt="edit" />
              </button>
            )}
          </>
        )}
      </div>
    );
  };

  // For Work Orders, don't show the .specifyDescription along other notes because
  // we are already showing that field in the Description.
  const filteredNotes = !queryVariables?.workOrderId
    ? notes
    : notes?.filter((note) => !note.specifyDescription);

  if (!readOnly || filteredNotes?.length > 0) {
    return (
      <div className={styles.notes}>
        <h3
          className={`${textStyles.secondaryText} ${stylesSidePanel.sectionHeader}`}
        >
          {title}
        </h3>
        {filteredNotes &&
          filteredNotes.map((note: Note) => renderEachNote(note))}
        <div className={styles.noteInput}>
          {loading ? (
            <div className={styles.noteMessageValue}>Saving...</div>
          ) : (
            !readOnly && (
              <textarea
                className={styles.noteInput}
                value={newNote}
                onChange={(e) => handleChangeNewNote(e.target.value)}
                placeholder="Type note..."
                onBlur={() => addNote()}
              />
            )
          )}
        </div>
      </div>
    );
  }
  return null;
};
export default memo(OrderNotes);
