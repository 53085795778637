type NotificationFromBackend = {
  message: string;
  messageType: string;
  recipient: string;
  payload: string;
  payloadType: string;
  scope: string;
};

export default class NotificationResolver {
  static resolve(event: NotificationFromBackend): void {
    const eventType = event?.messageType?.trim();
    if (eventType === "RentalAgreementToQuayConnectedToShorePower") {
      // console.log("shore", { event });
    }
  }
}
