import React, { memo, useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";

import {
  useSettingsStore,
  useVisibleDays,
} from "@:lite/zustand/useSettingsStore";

import styles from "./index.module.scss";

type Props = {
  elementWidth: number;
};

const TimelineGuides: React.FC<Props> = ({ elementWidth }) => {
  const [autoRefresh, forceAutoRefresh] = useState(0);
  const visibleDays = useVisibleDays();
  const { visibleStartDate } = useSettingsStore(
    (state) => state.visibleDateRange,
  );

  // as a consequence of refreshing the data, it will update the blue background area too
  useEffect(() => {
    const interval = setInterval(
      () => forceAutoRefresh(autoRefresh + 1),
      2 * 60 * 1000,
    );
    return () => {
      clearInterval(interval);
    };
  }, [autoRefresh]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const generateVerticalLinesGrid = (total: number, elementWidth: number) => {
    const result = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < total; i++) {
      result.push({
        id: `grid-${i}`,
        width: `${elementWidth * i}px`,
        borderRight: "1px solid #d5d5d5",
        zIndex: 0,
      });
    }

    return result;
  };

  const timeGuides = useMemo(() => {
    const diffVisibleStartToNow = moment().diff(visibleStartDate, "minutes");

    // Calculations in minutes
    const widestPossibleRange = visibleDays * 24 * 60;
    let blueWidth = 0; // Base case. We are in the future, diffVisibleStartToNow is < 0
    if (diffVisibleStartToNow > 0) {
      // we are in the future
      if (diffVisibleStartToNow > widestPossibleRange) {
        blueWidth = widestPossibleRange;
      } else {
        blueWidth = diffVisibleStartToNow;
      }
    }

    // Convert to pixels
    const widthForVerticalLineDay1 = 24 * 60 * (elementWidth / 60);

    return [
      {
        id: "bg",
        width: `${(elementWidth / 60) * blueWidth}px`,
        background: "var(--timelineBgcurrentTime)",
      },
      {
        id: "day1",
        width: widthForVerticalLineDay1,
        borderRight: "2px dashed #5A5A5A", // If you change the width of the border, double check if it dissappears when zooming out
        zIndex: 1,
      },
      // {  // For 3-days-view. Make it dynamic if needed.
      //   id: "day2",
      //   width: day2Width,
      //   borderRight: "1.8px dashed #5A5A5A",
      // },
      ...generateVerticalLinesGrid(visibleDays * 24, elementWidth),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoRefresh, visibleStartDate, visibleDays, elementWidth]);

  return (
    <>
      {timeGuides.map((guideStyles) => (
        <div
          key={guideStyles.id}
          className={styles.timeguide}
          style={guideStyles}
        />
      ))}
    </>
  );
};

export default memo(TimelineGuides);
