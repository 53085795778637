/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  CSSProperties,
  forwardRef,
  memo,
  useCallback,
  useMemo,
  useRef,
} from "react";
import omit from "lodash/omit";

import CircularLoading from "@:lite/components/common/circularLoading";
import { LOADING_STYLE } from "@:lite/helpers/constants";
import {
  isStatusNotFinished,
  isStatusRequestedOrPlanned,
} from "@:lite/helpers/isStatus";
import {
  OrderStatusColors,
  OrderStatusEnum,
} from "@:lite/helpers/OrderStatusColors";
import { useDraggableItem } from "@:lite/hooks/ui/timeline/useDraggableItem";
import { useNeedToMakeItemSecondary } from "@:lite/hooks/ui/timeline/useNeedToMakeItemSecondary";
import { useOrderSize } from "@:lite/hooks/ui/timeline/useOrderSize";
import ArrowIcon from "@:lite/style/img/arrow.svg";
import shorePowerOffIcon from "@:lite/style/img/icons/shorepower_off.svg";
import shorePowerOnIcon from "@:lite/style/img/icons/shorepower_on.svg";
import {
  DraggableItemProps,
  DraggableItemType,
  OnDraggableSideDropFn,
  OnDropPayload,
  Vessel,
  VisualColorTheme,
} from "@:lite/types";
import { useInProgressStore } from "@:lite/zustand/useInProgressStore";
import {
  DEFAULT_ALLOCATION_HEIGHT,
  setSidePanelOrder,
  toggleCollapsableVesselAllocation,
  useOrderStore,
} from "@:lite/zustand/useOrderStore";
import { useSettingsStore, useUserData } from "@:lite/zustand/useSettingsStore";

import OrderCardDraggableSides from "../draggableSide/orderCardDraggableSides";
import VesselDroppableGroup from "./vesselDroppableGroup";

import styles from "../index.module.scss";
import textStyles from "@:lite/style/textStyles.module.scss";

type Props = {
  vessel: Vessel;
  elementWidth: number;
  visibleDays: number;
  position: number;
  selectedDate: Date;
  timeLine: string[];
  onPullEnd: OnDraggableSideDropFn;
  onDrop: (result: OnDropPayload) => void;
};

const VesselAllocation = forwardRef<HTMLInputElement, Props>(
  (
    {
      vessel,
      elementWidth,
      visibleDays,
      position,
      selectedDate,
      timeLine,
      onPullEnd,
      onDrop,
    },
    ref,
  ) => {
    const sidePanelOrder = useOrderStore((state) => state.sidePanelOrder);
    const vesselChangeInProgress = useInProgressStore(
      (state) => state.vesselChangeInProgress,
    );
    const woChangeInProgress = useInProgressStore(
      (state) => state.woChangeInProgress,
    );

    const { visibleStartDate, visibleEndDate } = useSettingsStore(
      (state) => state.visibleDateRange,
    );

    const { userCompany } = useUserData();
    const isOurItem = userCompany === vessel.customerId;

    const orderInfo = useRef<HTMLDivElement>(null);
    const inDetailedView = useSettingsStore((state) => state.inDetailedView);

    const isRequestedOrPlanned = isStatusRequestedOrPlanned(vessel?.status);
    const isNotFinished = isStatusNotFinished(vessel?.status);

    const needToMakeItemSecondary = useNeedToMakeItemSecondary({
      order: vessel,
    });

    const isLoading1 = vesselChangeInProgress.some(
      (each) => each.id === vessel.id,
    );
    const isLoading2 = woChangeInProgress.some((each) => {
      return vessel?.workOrders?.some((eachWo) => eachWo.id === each.id);
    });

    const isLoading = isLoading1 || isLoading2;

    const draggableProps: DraggableItemProps =
      vessel.quay !== "Not Allocated"
        ? {
            canDrag: !isLoading,
            quayId: vessel.quay,
            excludeDragScopes: ["not allocated"],
          }
        : { canDrag: !isLoading, quayId: vessel.quay };

    const draggableItemType = DraggableItemType.Order;
    const [{ isDragging }, drag] = useDraggableItem({
      type: draggableItemType,
      timeLine,
      orderId: vessel.id,
      ...draggableProps,
      canDrag: isOurItem && !isLoading && isRequestedOrPlanned,
    });

    const getVisualColorTheme = useCallback(
      (status: string): VisualColorTheme => {
        return isLoading
          ? LOADING_STYLE
          : OrderStatusColors.getStatus(status as OrderStatusEnum);
      },
      [isLoading],
    );

    const { width, adjustedWidth } = useOrderSize({
      selectedDate,
      order: vessel,
      elementWidth,
      visibleDays,
    });

    const hasAttachedWos =
      vessel.workOrders?.length > 0 &&
      vessel.workOrders?.some((item) => !item?.hideInTimeline);
    // const hasExpandedAttachedWos = orderStyles.groupHeight > 0;

    const orderStyles = useMemo(() => {
      // TODO: Fix this properly .. why adjustedWidth sometimes is less than width? (it should be fixed in intervalGraphColoring file)
      let finalWidth = adjustedWidth > width ? adjustedWidth : width;

      const finalStartDate = vessel?.startDate
        ? vessel?.startDate
        : vessel.plannedStartDate;

      // Calculations in minutes
      const startDiff = -visibleStartDate.diff(finalStartDate, "minutes");
      const shift = startDiff > 0 ? startDiff : 0;
      // To pixels:
      const left = shift * (elementWidth / 60);
      const timelineContainerWidth = (elementWidth / 2) * 48 * 2; // TODO: don't hardcode
      if (left + finalWidth > timelineContainerWidth) {
        finalWidth = timelineContainerWidth - left; // To prevent DetailedView allocations to overflow the right edge of the timeline
      }

      return {
        groupHeight: vessel?.groupHeight,
        groupTop: vessel?.groupTop,
        groupLeft: left,
        groupWidth: finalWidth,
        groupColor: getVisualColorTheme(vessel.status).color,
        backgroundGroupColor: getVisualColorTheme(vessel.status).background,
        borderColor: getVisualColorTheme(vessel.status).color,
        borderWidth: sidePanelOrder?.id === vessel.id ? "4px" : "2px",
        boxShadow:
          sidePanelOrder?.id === vessel.id
            ? "0px 8px 10px rgba(90, 90, 90, 0.14), 0px 3px 14px rgba(90, 90, 90, 0.12), 0px 4px 5px rgba(90, 90, 90, 0.2)"
            : "none",
        width: finalWidth || 0,
        height: vessel?.innerAllocHeight,
        left,
        top: vessel?.visualTop,
      };
    }, [
      adjustedWidth,
      width,
      vessel?.startDate,
      vessel.plannedStartDate,
      vessel?.groupHeight,
      vessel?.groupTop,
      vessel.status,
      vessel.id,
      vessel?.innerAllocHeight,
      vessel?.visualTop,
      visibleStartDate,
      elementWidth,
      getVisualColorTheme,
      sidePanelOrder?.id,
    ]);

    if (!sidePanelOrder?.id) {
      orderStyles.boxShadow = "none";
    }

    const { orderContentWrapperStyles, contentRootStyles } = useMemo(() => {
      return {
        orderContentWrapperStyles: {
          backgroundColor: isDragging
            ? "transparent"
            : getVisualColorTheme(vessel.status).background,
          width: Math.max(width - 3, 0),
        },
        contentRootStyles: {
          pointerEvents:
            isDragging || needToMakeItemSecondary
              ? "none"
              : ("inherit" as React.CSSProperties["pointerEvents"]),
        } as CSSProperties,
      };
    }, [
      isDragging,
      getVisualColorTheme,
      vessel.status,
      width,
      needToMakeItemSecondary,
    ]);

    const widthCss = "fit-content"; // inDetailedView ? "fit-content" : "100%";
    let flexDirection = "column";
    let alignItems = "";

    if (orderStyles.height < DEFAULT_ALLOCATION_HEIGHT) {
      flexDirection = "row";
      alignItems = "center";
    }

    const wrapperStyles = {
      width: widthCss,
      flexDirection,
      alignItems,
      display: "flex",
    };

    const orderInfoComponent = useMemo(() => {
      const textStyle = {
        overflow: "hidden",
        fontWeight: 700,
        color: isLoading ? "#C4C4C4" : "inherit",
      };
      const eventInfo = (
        <div key={vessel.id} className={styles.eventInfo}>
          <span style={textStyle} className={textStyles.primaryText}>
            {`${vessel.vesselName || "N/A"} - ${vessel.customerName || ""}`}
          </span>
        </div>
      );

      return eventInfo;
    }, [isLoading, vessel.id, vessel.vesselName, vessel.customerName]);

    const onAllocationClick = (e) => {
      if (isLoading || !isOurItem) {
        return;
      }
      // Handle edge case. "onAllocationClick" gets triggered also when clicking away from the 3-dots menu, or click modal buttons
      if (
        e.target?.classList?.contains("collapse-toggle") ||
        e.target?.classList?.contains("popup-overlay") ||
        e.target?.classList?.value?.includes("modalButton")
      ) {
        return;
      }

      // setTimeout fixes the race condition in the click event handling that
      // It allows the previous active sidepanel to have time detect clickaway and close itself + saving data before opening this one
      setTimeout(() => {
        setSidePanelOrder({ id: vessel?.id });
      }, 5);
    };

    return (
      <>
        <VesselDroppableGroup
          key={vessel.id}
          orderId={vessel.id}
          orderStyles={orderStyles}
          accept={[DraggableItemType.SubWorkOrder]}
          onDrop={onDrop}
        />

        {/*  It needs to be outside of tableOderContainer, or position:sticky won't work inside of a container with position: absolute */}
        <div
          className="floatingLabel vesselLabel"
          ref={ref}
          data-allocation-width={orderStyles.width - 30}
          data-allocation-offsetleft={orderStyles.left}
          style={{
            ...orderContentWrapperStyles,
            ...{
              position: "absolute",
              top: hasAttachedWos ? orderStyles.top + 8 : orderStyles.top + 8,
              left: orderStyles.left + 20,
              width: Math.max(orderStyles.width - 0, 30),
              backgroundColor: "transparent",
              opacity: isOurItem ? 1 : 0.65,
              zIndex: 2,
              overflow: "hidden",
            },
          }}
        >
          <div ref={orderInfo} style={wrapperStyles as React.CSSProperties}>
            {orderInfoComponent}
          </div>
        </div>

        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          className={styles.tableOrderContainer}
          style={{
            marginLeft: position,
            height: orderStyles.height,
            top: orderStyles.top,
            left: orderStyles.left,
          }}
          onClick={onAllocationClick}
          tabIndex={0}
          role="button"
        >
          {isOurItem && (
            <OrderCardDraggableSides
              {...draggableProps}
              canDrag={!isLoading}
              orderId={vessel.id}
              canDragStartSide={isRequestedOrPlanned}
              canDragStopSide={isNotFinished}
              onDrop={onPullEnd}
              height={`${orderStyles.height}px`}
              parentItemType={draggableItemType}
              orderRegularWidth={width}
              orderExpandedWidth={orderStyles.width}
              inDetailedView={inDetailedView}
            />
          )}

          <div className={styles.dragableElement} style={contentRootStyles}>
            <div
              className={styles.tableEvent}
              style={{
                ...omit({ ...orderStyles }, ["top", "left"]),
                ...(hasAttachedWos
                  ? {
                      borderWidth: `${orderStyles.borderWidth} ${orderStyles.borderWidth} ${orderStyles.borderWidth} 6px`,
                    }
                  : {}),
                borderTopLeftRadius: hasAttachedWos ? "6px" : "0px",
                borderColor: width <= 0 ? "#DD877E" : orderStyles.borderColor,
                position: "absolute",
                opacity: isOurItem ? 1 : 0.65,
                cursor: isLoading
                  ? "progress"
                  : isRequestedOrPlanned && isOurItem
                    ? "grab"
                    : isOurItem
                      ? "pointer"
                      : "default",
                boxShadow: isDragging
                  ? "inset 0px 0px 10px 0px rgba(0, 0, 0, 0.3)"
                  : orderStyles.boxShadow,
              }}
              ref={drag}
            >
              <div style={{ ...orderContentWrapperStyles }}>
                {isLoading && <CircularLoading />}

                <div>
                  {!isStatusRequestedOrPlanned(vessel.status) && (
                    <img
                      className="collapse-toggle"
                      style={{
                        verticalAlign: "middle",
                        margin: "0 0 1px 0",
                        paddingRight: hasAttachedWos ? "20px" : "4px",
                        cursor: "default",
                        position: "absolute",
                        right: 0,
                        //   top: vessel?.allocationCollapsed ? 19 : 3,
                        top: 3,
                      }}
                      src={
                        vessel?.shorePowerUsage
                          ? shorePowerOnIcon
                          : shorePowerOffIcon
                      }
                      alt="ShorePower status"
                      title={
                        vessel?.shorePowerUsage
                          ? "Connected to ShorePower"
                          : "Not connected to ShorePower"
                      }
                    />
                  )}
                  {vessel?.userHasAccessToVesselSubWOs && hasAttachedWos && (
                    <button
                      type="button"
                      className={`${styles.collapsingButton} collapse-toggle`}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        toggleCollapsableVesselAllocation(vessel.id);
                      }}
                    >
                      <img
                        className="collapse-toggle"
                        style={{
                          margin: "0 3px 1px 0",
                          transform: vessel?.allocationCollapsed
                            ? "rotate(270deg)"
                            : "rotate(90deg)",
                        }}
                        src={ArrowIcon}
                        alt="arrow"
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
);

export default memo(VesselAllocation);
