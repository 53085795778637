import { gql } from "@apollo/client";

export const GET_QUAYS_QUERY = gql`
  query getQuays($facilityId: String!) {
    facility(facilityId: $facilityId) {
      quays {
        id
        name
      }
    }
  }
`;
