import React, { FC, memo, useState } from "react";
import moment, { Moment } from "moment-timezone";

import DateAndTimeField from "@:lite/components/common/formElements/dateAndTimeField";
import InputText from "@:lite/components/common/formElements/inputText";
import DropZone from "@:lite/components/timeline/sidePanel/dropzone";
import { validateStartBeforeStop } from "@:lite/helpers/dateTimeValidator";
import {
  isStatusInvoiced,
  isStatusNotFinished,
  isStatusRequestedOrPlanned,
} from "@:lite/helpers/isStatus";
import {
  OrderStatusColors,
  OrderStatusEnum,
} from "@:lite/helpers/OrderStatusColors";
import {
  OrderUpdatedEventParams,
  WorkOrder as WorkOrderTypes,
} from "@:lite/types";
import { useInProgressStore } from "@:lite/zustand/useInProgressStore";

import {
  CREATE_WORK_ORDER_NOTE,
  GET_WORK_ORDER_NOTES,
  UPDATE_WORK_ORDER_NOTE,
} from "./notes/notesQueriesAndMutations";
import OrderNotes, {
  NotesMutationParams,
  NotesQueryParams,
  NotesQueryPayloadIdentifier,
} from "./notes/orderNotes";

import styles from "./sidePanel.module.scss";
import formStyles from "@:lite/components/common/formElements/customFormStyles.module.scss";
import textStyles from "@:lite/style/textStyles.module.scss";

type Props = {
  wo: WorkOrderTypes;
  setUpdatedWorkOrder: (data: Partial<OrderUpdatedEventParams> | null) => void;
  showDivider?: boolean;
};

const WorkOrderSidePanel: FC<Props> = ({
  wo,
  setUpdatedWorkOrder,
  showDivider = false,
}) => {
  // Notice: We use the props' start and stop date as initial value for this local useState.
  // BUT if new updates come through props (other users doing changes to this vessel), we don't refresh them.
  const [startDate, setStartDate] = useState<Moment | null>(
    wo.plannedStartDate ? moment(wo.plannedStartDate) : null,
  );
  const [stopDate, setStopDate] = useState<Moment | null>(
    wo.plannedStopDate ? moment(wo.plannedStopDate) : null,
  );
  const [poNumber, setPoNumber] = useState<string>(wo?.poNumber);
  const poChangeInProgress = useInProgressStore(
    (state) => state.poChangeInProgress,
  );

  const handleSetPoNumber = (newValue: string) => {
    setPoNumber(newValue);
    setUpdatedWorkOrder({ id: wo.id, poNumber: newValue });
  };

  const handleSetStartDate = (newValue: Moment | null) => {
    setStartDate(newValue);
    setUpdatedWorkOrder({
      id: wo.id,
      plannedStartDate: newValue?.toISOString() || "",
    });
  };

  const handleSetStopDate = (newValue: Moment | null) => {
    setStopDate(newValue);

    setUpdatedWorkOrder({
      id: wo.id,
      plannedStopDate: newValue?.toISOString() || "",
    });
  };

  // .specifyDescription is unique. [0] is fine.
  const specifyDescription = wo?.workOrderNotes?.filter(
    (note) => note?.specifyDescription,
  )?.[0]?.specifyDescription;

  const isStartBeforeStop = validateStartBeforeStop(startDate, stopDate);
  const isInvoiced = isStatusInvoiced(wo.derivedStatus);
  return (
    <>
      {showDivider && (
        <hr style={{ marginTop: "25px", backgroundColor: "#E0E0E0" }} />
      )}
      <div className={styles.infoBlock}>
        <div className={styles.fieldNames}>
          <span className={textStyles.secondaryText}>Work order:</span>
          <span className={textStyles.secondaryText}>Customer:</span>
          <span className={textStyles.secondaryText}>Order Number:</span>
          <span className={textStyles.secondaryText}>MCO:</span>
          <span className={textStyles.secondaryText}>Purchase order:</span>
          <span className={textStyles.secondaryText}>Ordered by:</span>
          <span className={textStyles.secondaryText}>Status:</span>
          <span className={`${textStyles.secondaryText} ${styles.taller}`}>
            Planned start:
          </span>
          <span className={`${textStyles.secondaryText} ${styles.taller}`}>
            Planned end:
          </span>
          <span className={textStyles.secondaryText}>Actual start:</span>
          <span className={textStyles.secondaryText}>Work Order Closed:</span>
          <span className={textStyles.secondaryText}>Description:</span>
        </div>
        <div className={styles.fieldInfo}>
          <span className={textStyles.primaryText}>
            {wo.description || "N/A"}
          </span>
          <span className={textStyles.primaryText}>
            {wo.customerDisplayName || "N/A"}
          </span>
          <span className={textStyles.primaryText}>{wo?.orderNumber}</span>
          <span
            className={textStyles.primaryText}
          >{`${wo?.mCO?.referenceOrderNumber} / ${wo?.mCO?.referenceOrderLine}`}</span>
          <span
            className={`${textStyles.primaryText} ${formStyles.compactInput}`}
          >
            <InputText
              value={poNumber}
              setValue={handleSetPoNumber}
              placeholder="N/A"
              disabled={isInvoiced}
              error={false}
              errorMessage=""
            />
            {poChangeInProgress.some(
              (o) =>
                o.id ===
                `${wo?.mCO?.referenceOrderNumber}-${wo?.mCO?.referenceOrderLine}`,
            )
              ? " Pending"
              : ""}
          </span>
          <span className={textStyles.primaryText}>
            {wo.orderedBy || "N/A"}
          </span>
          <div className={`${styles.status}`}>
            <aside
              className="status-indicator"
              style={{
                background: `${OrderStatusColors.getColor(
                  wo.derivedStatus as OrderStatusEnum,
                )}`,
              }}
            />
            <span className={`${textStyles.primaryText} status-label`}>
              {OrderStatusColors.getName(wo.derivedStatus as OrderStatusEnum)}
            </span>
          </div>
          {isStatusRequestedOrPlanned(wo?.derivedStatus) ? (
            <DateAndTimeField
              className={styles.nsgDatetimePicker}
              dateValue={startDate}
              handleChange={handleSetStartDate}
              errorJSX={isStartBeforeStop ? undefined : ""}
            />
          ) : (
            <div className={textStyles.primaryText}>
              {startDate.format("DD.MM.YYYY, HH:mm")}
            </div>
          )}
          {isStatusNotFinished(wo?.derivedStatus) ? (
            <DateAndTimeField
              className={styles.nsgDatetimePicker}
              dateValue={stopDate}
              handleChange={handleSetStopDate}
              errorJSX={isStartBeforeStop ? undefined : ""}
            />
          ) : (
            <div className={textStyles.primaryText}>
              {stopDate.format("DD.MM.YYYY, HH:mm")}
            </div>
          )}
          <span className={textStyles.primaryText}>
            {wo.startDate
              ? moment(wo.startDate).format("DD.MM.YYYY, HH:mm")
              : "N/A"}
          </span>
          <span className={textStyles.primaryText}>
            {wo.stopDate
              ? moment(wo.stopDate).format("DD.MM.YYYY, HH:mm")
              : "N/A"}
          </span>
          {!specifyDescription && (
            <span className={textStyles.primaryText}>N/A</span>
          )}
        </div>
      </div>
      {!isStartBeforeStop && (
        <p className="error-message">
          Check that the start date is later than the end date.
          <br /> Give it another try!
        </p>
      )}
      {specifyDescription && (
        <div
          className={`${textStyles.primaryText} ${styles.specifyDescription}`}
        >
          {specifyDescription}
        </div>
      )}
      <OrderNotes
        title="WORK ORDER Notes"
        noteParentId={wo.id}
        readOnly={isInvoiced}
        mutationParams={
          {
            create: CREATE_WORK_ORDER_NOTE,
            update: UPDATE_WORK_ORDER_NOTE,
            createParam: "workOrderId",
            updateParam: "orderNoteId",
          } as NotesMutationParams
        }
        queryParams={
          {
            query: GET_WORK_ORDER_NOTES,
            param: "orderNumber",
            payloadIdentifier: {
              identifier1: "workOrderNotes",
              identifier2: undefined,
            } as NotesQueryPayloadIdentifier,
          } as NotesQueryParams
        }
      />
      {(!isInvoiced || wo?.attachments?.length > 0) && (
        <div className={styles.attachments}>
          <h3 className={`${textStyles.secondaryText} ${styles.sectionHeader}`}>
            Attachments:
          </h3>
          <DropZone
            attachments={wo?.attachments}
            readOnly={isInvoiced}
            orderType="workorder"
            rentLine={wo?.mCO?.referenceOrderLine}
            rentLineNumber={wo?.mCO?.referenceOrderNumber}
          />
        </div>
      )}
    </>
  );
};
export default memo(WorkOrderSidePanel);
