import {
  removeIdFromPoInPogressList,
  removeIdFromVesselInPogressList,
  removeIdFromWoInPogressList,
} from "@:lite/zustand/useInProgressStore";
import { refreshTimeline } from "@:lite/zustand/useOrderStore";

type DomainEvent = {
  readModelId: string;
  scope: string;
  recipient: string;
  eventType: string;
};

const PONumberFinishedTypes = [
  "VESSEL_ORDER_INFORMATION_UPDATED_FROM_M3_COMPLETED",
  "VESSEL_ORDER_INFORMATION_UPDATED_FROM_M3_FAILED",
  "WORK_ORDER_INFORMATION_UPDATED_FROM_M3_COMPLETED",
  "WORK_ORDER_INFORMATION_UPDATED_FROM_M3_FAILED",
];

export default class EventResolver {
  static resolve(event: DomainEvent): void {
    if (PONumberFinishedTypes.includes(event.eventType)) {
      removeIdFromPoInPogressList(event.readModelId);
      // This is for the case when you are not doing the update yoursel, it is received via notifications
      refreshTimeline();
      return;
    }
    switch (event.eventType) {
      case "WORK_ORDER_UPDATED_FROM_M3_COMPLETED":
        removeIdFromWoInPogressList(event.readModelId);
        break;
      case "WORK_ORDER_UPDATED_FROM_M3_FAILED":
        removeIdFromWoInPogressList(event.readModelId);
        break;
      case "VESSEL_ADDED_TO_QUAY_COMPLETED":
        removeIdFromVesselInPogressList(event.readModelId);
        break;
      case "VESSEL_ADDED_TO_QUAY_FAILED":
        removeIdFromVesselInPogressList(event.readModelId);
        break;
      default:
    }
  }
}
