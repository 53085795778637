/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { Fade, Snackbar } from "@mui/material";

import buttons from "@:lite/style/buttons.module.scss";
import textStyles from "@:lite/style/textStyles.module.scss";

const AppVersionCheck = () => {
  const [newerVersion, setNewerVersion] = useState(false);
  useEffect(() => {
    const checkVersion = async () => {
      console.debug("checking if newer version available");
      if (window.PB_RELEASE_SHA && window.PB_RELEASE_SHA?.length > 0) {
        try {
          // Because I can't figure out nginx rule to not cache this.
          // random number between 1 and 1,000,000
          const cacheBusting = Math.floor(Math.random() * 1000000) + 1;
          const response = await fetch(`/latest.json?v=${cacheBusting}`);
          if (!response.ok) {
            throw new Error("Network response to fetch latest.json was not ok");
          }
          const latest = await response.json();
          const latestSha = latest?.sha;
          if (
            latestSha &&
            latestSha.length > 0 &&
            window.PB_RELEASE_SHA !== latest.sha
          ) {
            setNewerVersion(true);
          }
        } catch (error) {
          console.error(`Fetch error: ${error}`);
        }
      }
    };

    // Perform the check once initially
    checkVersion();

    // Then check every 30 minutes
    const intervalId = setInterval(checkVersion, 30 * 60 * 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [setNewerVersion]);

  return newerVersion ? (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      TransitionComponent={Fade}
      open={newerVersion}
      autoHideDuration={6000}
    >
      <div className="snack-bar">
        <span
          className={`${textStyles.primaryText}`}
          onClick={() => window.location.reload()}
          style={{ cursor: "pointer" }}
        >
          New version available. Click to refresh.
        </span>

        <button
          className={buttons.modalButton}
          type="button"
          style={{ marginTop: "2px" }}
          onClick={() => {
            setNewerVersion(false);
          }}
        >
          dismiss
        </button>
      </div>
    </Snackbar>
  ) : null;
};

export default AppVersionCheck;
