import React, { FC, useEffect, useState } from "react";
import useLocalStorageState from "use-local-storage-state";

import checkboxBorder from "@:lite/style/img/icons/checkboxBorder.svg";
import checkboxChecked from "@:lite/style/img/icons/checkedCheckbox.svg";
import { SelectSingleProps, SelectWithcolor } from "@:lite/types";

import PopUp from "../popUp";
import ArrowSVG from "./arrow";
import CheckSVG from "./checkIcon";

import st from "../customFormStyles.module.scss";
import styles from "./index.module.scss";

type Props = {
  placeholder: string;
  options: SelectSingleProps[] | SelectWithcolor[];
  activeOptions: SelectSingleProps[] | SelectWithcolor[];
  withPreselectionToggle?: boolean;
  onChange: (values: SelectSingleProps[] | SelectWithcolor[]) => void;
  allButtonName: string;
  noneButtonName: string;
  preselected: SelectWithcolor[] | SelectSingleProps[];
  storageKey: string;
};
const CustomMultiSelect: FC<Props> = ({
  placeholder,
  options,
  activeOptions,
  withPreselectionToggle = false,
  onChange,
  allButtonName,
  noneButtonName,
  preselected,
  storageKey,
}) => {
  const [isMenuOpen, setisMenuOpen] = useState(false);

  // TODO: This was before Zustand. Delete me:
  // We keep track of the user's selection, saving it in localStorage. A change of date or base should not change the filter.
  // As a bonus, this hook syncs across tabs too
  // const [selectedOptions, setSelectedOptions] = useLocalStorageState<
  //   SelectWithcolor[] | SelectSingleProps[]
  // >(storageKey, { defaultValue: [] });

  // Auto-preselection is enabled by default
  // Only when the user is intentional to pick certain options, we disable the auto-preselection
  const [isAutoPreselecting, setAutoPreselecting] = useLocalStorageState(
    `${storageKey}-is-auto-preselecting`,
    { defaultValue: true },
  );

  // Auto-preselect engine is here: it will update the selected options based on "preselected" prop. Initial load and re-renders.
  useEffect(() => {
    if (isAutoPreselecting) {
      onChange(preselected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoPreselecting, JSON.stringify(preselected)]);

  const toggleAutoPreselecting = () => {
    setAutoPreselecting(!isAutoPreselecting);
    if (!isAutoPreselecting) {
      onChange(preselected || []);
    }
  };

  const setMenuOpen = () => {
    setisMenuOpen(!isMenuOpen);
  };

  const handleChange = (v: SelectWithcolor | SelectSingleProps) => {
    let newArr = [...activeOptions];
    // Remove the option if it was selected previously
    if (newArr.find((el) => v.value === el.value)) {
      newArr.splice(
        newArr.findIndex((i) => i.value.toString() === v.value.toString()),
        1,
      );
    } else {
      newArr = [...activeOptions, v];
    }
    onChange(newArr);
    setAutoPreselecting(false);
  };

  const toggleAll = () => {
    setAutoPreselecting(false);
    if (options.length === activeOptions.length) {
      // All was selected, then deselect all:
      onChange([]);
    } else {
      // Select all possible options
      onChange(options);
    }
  };

  const renderOptions = () => {
    return (
      <div className={styles.menuList}>
        <button
          type="button"
          className={st.customSelectElement}
          onClick={toggleAll}
          style={
            options.length === activeOptions.length
              ? { background: "#f7f7f7" }
              : {}
          }
        >
          <span />
          <div>
            {options.length === activeOptions.length
              ? noneButtonName
              : allButtonName}
          </div>
        </button>
        {options.map((el) => (
          <button
            type="button"
            onClick={() => handleChange(el)}
            key={el.label}
            className={st.customSelectElement}
            style={
              activeOptions.find((f) => f.value === el.value)
                ? { background: "#f7f7f7" }
                : {}
            }
          >
            {!(el as SelectWithcolor).color ? (
              <span>
                <img
                  src={
                    activeOptions.find((f) => f.value === el.value)
                      ? checkboxChecked
                      : checkboxBorder
                  }
                  alt="checkbox"
                />
              </span>
            ) : (
              <span
                style={{
                  backgroundColor: `${(el as SelectWithcolor).color}`,
                }}
              >
                {activeOptions.find((f) => f.value === el.value) && (
                  <CheckSVG />
                )}
              </span>
            )}
            <div>{el.label}</div>
          </button>
        ))}

        {withPreselectionToggle && (
          <>
            <div
              style={{
                borderTop: "1px solid #C4C4C4",
                margin: "5px 0",
              }}
            />
            <button
              type="button"
              className={st.customSelectElement}
              onClick={toggleAutoPreselecting}
              disabled={isAutoPreselecting}
              style={{
                background: "#F6F6F6",
                transition: "none",
                display: "table-cell", // text centering
                color: isAutoPreselecting ? "#666" : "#00ADBB",
                fontWeight: isAutoPreselecting ? "normal" : "bold",
                cursor: isAutoPreselecting ? "not-allowed" : "pointer",
              }}
            >
              <div>Show active orders</div>
            </button>
          </>
        )}
      </div>
    );
  };

  const activeStyles = {
    borderColor: "#00ADBB",
    boxShadow:
      "0px 0px 2px rgba(90, 90, 90, 0.14), 0px 2px 2px rgba(90, 90, 90, 0.12), 0px 1px 3px rgba(90, 90, 90, 0.2)",
  };

  // This single multi-select variant component is exclusive of PCB
  return options.length === 1 ? (
    <div className={styles.customMultiSelect} style={{ cursor: "default" }}>
      <div>
        <span
          title="Allowed order types"
          style={{ color: "grey" }}
        >{`${options[0].label}`}</span>
      </div>
    </div>
  ) : (
    <PopUp
      trigger={
        <div
          style={isMenuOpen ? activeStyles : {}}
          className={styles.customMultiSelect}
        >
          <div>
            <span>
              {placeholder +
                (activeOptions.length ? ` (${activeOptions.length})` : "")}
            </span>
            <ArrowSVG />
          </div>
        </div>
      }
      content={renderOptions()}
      position="bottom center"
      onClose={setMenuOpen}
      onOpen={setMenuOpen}
    />
  );
};
export default CustomMultiSelect;
