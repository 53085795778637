/* eslint-disable @typescript-eslint/no-explicit-any */ // <--- TODO
import moment, { Moment } from "moment-timezone";
import { create } from "zustand";
import { devtools, persist, subscribeWithSelector } from "zustand/middleware";

import { SelectSingleProps, User } from "@:lite/types";

import { VisibleDateRange } from "./zustand-types";

// Set the default Time zone for the app. See README
moment?.tz?.setDefault("Europe/Oslo");

type State = {
  selectedFacility: string;
  selectedDate: Date;
  selectedStatus: SelectSingleProps[];
  selectedOrderType: SelectSingleProps[];
  latestOrderId: string;
  userData: User;
  visibleDays: number;
  inDetailedView: boolean;
  allCollapsed: boolean;
  isSnackbarOn: boolean;
  nameOfSidebarTab: string;
  isFetching: boolean;
  visibleDateRange: VisibleDateRange;
  visibleRows: any;
  allRows: any;
  tooltipRef: any;
  isDragging: boolean;
  highContrastMode: boolean;
  // actions
  setSelectedFacility: (newFacility: string) => void;
  setSelectedDate: (newDate: Date) => void;
  setSelectedStatus: any;
  setOrderTypeFilter: any;
  setLatestOrderId: (id: string) => void;
  setUserData: any;
  setInDetailedView: any;
  setAllCollapsed: any;
  setIsSnackbarOn: any; //  setIsSnackbarOn function is not used. Always on. Notifications via websockets were removed
  setNameOfSidebarTab: any;
  closeSidebarTab: () => void;
  setIsFetching: (isFetching: boolean) => void;
  setVisibleDateRange: (start: Moment, end: Moment) => void;
  setTooltipRef: (tooltipRef: any) => void;
  setGlobalIsDragging: (state: boolean) => void;
  setHighContrastMode: () => void;
};

// Don't store them in localStorage:
const nonPersistedSettings = [
  "selectedDate",
  "userData",
  "visibleDays",
  "isFetching",
  "visibleDateRange",
  "visibleRows",
  "allRows",
  "tooltipRef",
  "isDragging",
];

const calculateVisibleRange = (momentStart, momentEnd) => {
  const visibleStartDate = momentStart.startOf("day");
  const visibleEndDate = momentEnd.endOf("day");
  return {
    visibleDateRange: {
      visibleStartDate,
      visibleEndDate,
    },
    // will be at 21:59:59.999Z. That's why we add 1 ms. Then moment.diff between these 2 dates in "days" or "hours" will be correct.
    visibleDays: visibleEndDate
      .add(1, "millisecond")
      .diff(visibleStartDate, "days"),
  };
};

export const useSettingsStore = create<State>()(
  devtools(
    subscribeWithSelector(
      persist(
        (set, get) => ({
          // TODO: Think that when deploying new code that changes the "default" value, it won't pick up new changes, the persisted values takes precedence.
          selectedFacility: undefined,
          selectedDate: undefined,
          selectedStatus: [],
          selectedOrderType: [],
          latestOrderId: "", // Used for the search panel autocomplete.
          visibleDays: undefined,
          userData: undefined,
          inDetailedView: false,
          allCollapsed: false,
          isSnackbarOn: true,
          nameOfSidebarTab: "",
          isFetching: false,
          visibleDateRange: {
            // TODO: Analyze if we should have this default value, Vs the initialization that happens some lines below (setVisibleDateRange). Do we save re-renderings?
            visibleStartDate: moment().startOf("day"),
            visibleEndDate: moment()
              .add(1, "day")
              .endOf("day") // will be at 21:59:59.999Z. That's why we add 1 ms. Then moment.diff between these 2 dates in "days" or "hours" will be correct.
              .add(1, "millisecond"),
          },
          visibleRows: [],
          allRows: [],
          tooltipRef: null,
          isDragging: false,
          highContrastMode: false,
          setSelectedFacility: (newFacId) => {
            set({ selectedFacility: newFacId });
          },
          setSelectedDate: (selectedDate) => {
            // Update all at once, or there will be side-effects if selectedDate gets updated before visibleRange.
            set({
              selectedDate,
              ...calculateVisibleRange(
                moment(selectedDate),
                moment(selectedDate).add(1, "day"),
              ),
            });
          },
          setSelectedStatus: (selectedStatus) => set({ selectedStatus }),
          setOrderTypeFilter: (selectedOrderType) => {
            set({ selectedOrderType });
          },
          setLatestOrderId: (latestOrderId) => {
            set({ latestOrderId });
          },
          setUserData: (userData) => {
            set({ userData });
          },
          setInDetailedView: (index: number) => {
            set({ inDetailedView: index === 1 });
          },
          setAllCollapsed: (value: boolean) => {
            set({ allCollapsed: value });
          },
          setIsSnackbarOn: () => {
            set({ isSnackbarOn: !get().isSnackbarOn });
          },
          setNameOfSidebarTab: (nameOfSidebarTab: string) => {
            set({ nameOfSidebarTab });
          },
          closeSidebarTab: () => {
            set({ nameOfSidebarTab: "" });
          },
          setIsFetching: (isFetching) => set({ isFetching }),
          setVisibleDateRange: (momentStart, momentEnd) => {
            set({ ...calculateVisibleRange(momentStart, momentEnd) });
          },
          setTooltipRef: (tooltipRef) => set({ tooltipRef }),
          setGlobalIsDragging: (isDragging) => set({ isDragging }),
          setHighContrastMode: () => {
            set({
              highContrastMode: !get().highContrastMode,
            });
          },
        }),
        {
          name: "nsg-portlite-settings", // used for Redux devtools and persist localStorage key.
          // Enables you to pick some of the state's fields to be stored in the storage.
          // Even if it's the same name it doesn't conflict between environments (localhost, dev, test, prod)
          partialize: (state) => {
            return Object.fromEntries(
              Object.entries(state).filter(
                ([key]) => !nonPersistedSettings.includes(key),
              ),
            );
          },
        },
      ),
    ),
  ),
);

// TODO: IF only used in 1 place, delete me
// export const getRangeFromSelectedDate = () => {
//   const { selectedDate, visibleDays } = useSettingsStore.getState();
//   const startDate = moment(selectedDate)
//     // .subtract(visibleDays, "day")
//     .startOf("day")
//     .format("YYYY-MM-DD");
//   const endDate = moment(selectedDate)
//     .add(visibleDays + 1, "day")
//     .endOf("day")
//     .format("YYYY-MM-DD");
//   return {
//     startDate,
//     endDate,
//   };
// };

export const useUserData = () => useSettingsStore((state) => state.userData);
export const useVisibleDays = () =>
  useSettingsStore((state) => state.visibleDays);

export const setVisibleRows = (visibleRows) => {
  useSettingsStore.setState({ visibleRows });
};
export const setAllRows = (allRows) => {
  useSettingsStore.setState({ allRows });
};
