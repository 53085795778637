import { gql } from "@apollo/client";

export const GET_WOS_QUERY = gql`
  query getWorkOrders(
    $facilityId: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    facility(facilityId: $facilityId) {
      workOrders(startDate: $startDate, stopDate: $endDate) {
        id
        customerName
        customerDisplayName
        description
        orderedBy
        orderNumber
        plannedStartDate
        plannedStopDate
        startDate
        stopDate
        derivedStatus
        poNumber
        mCO {
          referenceOrderLine
          referenceOrderNumber
        }
        workOrderType {
          id
        }
      }
    }
  }
`;

export const GET_WOS_EXTRA_QUERY = gql`
  query getWorkOrders_EXTRA(
    $facilityId: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    facility(facilityId: $facilityId) {
      workOrders(startDate: $startDate, stopDate: $endDate) {
        id
        mCO {
          referenceOrderLine
          referenceOrderNumber
        }
        workOrderType {
          id
        }
        workOrderNotes {
          specifyDescription
        }
        attachments {
          id
          filename
        }
      }
    }
  }
`;
