import { create } from "zustand";
import { devtools, subscribeWithSelector } from "zustand/middleware";

type InProgressState = {
  poChangeInProgress: any[];
  vesselChangeInProgress: any[];
  woChangeInProgress: any[];
  inProgressSnackMessage: string;
};

export const useInProgressStore = create<InProgressState>()(
  // log(
  devtools(
    subscribeWithSelector(() => ({
      poChangeInProgress: [],
      vesselChangeInProgress: [],
      woChangeInProgress: [],
      inProgressSnackMessage: "",
    })),
  ),
);

export const removeIdFromPoInPogressList = (id) => {
  useInProgressStore.setState(({ poChangeInProgress }) => {
    const updatedIds = poChangeInProgress.filter(
      (eachItem) => eachItem?.id !== id,
    );
    return {
      poChangeInProgress: updatedIds,
    };
  });
  // why?  refreshTimeline();
};

export const removeIdFromVesselInPogressList = (id) => {
  useInProgressStore.setState(({ vesselChangeInProgress }) => {
    const updatedIds = vesselChangeInProgress.filter(
      (eachItem) => eachItem?.id !== id,
    );
    return {
      vesselChangeInProgress: updatedIds,
    };
  });
};

export const removeIdFromWoInPogressList = (id) => {
  useInProgressStore.setState(({ woChangeInProgress }) => {
    const updatedIds = woChangeInProgress.filter(
      (eachItem) => eachItem?.id !== id,
    );
    return {
      woChangeInProgress: updatedIds,
    };
  });
};

useInProgressStore.subscribe(
  (state) => state.vesselChangeInProgress,
  async () => {
    useInProgressStore.setState({
      inProgressSnackMessage: `Something might have gone wrong with saving`,
    });
  },
);
