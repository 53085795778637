import { useDragLayer } from "react-dnd";

import { Vessel } from "@:lite/types";

type Params = {
  order: Pick<Vessel, "id">;
};

// @TODO: rename to need to make the item click transparent!
export const useNeedToMakeItemSecondary = ({ order }: Params): boolean => {
  const { item: draggingItem, isDragging: isDragInProgress } = useDragLayer(
    (monitor) => ({
      isDragging: monitor.isDragging(),
      item: monitor.getItem(),
    }),
  );

  return isDragInProgress && draggingItem?.orderId !== order.id;
};
