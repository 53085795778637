import React, { memo, useEffect, useMemo } from "react";
import debounce from "lodash/debounce";

import useNotificationFromBackend from "@:lite/hooks/subscriptions/useNotificationFromBackend";
import { refreshTimeline } from "@:lite/zustand/useOrderStore";

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// We moved this functionality from timelineTable to its own "leaf" component
// So it doesn't re-render the timelineTable unnecessarily.
// There is no way to avoid re-renderings in a React component that consumes an useSubscription hook
const TimelineNotifications: React.FC = () => {
  const notification = useNotificationFromBackend();

  // Generate a random number so the server is not hammered at the same exact time by all PB users requesting data in the same millisecond.
  const randomDelay = getRandomInt(1800, 3000); // Higher range than PB, since is not critical

  const debouncedRefetchAllOrders = useMemo(
    () => debounce(refreshTimeline, randomDelay),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [], // We don't need to change the randomDelay. The same one in the meanwhile this component is mounted is ok.
  );

  useEffect(() => {
    if (notification?.payload) {
      debouncedRefetchAllOrders();
    }
  }, [
    debouncedRefetchAllOrders,
    notification, // notice that notification?.cacheBusting plays a role here.
    notification?.payload,
  ]);

  // Clean up when unmounting the component. From https://dmitripavlutin.com/react-throttle-debounce/
  // I think this could have some unwanted cancelling.
  // useEffect(() => {
  //   return () => {
  //     debouncedRefetchAllOrders?.cancel();
  //   };
  // }, [debouncedRefetchAllOrders]);

  // We keep the rendering intentionally "cheap", so the constant firehose of notifications wouldn't impact the performance.
  return null;
};

export default memo(TimelineNotifications);
