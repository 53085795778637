import React from "react";
import isEmpty from "lodash/isEmpty";

import CustomSelect from "@:lite/components/common/formElements/reactSelect";
import useGetFacilities from "@:lite/hooks/facilities/useGetFacilities";
import { Facility } from "@:lite/types";
import { useSettingsStore, useUserData } from "@:lite/zustand/useSettingsStore";

import DateFilters from "./datefilters";
import OrderFilters from "./orderFilters";

import styles from "./index.module.scss";

const Header: React.FC = () => {
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);
  const setSelectedFacility = useSettingsStore(
    (state) => state.setSelectedFacility,
  );
  const userData = useUserData();
  const { userFacilityIds } = userData;
  const facilitiesData = useGetFacilities();

  let filteredFacilities = [];

  // Important to check exactly empty array, that will allow access to all facilities. Not to confuse with undefined (when loading)
  if (Array.isArray(userFacilityIds) && userFacilityIds.length === 0) {
    filteredFacilities = facilitiesData;
    if (!selectedFacility) {
      console.error(
        "brand new user without any facility selected. Let's auto-select for him",
      );
      setSelectedFacility("220");
      window.location.reload();
    }
  } else {
    filteredFacilities = facilitiesData.filter((f) =>
      userFacilityIds.includes(f.id),
    );
    // If he doesn't have access to the "currentFacility" (selectedFacility), then switch to the
    // first facility he has access to
    if (
      !isEmpty(filteredFacilities) &&
      !filteredFacilities?.some((a) => a?.id === selectedFacility)
    ) {
      console.error("no access to this facility");
      setSelectedFacility(userFacilityIds?.[0]);
      window.location.reload();
    }
  }

  const facilitiesOptions = filteredFacilities?.map((f: Facility) => {
    return { value: f.id, label: `${f.id} ${f.name.toUpperCase()}` };
  });

  // key, value pair
  const currentFacility = facilitiesOptions.find(
    (f) => f?.value === selectedFacility,
  );

  return (
    <div
      className={styles.mainWrapper}
      style={{
        backgroundColor:
          window?.DOLITTLE_ENVIRONMENT !== "prod" ? "#C8DADB" : "white",
      }}
    >
      <div className={styles.headerLogo}>PCB</div>
      <div className={`${styles.headerFilters}`}>
        <div className={`${styles.selectors} flex-center`}>
          <CustomSelect
            value={currentFacility || facilitiesOptions?.[0]}
            onChange={(value) => {
              if (value?.value) {
                setSelectedFacility(value?.value);
                window.location.reload(); // TODO: is possible to avoid full refresh?
              }
            }}
            placeholder="Select base"
            options={facilitiesOptions}
          />
        </div>
        <DateFilters />
        <OrderFilters />
      </div>
    </div>
  );
};

export default Header;
