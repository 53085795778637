import { gql } from "@apollo/client";

export const UPDATE_VESSEL_MUTATION = gql`
  mutation allocateVessel(
    $vesselId: String!
    $startDate: DateTime!
    $stopDate: DateTime!
    $issuedBy: String!
  ) {
    allocateVesselOnTheSameQuay(
      vesselId: $vesselId
      startDate: $startDate
      stopDate: $stopDate
      issuedBy: $issuedBy
    )
  }
`;
