import React from "react";
import { DropTargetMonitor, useDragLayer, useDrop } from "react-dnd";

import {
  DraggableDnDItem,
  DraggableItemType,
  DropEventEmitter,
  DroppableItemType,
  OnDropPayload,
} from "@:lite/types";
import { DEFAULT_ALLOCATION_HEIGHT } from "@:lite/zustand/useOrderStore";

import styles from "./index.module.scss";

type Props = DropEventEmitter & {
  orderId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  orderStyles: any;
  onDrop: (result: OnDropPayload) => void;
  accept: DraggableItemType[] | string[];
};

const VesselDroppableGroup: React.FC<Props> = ({
  orderId,
  orderStyles,
  onDrop,
  accept,
}) => {
  const { isDragging, item: draggedItem } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    item: monitor.getItem(),
  }));

  const [{ isOver, canDrop }, drop] = useDrop<
    DraggableDnDItem,
    unknown,
    { isOver: boolean; canDrop: boolean }
  >(
    () => ({
      accept,
      drop: (item, monitor) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const partialPayload: any = {
          orderId,
          type: monitor.getItemType() as DroppableItemType,
        };
        return { ...partialPayload, viewportX: monitor.getClientOffset()?.x };
      },
      collect: (monitor: DropTargetMonitor) => {
        return {
          isOver: monitor.isOver({ shallow: true }),
          canDrop: monitor.canDrop(),
        };
      },
      canDrop: (item) => {
        if (item?.parentVesselId && orderId) {
          const prefix1 = item.parentVesselId.substring(
            0,
            item.parentVesselId.lastIndexOf(":"),
          );
          const prefix2 = orderId.substring(0, orderId.lastIndexOf(":"));
          return prefix1 === prefix2;
        }
        return false;
      },
    }),
    [onDrop, accept],
  );

  const finalHeight =
    isDragging &&
    draggedItem?.draggingTheSide !== true &&
    canDrop &&
    orderStyles.groupHeight < DEFAULT_ALLOCATION_HEIGHT
      ? DEFAULT_ALLOCATION_HEIGHT
      : orderStyles.groupHeight;

  return (
    <div
      key={`${orderId}group`}
      style={{
        top: orderStyles.groupTop,
        left: orderStyles.groupLeft,
        height: finalHeight,
        width: orderStyles.groupWidth,
        backgroundColor: orderStyles.backgroundGroupColor,
        opacity: isOver ? "1" : "0.45",
        border:
          // eslint-disable-next-line no-nested-ternary
          isDragging && draggedItem?.draggingTheSide !== true
            ? `3px dashed #${isOver ? "444" : "444"}`
            : finalHeight > 0
              ? `2px solid ${orderStyles.borderColor}`
              : "none",
      }}
      className={styles.groupedWOs}
      ref={drop}
    />
  );
};

export default VesselDroppableGroup;
