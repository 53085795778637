import React from "react";

import Search from "@:lite/components/common/search/searchPanel";
import Settings from "@:lite/components/common/settings/index";
import { useSettingsStore } from "@:lite/zustand/useSettingsStore";

import SearchSVG from "./svgElements/search";
import SettingsSVG from "./svgElements/settings";

import styles from "./index.module.scss";
import textStyles from "@:lite/style/textStyles.module.scss";

const tabs = [
  {
    label: "SEARCH",
    link: "/search",
    icon: <SearchSVG />,
  },
  {
    label: "SETTINGS",
    link: "/settings",
    icon: <SettingsSVG />,
  },
];

const SideBar: React.FC = () => {
  const nameOfSidebarTab = useSettingsStore((state) => state.nameOfSidebarTab);
  const closeSidebarTab = useSettingsStore((state) => state.closeSidebarTab);
  const setNameOfSidebarTab = useSettingsStore(
    (state) => state.setNameOfSidebarTab,
  );
  return (
    <>
      <div className={`sidebar-db-click ${styles.mainWrapper}`}>
        {tabs.map((tab) => (
          <div key={tab.link}>
            <button
              className={`${styles.tab} ${
                nameOfSidebarTab === tab.label && styles.active
              }`}
              onClick={() =>
                tab.label === nameOfSidebarTab
                  ? closeSidebarTab()
                  : setNameOfSidebarTab(tab.label)
              }
              type="button"
            >
              {tab.icon}
              <span className={textStyles.primaryText}>{tab.label}</span>
            </button>
          </div>
        ))}
      </div>
      {nameOfSidebarTab === "SETTINGS" && <Settings />}
      {nameOfSidebarTab === "SEARCH" && <Search />}
    </>
  );
};

export default SideBar;
