import { useEffect } from "react";
import { gql, useSubscription } from "@apollo/client";

import { useSettingsStore } from "@:lite/zustand/useSettingsStore";

import DomainEventResolver from "./DomainEventResolver";

const DOMAIN_EVENTS = gql`
  subscription ($facilityId: String!) {
    eventFromBackend(
      where: {
        or: [
          { scope: { eq: EVERYONE } }
          {
            and: [
              { scope: { eq: FACILITY } }
              { recipient: { eq: $facilityId } }
            ]
          }
        ]
      }
    ) {
      readModelId
      eventType
    }
  }
`;

const useLiveDomainEvents = (): void => {
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);

  const { data } = useSubscription(DOMAIN_EVENTS, {
    variables: { facilityId: selectedFacility },
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    if (window?.DEBUG_WS) {
      // eslint-disable-next-line no-console
      console.debug(
        "%c[WS - DOMAIN_EVENT] ",
        "background: #B9D2E8",
        data?.eventFromBackend?.eventType,
        data.eventFromBackend,
      );
    }
    DomainEventResolver.resolve(data.eventFromBackend);
  }, [data]);
};

export default useLiveDomainEvents;
