import React, { FC, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
  size?: number;
  seconds?: number;
  customStyles?: Record<string, unknown>;
};

const CircularDeterminateLoader: FC<Props> = ({
  size = 24,
  seconds = 4,
  customStyles = {},
}) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    let timer;
    if (progress < 100) {
      timer = setTimeout(
        () => {
          setProgress(progress + 100 / 50);
        },
        (seconds / 50) * 1000,
      );
    }
    return () => {
      clearTimeout(timer);
    };
  }, [progress, seconds]);

  const baseStyles = {
    top: "50%",
    color: "#00ADBB",
    left: "12px",
    margin: "-4px 6px 0 12px",
    verticalAlign: "middle",
  };
  return (
    <CircularProgress
      size={size}
      value={progress}
      variant="determinate"
      style={{ ...baseStyles, ...customStyles }}
    />
  );
};

export default CircularDeterminateLoader;
