import React, { useMemo } from "react";
import { useMsal } from "@azure/msal-react";
import isEmpty from "lodash/isEmpty";

import GroupButtons from "@:lite/components/common/formElements/groupButtons";
import CustomMultiSelect from "@:lite/components/common/formElements/multiselect/customMultiSelect";
import PopUp from "@:lite/components/common/formElements/popUp";
import { OrderStatusColors } from "@:lite/helpers/OrderStatusColors";
import arrowIcon from "@:lite/style/img/arrow.svg";
import clockIcon from "@:lite/style/img/clock.svg";
import avatar from "@:lite/style/img/default_avatar.svg";
import linesIcon from "@:lite/style/img/lines.svg";
import {
  OrderStatusColor,
  SelectSingleProps,
  SelectWithcolor,
  WorkOrder,
} from "@:lite/types";
import { useOrderStore } from "@:lite/zustand/useOrderStore";
import { useSettingsStore, useUserData } from "@:lite/zustand/useSettingsStore";

import styles from "./index.module.scss";
import textStyles from "@:lite/style/textStyles.module.scss";

const allOrderTypes = [
  {
    label: "VESSELS",
    value: "104",
  },
  {
    label: "OCTG",
    value: "105",
  },
  {
    label: "TAXI",
    value: "106",
  },
  {
    label: "BULK",
    value: "107",
  },
  {
    label: "BASE",
    value: "108",
  },
  {
    label: "PROJECT",
    value: "114",
  },
  {
    label: "STEELWORK SERVICES",
    value: "130",
  },
  {
    label: "WAREHOUSE",
    value: "150",
  },
];

const uniqueByKey = (array, key) => {
  const seen = new Set();
  return array.filter((item) => {
    const keyValue = item[key];
    if (seen.has(keyValue)) {
      return false;
    }
    seen.add(keyValue);
    return true;
  });
};

const userHasFullAccess = (userOrderTypes) => {
  return Array.isArray(userOrderTypes) && userOrderTypes.length === 0;
};
const OrderFilters: React.FC = () => {
  const inDetailedView = useSettingsStore((state) => state.inDetailedView);
  const setInDetailedView = useSettingsStore(
    (state) => state.setInDetailedView,
  );
  const allCollapsed = useSettingsStore((state) => state.allCollapsed);
  const setAllCollapsed = useSettingsStore((state) => state.setAllCollapsed);

  const allOrders = useOrderStore((state) => state.allOrders);

  const selectedStatus = useSettingsStore((state) => state.selectedStatus);
  const selectedOrderType = useSettingsStore(
    (state) => state.selectedOrderType,
  );
  const setSelectedStatus = useSettingsStore(
    (state) => state.setSelectedStatus,
  );
  const setOrderTypeFilter = useSettingsStore(
    (state) => state.setOrderTypeFilter,
  );

  const userData = useUserData();
  const { userOrderTypes, displayName } = userData;
  const { instance } = useMsal();

  const selectedOrderTypes = selectedOrderType.filter(
    (f) =>
      userHasFullAccess(userOrderTypes) || userOrderTypes.includes(f.value),
  );
  const mapOrderStatusToMultiSelect = useMemo(() => {
    const result: { value: string; label: string; color: string }[] = [];

    OrderStatusColors.asArray().forEach(
      (el: OrderStatusColor, index: number) => {
        result.push({
          value: index.toString(),
          label: el.name,
          color: el.color,
        });
      },
    );
    return result;
  }, []);

  let filteredOrderTypesOptions;

  // Important to check exactly empty array, that will allow access to all facilities. Not to confuse with undefined (when loading)
  // This extra checks are only in PCB, no PB
  if (userHasFullAccess(userOrderTypes)) {
    filteredOrderTypesOptions = allOrderTypes;
  } else {
    filteredOrderTypesOptions = allOrderTypes.filter((f) =>
      userOrderTypes.includes(f.value),
    );
  }

  const preselectedTypes = useMemo(() => {
    let res = [];

    if (!isEmpty(allOrders)) {
      const availableOrderIds = Object.values(allOrders)
        .filter((order) => !!order?.normalizedQuay)
        .filter(
          (order) =>
            order?.normalizedOrderType === "wo" &&
            (userHasFullAccess(userOrderTypes) ||
              userOrderTypes.includes(order.normalizedQuay)),
        )
        .map((wo: WorkOrder) => {
          return {
            label: wo.normalizedQuay,
            value: wo.normalizedQuay,
          };
        });

      const vesselsToo =
        userHasFullAccess(userOrderTypes) || userOrderTypes.includes("104");

      res = uniqueByKey(
        [
          ...(vesselsToo ? [{ label: "VESSELS", value: "104" }] : []),
          ...availableOrderIds,
        ],
        "value",
      );
    }
    return res;
  }, [allOrders, userOrderTypes]);

  const handleLogout = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: "/login",
      mainWindowRedirectUri: "/login",
    });
  };

  return (
    <div className={styles.orderFilters}>
      <div
        className={`${styles.allCollapsed} ${
          allCollapsed ? styles.allCollapsedEnabled : ""
        }`}
      >
        <GroupButtons
          firstTitle={arrowIcon}
          iconsAllowed
          activeButtonIndex={allCollapsed ? 2 : 1} // 1 is Enabled
          handleActiveButton={() => {
            setAllCollapsed(!allCollapsed);
          }}
          leftButtonText="Expand/collapse details for all Rental lines"
        />
      </div>
      <GroupButtons
        firstTitle={linesIcon}
        secondTitle={clockIcon}
        iconsAllowed
        activeButtonIndex={inDetailedView ? 1 : 2} // 1 is Enabled
        handleActiveButton={setInDetailedView}
        leftButtonText="Detailed view"
        rightButtonText="Timed view"
      />
      <CustomMultiSelect
        onChange={(values: SelectWithcolor[] | SelectSingleProps[]) => {
          setSelectedStatus(values);
        }}
        placeholder="ORDER STATUS"
        options={mapOrderStatusToMultiSelect}
        allButtonName="Select all"
        noneButtonName="Deselect all"
        preselected={[]}
        activeOptions={selectedStatus}
        storageKey="order-status-select"
      />
      <CustomMultiSelect
        onChange={(value: SelectWithcolor[] | SelectSingleProps[]) => {
          setOrderTypeFilter(value);
        }}
        placeholder="ORDER TYPE"
        options={filteredOrderTypesOptions}
        allButtonName="Select all"
        noneButtonName="Deselect all"
        preselected={preselectedTypes}
        activeOptions={selectedOrderTypes}
        storageKey="order-type-select"
        withPreselectionToggle
      />
      <div className={`${styles.orderFilters} ${styles.userInfo}`}>
        <div className="flex-center">
          <img src={avatar} alt="avatar" />
        </div>
        <PopUp
          trigger={
            <div className={textStyles.primaryText}>{displayName || ""}</div>
          }
          content={
            <div
              tabIndex={0}
              role="button"
              onKeyDown={() => null}
              onClick={handleLogout}
              className="popUp-wrapper"
            >
              Log Out
            </div>
          }
        />
      </div>
    </div>
  );
};

export default OrderFilters;
