import React, { FC, memo } from "react";

import { closeSidePanel, deleteVessel } from "@:lite/zustand/useOrderStore";
import { useSettingsStore, useUserData } from "@:lite/zustand/useSettingsStore";

import styles from "./sidePanel.module.scss";
import buttons from "@:lite/style/buttons.module.scss";
import textStyles from "@:lite/style/textStyles.module.scss";

type Props = {
  vesselId: string;
  changeModalState: () => void;
};

const DeletionModal: FC<Props> = ({ vesselId, changeModalState }) => {
  const userData = useUserData();
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);
  return (
    <div className={styles.deletionModal}>
      <h5 className={textStyles.primaryText}>DELETE QUAY RESERVATION.</h5>
      <p>Want to delete quay reservation?</p>
      <p>This action cannot be undone.</p>
      <section>
        <button
          className={buttons.modalButton}
          type="button"
          onClick={changeModalState}
        >
          No, Cancel
        </button>
        <button
          className={buttons.modalButton}
          type="button"
          onClick={async () => {
            if (!vesselId) return;
            const finalUpdate = {
              facilityId: selectedFacility,
              issuedBy: userData.mail,
              vesselId,
            };
            deleteVessel(finalUpdate);
            changeModalState();
            closeSidePanel();
          }}
        >
          Yes, Delete
        </button>
      </section>
    </div>
  );
};
export default memo(DeletionModal);
