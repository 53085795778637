export const isStatusRequestedOrPlanned = (status: string | undefined) => {
  if (!status) {
    return false;
  }
  const stat = status.toString().toUpperCase();
  return stat === "REQUESTED" || stat === "PLANNED";
};

// We use it mostly to decide if the planned stop date should still be editable.
export const isStatusNotFinished = (status: string | undefined) => {
  if (!status) {
    return false;
  }
  const stat = status.toString().toUpperCase();
  return (
    stat === "REQUESTED" ||
    stat === "PLANNED" ||
    stat === "STARTED" ||
    stat === "STOPPED" ||
    stat === "INTERRUPTED"
  );
};

export const isStatusInvoiced = (status: string | undefined) => {
  if (!status) {
    return false;
  }
  return status.toString().toUpperCase() === "INVOICED";
};
