import { ConnectDragSource, useDrag } from "react-dnd";

import {
  DraggableDnDItem,
  DraggableItemType,
  DraggingToggleHost,
} from "@:lite/types";
import { useSettingsStore } from "@:lite/zustand/useSettingsStore";

type Params = DraggableDnDItem &
  DraggingToggleHost & { timeLine: string[] } & {
    type: DraggableItemType;
  };

type DraggableCollectedProps = { isDragging: boolean };

type ReturnValue = [DraggableCollectedProps, ConnectDragSource];

export const useDraggableItem = ({
  type,
  orderId,
  parentVesselId = undefined,
  quayId,
  timeLine,
  dragScope,
  excludeDragScopes,
  canDrag,
}: Params): ReturnValue => {
  const setGlobalIsDragging = useSettingsStore(
    (state) => state.setGlobalIsDragging,
  );
  const [collectedDragData, dragRef] = useDrag<
    DraggableDnDItem,
    unknown,
    DraggableCollectedProps
  >(
    () => ({
      type,
      timeLine,
      item: {
        parentVesselId,
        orderId,
        quayId,
        dragScope,
        excludeDragScopes,
      },
      canDrag: () => {
        setGlobalIsDragging(true);
        return canDrag;
      },
      end: () => {
        setGlobalIsDragging(false);
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [type, orderId, quayId, dragScope, excludeDragScopes, canDrag],
  );

  return [collectedDragData, dragRef];
};
