import { OrderStatusColor } from "@:lite/types";

export enum OrderType {
  WORK_ORDER,
  OPERATION_LINE,
  VESSEL,
}

export enum OrderStatusEnum {
  Requested = "REQUESTED",
  Planned = "PLANNED",
  Started = "STARTED",
  Stopped = "STOPPED",
  Interrupted = "INTERRUPTED",
  Finished = "FINISHED",
  Invoiced = "INVOICED",
  Unknown = "UNKNOWN",
}
/**
 * This class was written based off of  vessel status. If too many descrepencies happen between operationline, work order and vessel statuses, consider splitting it up
 * for now only 1 exception is known
 */
export class OrderStatusColors {
  private static readonly CONFIG = new Map<OrderStatusEnum, OrderStatusColor>([
    [
      OrderStatusEnum.Requested,
      {
        name: OrderStatusEnum.Requested,
        color: "var(--requestedColor)",
        background: "var(--requestedBackground)",
      },
    ],
    [
      OrderStatusEnum.Planned,
      {
        name: OrderStatusEnum.Planned,
        color: "var(--plannedColor)",
        background: "var(--plannedBackground)",
      },
    ],
    [
      OrderStatusEnum.Started,
      {
        name: OrderStatusEnum.Started,
        color: "var(--startedColor)",
        background: "var(--startedBackground)",
      },
    ],
    [
      OrderStatusEnum.Stopped,
      {
        name: OrderStatusEnum.Stopped,
        color: "var(--stoppedColor)",
        background: "var(--stoppedBackground)",
      },
    ],
    [
      OrderStatusEnum.Interrupted,
      {
        name: OrderStatusEnum.Interrupted,
        color: "var(--stoppedColor)",
        background: "var(--stoppedBackground)",
      },
    ],
    [
      OrderStatusEnum.Finished,
      {
        name: OrderStatusEnum.Finished,
        color: "var(--finishedColor)",
        background: "var(--finishedBackground)",
      },
    ],
    [
      OrderStatusEnum.Invoiced,
      {
        name: OrderStatusEnum.Invoiced,
        color: "var(--invoicedColor)",
        background: "var(--invoicedBackground)",
      },
    ],
    [
      OrderStatusEnum.Unknown,
      {
        name: OrderStatusEnum.Unknown,
        color: "var(--unknownColor)",
        background: "var(--unknownBackground)",
      },
    ],
  ]);

  static getStatus(status: OrderStatusEnum): OrderStatusColor {
    return (
      OrderStatusColors.CONFIG.get(status) ??
      OrderStatusColors.getUnknownStatus()
    );
  }

  static getColor(status: OrderStatusEnum): string {
    return OrderStatusColors.getStatus(status)?.color;
  }

  static getBackgroundColor(
    status: OrderStatusEnum,
    orderType?: OrderType,
  ): string | undefined {
    switch (orderType) {
      case OrderType.OPERATION_LINE:
        return OrderStatusColors.getBackgroundColorForOperationLine(status);
      default:
        return OrderStatusColors.getStatus(status)?.background;
    }
  }

  static getName(status: OrderStatusEnum): string {
    return OrderStatusColors.getStatus(status)?.name;
  }

  static getUnknownStatus(): OrderStatusColor {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return OrderStatusColors.CONFIG.get(OrderStatusEnum.Unknown)!;
  }

  static asArray(): Array<OrderStatusColor> {
    return Array.from(OrderStatusColors.CONFIG.values());
  }

  // Operation Lines need the same bg color for Requested and Planned
  private static getBackgroundColorForOperationLine(
    status: OrderStatusEnum,
  ): string | undefined {
    if (
      status === OrderStatusEnum.Requested ||
      status === OrderStatusEnum.Planned
    ) {
      return OrderStatusColors.getStatus(OrderStatusEnum.Planned)?.background;
    }
    return OrderStatusColors.getStatus(status)?.background;
  }
}
