/* eslint-disable no-restricted-syntax */
import React, { forwardRef, memo, useCallback, useRef, useState } from "react";
import isNil from "lodash/isNil";
import moment from "moment-timezone";

import { draggableItemToOrderTypeMap } from "@:lite/helpers/constants";
import {
  DraggableItemType,
  DraggableSideDnDItem,
  Nullable,
  OnDropPayload,
  OnOrderUpdatedFn,
  OrderUpdateEventOrderType,
} from "@:lite/types";
import { getSelectedMomentFromSelectedDate } from "@:lite/utils/order";
import {
  calculateNewTimeBoundsForOrderBasedOnStartTime,
  getDiffInDays,
  normalizeTimeSlotDef,
} from "@:lite/utils/timelineUtils";
import {
  getOrderById,
  mutateVessel,
  mutateWorkOrder,
  useOrderStore,
} from "@:lite/zustand/useOrderStore";
import {
  useSettingsStore,
  useVisibleDays,
} from "@:lite/zustand/useSettingsStore";
import { useSnackbar } from "@:lite/zustand/useSnackbar";

import VesselAllocation from "./allocations/vesselAllocation";
import VesselSubWorkOrderAllocation from "./allocations/vesselSubWorkOrderAllocation";
import WorkOrderAllocation from "./allocations/workOrderAllocation";
import { DraggableSideTimeTooltip } from "./draggableSide/draggableSideTimeTooltip";
import DragLayer, {
  DragLayerState,
  OnDragLayerStateChangedFn,
} from "./dragLayer";
import { checkStartBeforeStop } from "./sidePanel/checkStartBeforeStop";
import SidePanelWrapper from "./sidePanel/sidePanelWrapper";
import TableRow from "./tableRow";
import TableRowLabel from "./tableRowLabel";
import { TimelineDraggingTooltip } from "./timelineDraggingTooltip";
import TimelineFloatingLabels from "./timelineFloatingLabels";
import TimelineGuides from "./timelineGuides";
import TimelineLoading from "./timelineLoading";
import TimelineNotifications from "./timelineNotifications";
import TimelineSnackbar from "./timelineSnackbar";

import styles from "./index.module.scss";

type DragLayerProps = Omit<
  React.ComponentProps<typeof DragLayer>,
  "children" | "onDragLayerStateChanged"
>;

type Props = {
  timeLine: string[];
  elementWidth: number;
  dragLayerProps: DragLayerProps;
};

const TimelineTable = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { timeLine, elementWidth, dragLayerProps } = props;

  const arrayToRender = useOrderStore((state) => state.arrayToRender);
  const selectedDate = useSettingsStore((state) => state.selectedDate);
  const visibleDays = useVisibleDays();
  const visibleRows = useSettingsStore((state) => state.visibleRows);

  // Mutates
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < arrayToRender.length; i++) {
    if (arrayToRender[i]?.orders) {
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < arrayToRender[i].orders.length; j++) {
        if (arrayToRender[i].orders[j]) {
          arrayToRender[i].orders[j].theRef = React.createRef<HTMLDivElement>();
          for (
            let k = 0;
            k < arrayToRender[i].orders[j].workOrders?.length;
            k += 1
          ) {
            if (arrayToRender[i].orders[j].workOrders[k]) {
              arrayToRender[i].orders[j].workOrders[k].theRef =
                React.createRef<HTMLDivElement>();
            }
          }
        }
      }
    }
  }

  const setSnack = useSnackbar((state) => state.setSnack);

  const [cardSidePullResultSnapshot, setCardSidePullResultSnapshot] =
    useState<Nullable<DraggableSideDnDItem & { newTime: string }>>(null);

  const showGeneralErrorSnackBar = useCallback(() => {
    setSnack(true, "Something went wrong! Please try again!", true, "error");
  }, [setSnack]);

  const saveOrderUpdates = useCallback(
    (params: {
      orderId: string;
      orderType: OrderUpdateEventOrderType;
      newStartDate: string;
      newStopDate: string;
    }) => {
      const { orderId, orderType, newStartDate, newStopDate } = params;

      const plannedStartDate = moment(newStartDate)
        .set({
          second: 0,
        })
        .toISOString();
      const plannedStopDate = moment(newStopDate)
        .set({
          second: 0,
        })
        .toISOString();

      switch (orderType) {
        case "vessel": {
          const finalUpdate = {
            id: orderId,
            plannedStartDate,
            plannedStopDate,
          };
          if (checkStartBeforeStop(finalUpdate, setSnack)) {
            mutateVessel(finalUpdate);
          }
          break;
        }
        case "sub-work-order": {
          const finalUpdate = {
            id: orderId,
            plannedStartDate,
            plannedStopDate,
          };
          if (checkStartBeforeStop(finalUpdate, setSnack)) {
            mutateWorkOrder(finalUpdate, true);
          }
          break;
        }
        case "work-order": {
          const finalUpdate = {
            id: orderId,
            plannedStartDate,
            plannedStopDate,
          };
          if (checkStartBeforeStop(finalUpdate, setSnack)) {
            mutateWorkOrder(finalUpdate);
          }
          break;
        }
        default: {
          throw new Error(`The order type "${orderType}" is unknown!`);
        }
      }
    },
    [setSnack],
  );

  const updateOrder = useCallback(
    async (params: {
      toTime: string;
      plannedStartDate?: string;
      plannedStopDate?: string;
      orderId: string;
      orderType: "vessel" | "work-order" | "sub-work-order";
      preserveOrderTimeBounds: boolean;
      name?: string;
      customerName?: string;
      visibleDays: number;
    }) => {
      try {
        const {
          toTime,
          plannedStartDate, // new one
          plannedStopDate, // new one
          orderId,
          orderType,
          preserveOrderTimeBounds,
        } = params;

        // toTime can come in 2 formats, already normalized as "HH:mm" (when dragging the edge of the event for example)
        // or "Z-HH:MM", with Z representing the day (0 yesterday, 1 today, 2 tomorrow), when drag & drop an item in a new slot
        let toTimehhmm = normalizeTimeSlotDef(toTime);
        let dayIndex = 1;
        if (plannedStartDate) {
          dayIndex = Math.floor(
            getDiffInDays(selectedDate, plannedStartDate, visibleDays),
          );
        } else if (toTime.split("-")[1]) {
          dayIndex = parseInt(toTime.split("-")[0], 10);
          toTimehhmm = normalizeTimeSlotDef(toTime.split("-")[1]);
        }
        const toTimeHours = parseInt(toTimehhmm.split(":")[0], 10);
        const toTimeMinutes = parseInt(toTimehhmm.split(":")[1], 10);
        const normalizedToTime = getSelectedMomentFromSelectedDate(selectedDate)
          .add(dayIndex, "days") // TODO: Make it better using visibleDays if it becomes configurable
          .hour(toTimeHours)
          .minute(toTimeMinutes);

        const fullOrder = getOrderById(orderId);

        // This section is about "Optimistic Update".
        // We update and manipulate artificially our store of orders, to simulate that the
        // update went well, until we get back the order from the server
        const { newStartDate, newStopDate } = preserveOrderTimeBounds
          ? calculateNewTimeBoundsForOrderBasedOnStartTime({
              newStart: normalizedToTime,
              timeBoundOrder: fullOrder,
            })
          : {
              newStartDate: plannedStartDate as string,
              newStopDate: plannedStopDate as string,
            };

        saveOrderUpdates({
          orderId,
          orderType,
          newStartDate,
          newStopDate,
        });
      } catch (e) {
        console.error(e);
        showGeneralErrorSnackBar();
      }
    },
    [selectedDate, visibleDays, saveOrderUpdates, showGeneralErrorSnackBar],
  );

  const calculateDragLayerStateChangeResult = useCallback(
    (state: DragLayerState) => {
      const {
        differenceByXFromInitialOffset,
        item,
        itemType,
        parentItemType,
        clientOffset,
      } = state;

      const noValues =
        isNil(item) ||
        isNil(item?.element) ||
        isNil(clientOffset) ||
        isNil(parentItemType) ||
        isNil(differenceByXFromInitialOffset);

      if (itemType !== DraggableItemType.DraggableSide || noValues) {
        return null;
      }

      const targetOrderType = draggableItemToOrderTypeMap[parentItemType];
      const cellWidth = elementWidth;
      const isDecreasing = differenceByXFromInitialOffset < 0;
      const shiftPercentBasedOnOneCellWidth =
        differenceByXFromInitialOffset / cellWidth;
      const numberOfMinutesInCell = 60;
      const calculatedShiftInMinutes = Math.abs(
        Math.round(numberOfMinutesInCell * shiftPercentBasedOnOneCellWidth),
      );

      const fullOrder = getOrderById(item.orderId);

      if (!fullOrder) {
        throw new Error("Get order from zustand store failed");
      }

      const calculateNewDateBasedOnDirectionAndShift = (
        baseISODate: string,
      ) => {
        return isDecreasing
          ? moment(baseISODate)
              .subtract(calculatedShiftInMinutes, "minutes")
              .toISOString()
          : moment(baseISODate)
              .add(calculatedShiftInMinutes, "minutes")
              .toISOString();
      };

      let newValueOfTimeBorder;
      const orderUpdateDef: Record<string, string> = {};
      const { plannedStartDate, plannedStopDate } = fullOrder;

      switch (item.position) {
        case "start":
          newValueOfTimeBorder =
            calculateNewDateBasedOnDirectionAndShift(plannedStartDate);

          Object.assign(orderUpdateDef, {
            plannedStartDate: newValueOfTimeBorder,
            "vessel.plannedStartDate": newValueOfTimeBorder,
            "workOrder.plannedStartDate": newValueOfTimeBorder,
          });
          break;

        case "end":
          newValueOfTimeBorder =
            calculateNewDateBasedOnDirectionAndShift(plannedStopDate);

          Object.assign(orderUpdateDef, {
            plannedStopDate: newValueOfTimeBorder,
            "vessel.plannedStopDate": newValueOfTimeBorder,
            "workOrder.plannedStopDate": newValueOfTimeBorder,
          });
          break;

        default:
          throw new Error(
            `Unknown draggable side position type - "${item.position}"`,
          );
      }

      return {
        item,
        targetOrderType,
        fullOrder,
        orderUpdateDef,
      };
    },
    [elementWidth],
  );

  const handleDragLayerStateChanged = (state: DragLayerState) => {
    try {
      const stateChangeResult = calculateDragLayerStateChangeResult(state);

      if (!stateChangeResult) {
        return;
      }

      const newTimeBorder =
        stateChangeResult.orderUpdateDef.plannedStartDate ||
        stateChangeResult.orderUpdateDef.plannedStopDate;

      setCardSidePullResultSnapshot({
        ...stateChangeResult.item,
        newTime: moment(newTimeBorder).format("HH:mm"),
      });
    } catch (e) {
      console.error(e);
      showGeneralErrorSnackBar();
    }
  };

  const handleAllocationEdgePull: OnDragLayerStateChangedFn = (state) => {
    try {
      const stateUpdateValue = calculateDragLayerStateChangeResult(state);
      if (!stateUpdateValue) {
        return;
      }

      const newStartDate = stateUpdateValue.orderUpdateDef.plannedStartDate
        ? stateUpdateValue.orderUpdateDef.plannedStartDate
        : stateUpdateValue.fullOrder.plannedStartDate;

      const newStopDate = stateUpdateValue.orderUpdateDef.plannedStopDate
        ? stateUpdateValue.orderUpdateDef.plannedStopDate
        : stateUpdateValue.fullOrder.plannedStopDate;

      updateOrder({
        toTime: normalizeTimeSlotDef(moment(newStartDate).format("HH:mm")),
        plannedStartDate: newStartDate,
        plannedStopDate: newStopDate,
        orderId: stateUpdateValue.item.orderId,
        orderType: stateUpdateValue.targetOrderType,
        preserveOrderTimeBounds: false,
        visibleDays,
      });

      setCardSidePullResultSnapshot(null);
    } catch (e) {
      console.error(e);
      showGeneralErrorSnackBar();
    }
  };

  // only for drag&drop. Not for pulling edges.
  const onDropCallback = useCallback(
    (result: OnDropPayload) => {
      updateOrder({
        toTime: result.toTime,
        orderId: result.orderId,
        orderType: draggableItemToOrderTypeMap[result.type],
        preserveOrderTimeBounds: true,
        visibleDays,
      });
    },
    [visibleDays, updateOrder],
  );

  const onOrderUpdated: OnOrderUpdatedFn = useCallback(
    (order) => {
      updateOrder({
        orderId: order.id,
        preserveOrderTimeBounds: false,
        visibleDays,
        ...order,
      });
    },
    [visibleDays, updateOrder],
  );
  const draggingTooltipRef = useRef(null);
  // TODO: Try useEffect, but it can fall in an edge case: after dragging the edge of allocation, this drag&drop tooltip disappears
  draggingTooltipRef.current = document.getElementById("draggingTooltip");

  return (
    <div className={styles.timelineTable}>
      <TimelineNotifications />
      <TimelineSnackbar />
      <TimelineLoading />
      <TimelineDraggingTooltip />
      <TimelineFloatingLabels arrayToRender={arrayToRender} />
      <SidePanelWrapper onOrderUpdated={onOrderUpdated} />
      {cardSidePullResultSnapshot?.element && (
        <DraggableSideTimeTooltip
          element={cardSidePullResultSnapshot.element}
          time={cardSidePullResultSnapshot.newTime}
        />
      )}
      <div className={styles.rowNameContainer}>
        {visibleRows.map((eachRow) => {
          const thisRow = arrayToRender.find((item) => item.row === eachRow.id);

          return (
            <TableRowLabel key={eachRow.id} height={thisRow?.rowHeight}>
              {eachRow.name}
            </TableRowLabel>
          );
        })}
      </div>
      <DragLayer
        ref={ref}
        {...dragLayerProps}
        onDragLayerStateChanged={handleDragLayerStateChanged}
      >
        <TimelineGuides elementWidth={elementWidth} />
        {!arrayToRender
          ? null
          : visibleRows.map((eachRow) => {
              const thisRow = arrayToRender.find(
                (item) => item.row === eachRow.id,
              );

              // Note: TableRow needs to be a container for the orders so multiple layers of "drop targets" can work, and bubble through them. See
              return (
                <TableRow
                  key={eachRow.id}
                  height={thisRow?.rowHeight}
                  quayId={eachRow.id}
                  elementWidth={elementWidth}
                  timeLine={timeLine}
                  onDrop={onDropCallback}
                  draggingTooltipRef={draggingTooltipRef}
                >
                  {thisRow?.orders.map((eachItem) => {
                    return (
                      <React.Fragment key={eachItem.id}>
                        {eachItem?.quay && (
                          <React.Fragment key={eachItem.id}>
                            <VesselAllocation
                              key={eachItem.id}
                              ref={eachItem?.theRef}
                              vessel={eachItem}
                              elementWidth={elementWidth}
                              visibleDays={0}
                              position={0}
                              selectedDate={selectedDate}
                              timeLine={timeLine}
                              onPullEnd={handleAllocationEdgePull}
                              onDrop={() => {
                                console.log("TODO");
                              }}
                            />
                            {!eachItem?.allocationCollapsed &&
                              eachItem.workOrders?.map((wo) => {
                                return wo?.hideInTimeline ? null : (
                                  <VesselSubWorkOrderAllocation
                                    key={wo.id}
                                    id={wo.id}
                                    ref={wo?.theRef}
                                    workOrder={wo}
                                    elementWidth={elementWidth}
                                    visibleDays={0}
                                    position={0}
                                    selectedDate={selectedDate}
                                    timeLine={timeLine}
                                    onPullEnd={handleAllocationEdgePull}
                                    parentVessel={eachItem}
                                  />
                                );
                              })}
                          </React.Fragment>
                        )}

                        {eachItem?.normalizedOrderType === "wo" && (
                          <WorkOrderAllocation
                            key={eachItem.id}
                            id={eachItem.id}
                            ref={eachItem?.theRef}
                            workOrder={eachItem}
                            elementWidth={elementWidth}
                            visibleDays={0}
                            position={0}
                            selectedDate={selectedDate}
                            timeLine={timeLine}
                            onPullEnd={handleAllocationEdgePull}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
                </TableRow>
              );
            })}
      </DragLayer>
    </div>
  );
});

export default memo(TimelineTable);
