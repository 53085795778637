import React, { useCallback, useEffect } from "react";

import useLiveDomainEvents from "@:lite/hooks/subscriptions/useLiveDomainEvents";

// It has this name because of nsg-app
export const AssignmentLinkTooltip: React.FC = () => {
  // Get quickly the Bearer Token, to use it in GraphQL IDE
  const doubleClick = useCallback((event) => {
    if (
      event?.shiftKey &&
      event?.target?.className?.includes("sidebar-db-click")
    ) {
      navigator?.clipboard?.writeText(window?.BEARER);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("dblclick", doubleClick, false);

    return () => {
      document.removeEventListener("dblclick", doubleClick, false);
    };
  }, [doubleClick]);

  return null;
};

// Own sub component to avoid rerenders
export const LiveDomainEvents: React.FC = () => {
  useLiveDomainEvents();
  return null;
};
