/* eslint-disable @typescript-eslint/no-explicit-any */

export type SelectSingleProps = {
  value: string;
  label: string;
};

export interface SelectWithcolor extends SelectSingleProps {
  color: string;
}

export type User = {
  displayName: string;
  mail: string;
  userCompany: string;
  userFacilityIds: string[];
  userOrderTypes: string[];
};

export type Quay = {
  facilityId?: string;
  id: string;
  name: string;
  __typename: string;
};

export type Facility = {
  id: string;
  name: string;
  __typename: string;
};

export type VisualColorTheme = {
  color: string;
  background: string;
};

export type OrderStatusColor = VisualColorTheme & {
  name: string;
};

type WorkOrdertype = {
  description: string | null;
  id: string;
  name: string;
  status: string;
  __typename: string;
};

type MCO = {
  referenceOrderLine: string;
  referenceOrderNumber: string;
};

export type Note = {
  description: string;
  entryDate: string;
  id: string;
  lastModified: string;
  noteType: string;
  text: string;
  textIdentity: string;
  modifiedBy: string;
  specifyDescription: string;
};

export type Attachment = {
  id: string;
  attachmentType: string;
  displayName: string;
  filename: string;
  size: number;
  uploadDate: string;
  modifiedDate: string;
  // Local values:
  uploading: boolean;
  deleting: boolean;
};

type LoadingStatusQtys = {
  canNotProcessQty: number;
  quaySideQty: number;
  roundTripQty: number;
  vesselQty: number;
  warehouseQty;
};

type KoncivLoadingStatus = {
  planId: number;
  planType: string;
  pipeLoad: LoadingStatusQtys;
  regularLoad: LoadingStatusQtys;
};

export type WorkOrder = {
  customerName: string;
  customerDisplayName: string;
  description: string;
  facilityId: string;
  poNumber: string | null;
  orderedBy: string | null;
  id: string;
  name: string;
  orderBy: string;
  orderDate: string;
  orderNumber: string;
  plannedStartDate: string;
  plannedStopDate: string;
  startDate: string;
  status: string;
  derivedStatus: string;
  stopDate: string;
  vesselRelated: boolean;
  mCO: MCO;
  loadingStatusSummary: KoncivLoadingStatus;
  normalizedTotalPipeLoad: number;
  normalizedTotalRegularLoad: number;
  hideInTimeline?: boolean;
  workOrderType: WorkOrdertype;
  workOrderNotes: Note[];
  attachments: Attachment[];
  normalizedQuay: string;
  allocHeight: number;
  visualTop: number;
  __typename: string;
};

type VesselInfo = {
  id: string;
  name: string;
  imoNumber: string;
  isSynchronized: boolean;
};
export type VesselPreviewInfo = {
  id: string;
  rentalAgreementNumber: string;
  rentalAgreementLine: string;
  customerName: string | null;
  quay: string | null;
  plannedStartDate: string;
  plannedStopDate: string;
  status: string;
  vessel: VesselInfo;
  workOrders: {
    id: string;
    name: string;
    status: string;
    orderDate: string;
    description: string;
  }[];
  normalizedQuay: string;
};

export type Vessel = {
  userHasAccessToVesselSubWOs: boolean;
  groupTop: number;
  groupHeight: number;
  id: string;
  poNumber: string | null;
  shorePowerUsage: any;
  vesselName: string;
  orderedBy: string | null;
  rentalAgreementNumber: string;
  rentalAgreementLine: string;
  vesselImo: string;
  customerId: string | null;
  customerName: string | null;
  quay: string | null;
  startDate: string;
  stopDate: string;
  plannedStartDate: string;
  plannedStopDate: string;
  isSynchronized: string | null;
  __typename: string;
  status: string;
  derivedStatus: string;
  vessel: VesselInfo;
  workOrders: WorkOrder[];
  attachments: Attachment[];
  normalizedQuay: string;
  allocHeight: number;
  allocationCollapsed: boolean;
  visualTop: number;
  stackIndex: number;
};

/**
 * Utility types start
 */
export type FunctionParams<
  F extends (...args: any) => any,
  I extends number = 0,
> = Parameters<F>[I];

type Nil = null | undefined;

export type Nullable<T> = T | Nil;

/**
 * Utility types end
 */

export enum DraggableItemType {
  Order = "order",
  WorkOrder = "work-order",
  SubWorkOrder = "sub-work-order",
  DraggableSide = "draggable-side",
}

export type DroppableItemType = Exclude<DraggableItemType, "draggable-side">;

type DragScopeParams = string[];

type DragScopeParamsHost = {
  dragScope?: DragScopeParams;
  excludeDragScopes?: DragScopeParams;
};

export type DraggableDnDItem = Partial<DragScopeParamsHost> & {
  orderId: string;
  parentVesselId?: string;
  quayId: string;
};

type DraggableSidePosition = "start" | "end";

export type DraggableSideDnDItem = DraggableDnDItem & {
  position: DraggableSidePosition;
  parentItemType: DroppableItemType;
  element: Nullable<HTMLElement>;
};

export type DraggingToggleHost = {
  canDrag: boolean;
};

export type DraggableItemProps = Omit<DraggableDnDItem, "orderId"> &
  DraggingToggleHost &
  DragScopeParamsHost;

export type DraggableSideProps = DraggableDnDItem &
  Pick<DraggableSideDnDItem, "position" | "parentItemType"> & {
    styles: {
      position: Partial<{
        top: number;
        left: number;
        right: number;
        bottom: number;
      }>;
      backgroundColor: string | undefined;
      cursor: string;
    };
    onDrop: OnDraggableSideDropFn;
  };

type DraggableSideDragState = {
  item: Nullable<DraggableSideDnDItem>;
  itemType: Nullable<DraggableItemType>;
  parentItemType: Nullable<DroppableItemType>;
  differenceByXFromInitialOffset: Nullable<number>;
  clientOffset: Nullable<{ x: number; y: number }>;
};

export type OnDraggableSideDropFn = (state: DraggableSideDragState) => void;

export type OnDropPayload = {
  orderId: string;
  toTime: string;
  toQuay: string;
  type: DroppableItemType;
};

export type DropEventEmitter = {
  onDrop: (result: OnDropPayload) => void;
};

export type OrderUpdateEventOrderType =
  | "vessel"
  | "work-order"
  | "sub-work-order";

type OrderUpdatedEventCommonParams = Pick<
  Vessel,
  "plannedStartDate" | "plannedStopDate"
> & {
  id: string;
  toTime: string;
  orderType: OrderUpdateEventOrderType;
};

export type OrderUpdatedEventParams = OrderUpdatedEventCommonParams & {
  poNumber?: string; // TODO: Make it non optional once this feature is released
  quay: string;
};

export type OnOrderUpdatedFn = (order: OrderUpdatedEventParams) => void;
