import React, { FC, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as Sentry from "@sentry/browser";
import { excludeGraphQLFetch } from "apollo-link-sentry";
import moment from "moment-timezone"; // Importing this way solves a typescript issue https://github.com/moment/moment-timezone/issues/906

import AppVersionCheck from "@:lite/components/common/appVersionCheck";
import { captureMessage } from "@:lite/helpers/captureError";
import { redirectToLogin } from "@:lite/utils/auth/authUtils";
import useKeepSessionAlive from "@:lite/utils/auth/useKeepSessionAlive";
import { createApolloClient } from "@:lite/utils/createApolloClient";
import { useSettingsStore } from "@:lite/zustand/useSettingsStore";

import MainRouter from "../routes";
import SignIn from "./signIn";

const env = window.DOLITTLE_ENVIRONMENT;

if (env === "test" || env === "prod") {
  // See documentation/Sentry.md for more info.
  Sentry.init({
    environment: env, // "local", "test" or "prod". (DEV doesn't have setup this var still)
    release: `pcb-portlite-${window.PB_RELEASE_SHA}`,
    dsn: "https://d0c8b46c310c4f76a7e3687801776cf7@o4504790414065664.ingest.sentry.io/4505471178833920",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0, // default 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeBreadcrumb: excludeGraphQLFetch,
  });
}

declare global {
  interface Window {
    PB_GATEWAY_URL: string;
    BEARER: string;
    DEBUG_WS: boolean;
    DEBUG_MERGE: boolean;
    DEBUG_AUTH: number;
    CURRENT_USER_EMAIL: string | undefined;
    BYPASS_MUTATION_PROTECTION: boolean;
    DOLITTLE_ENVIRONMENT: string;
    PB_RELEASE_SHA: string;
    PB_BACKEND_URL: string;
  }
}
// Set the default Time zone for the app. See README
moment?.tz?.setDefault("Europe/Oslo");
// Set Monday as first day of the week, not Sunday. (This is visible in theMUI date picker)
moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});

// eslint-disable-next-line no-console
console.log(
  "NSG Planning and Collaboration Board, built by nordlys.studio",
  "https://nordlys.studio",
);

// Root component
const App: FC = () => {
  const gatewayUrl = window.PB_GATEWAY_URL;
  const { instance, inProgress, accounts } = useMsal();
  const setUserData = useSettingsStore((state) => state.setUserData);
  const setOrderTypeFilter = useSettingsStore(
    (state) => state.setOrderTypeFilter,
  );
  const selectedOrderType = useSettingsStore(
    (state) => state.selectedOrderType,
  );

  const highContrastMode = useSettingsStore((state) => state.highContrastMode);
  useEffect(() => {
    document.body.className = `theme-${
      highContrastMode ? "high-contrast-colors" : "normal-colors"
    }`;
  }, [highContrastMode]);

  const [activeToken, setActiveToken] = useState<string>("");

  useKeepSessionAlive(instance, activeToken, setActiveToken, accounts);
  const account = accounts[0];

  // It gets executed for *every* GraphQL query
  const tokenValidityCheck = async () => {
    // If user is authenticated, get a token:

    if (account && inProgress === InteractionStatus.None) {
      try {
        // acquireTokenSilent reads token from cache
        const response = await instance.acquireTokenSilent({
          scopes: ["User.Read"],
          account,
        });
        let userCompany = "";
        const userFacilityIds = [];
        const userOrderTypes = [];
        const groups = account?.idTokenClaims?.groups as string[];
        // groups.push("pcb_facilityid_221", "pcb_facilityid_230");
        groups.push("pcb_customerid_50001");
        // 104 is for Vessels and WOs connected to Vessels
        // groups.push("pcb_ordertype_104");
        // groups.push("pcb_ordertype_108");

        const facilityRegex = /pcb_facilityid_(\d+)/;
        const customerRegex = /pcb_customerid_(\d+)/;
        const orderTypeRegex = /pcb_ordertype_(\d+)/;

        if (groups) {
          groups.forEach((str) => {
            let match;

            match = str.match(facilityRegex);
            if (match && !userFacilityIds.includes(match[1])) {
              userFacilityIds.push(match[1]);
            }

            match = str.match(orderTypeRegex);
            if (match && !userOrderTypes.includes(match[1])) {
              userOrderTypes.push(match[1]);
            }

            match = str.match(customerRegex);
            if (match) {
              // eslint-disable-next-line prefer-destructuring
              userCompany = match[1];
            }
          });
        }

        // If user has persisted in this localstorage this setting, we want to reset this impossible state:
        const impossibleState =
          !(Array.isArray(userOrderTypes) && userOrderTypes.length === 0) &&
          selectedOrderType.some(
            (each) => !userOrderTypes.includes(each?.value),
          );
        if (impossibleState) {
          setTimeout(() => {
            // sometimes this trigger too early fetchQuays when apolloClient is not ready. Give it 100 ms
            setOrderTypeFilter([], 100);
          });
        }
        setUserData({
          displayName: account?.name ?? "",
          mail: account?.username ?? "",
          userCompany,
          userFacilityIds,
          userOrderTypes,
        });

        window.CURRENT_USER_EMAIL = account?.username ?? "";
        window.BEARER = response.idToken;
        setActiveToken(response.idToken);
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          captureMessage(
            "Acquire token silent failure (tokenValidityCheck), redirecting to login",
          ); // Expected behavior, but we want to see how often happens
          redirectToLogin(instance);
        }
      }
    } else if (!account && inProgress === InteractionStatus.None) {
      redirectToLogin(instance);
    }
  };

  let apolloClient;
  if (account && inProgress === InteractionStatus.None) {
    if (!activeToken) {
      tokenValidityCheck();
    } else {
      apolloClient = createApolloClient(gatewayUrl, tokenValidityCheck);
    }
  }

  // We make sure to initialize apollo client only when ready for performance (300ms faster), and also
  // because multiple re-renders of ApolloProvider breaks the Apollo Devtools extension: https://github.com/apollographql/apollo-client-devtools/issues/822#issuecomment-1315765497
  if (apolloClient) {
    return (
      <ApolloProvider client={apolloClient}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <AppVersionCheck />
          <Router>
            <MainRouter />
          </Router>
        </LocalizationProvider>
      </ApolloProvider>
    );
  }
  // This check is basically the same than msal's isAuthenticated.
  if (account) {
    return <div>Authenticating ...</div>;
  }

  return <SignIn />;
};

export default App;
