import React, { FC, memo } from "react";

import GroupButtons from "@:lite/components/common/formElements/groupButtons";
import clockIcon from "@:lite/style/img/clock.svg";
import linesIcon from "@:lite/style/img/lines.svg";
import { useSettingsStore } from "@:lite/zustand/useSettingsStore";

import BasicSwitch from "../formElements/switcher";

import styles from "./index.module.scss";
import textStyles from "@:lite/style/textStyles.module.scss";

const SettingsForm: FC = () => {
  const inDetailedView = useSettingsStore((state) => state.inDetailedView);
  const setInDetailedView = useSettingsStore(
    (state) => state.setInDetailedView,
  );

  const highContrastMode = useSettingsStore((state) => state.highContrastMode);
  const setHighContrastMode = useSettingsStore(
    (state) => state.setHighContrastMode,
  );

  return (
    <div className={styles.settings}>
      <div>
        <div>
          <span className={textStyles.secondaryText}>High Contrast mode</span>
          <BasicSwitch
            checked={highContrastMode}
            onChange={setHighContrastMode}
          />
        </div>
        <p className={textStyles.secondaryText}>
          Change the color sheme to experience increased contrast on low res
          screens.
        </p>
      </div>
      <div>
        <div>
          <span className={textStyles.secondaryText}>
            Select default activity board view
          </span>
        </div>
        <p
          className={textStyles.secondaryText}
          style={{ marginTop: "10px", marginBottom: "0px" }}
        >
          Detailed view will display all order information on the card
          regardless of the time constraints.
        </p>
        <p className={textStyles.secondaryText}>
          Timed view may truncate order details in order to show accurate length
          of order time.
        </p>
        <div className={styles.groupButton}>
          <GroupButtons
            firstTitle={linesIcon}
            secondTitle={clockIcon}
            iconsAllowed
            textAllowed
            leftButtonText="Detailed view"
            rightButtonText="Timed view"
            activeButtonIndex={inDetailedView ? 1 : 2} // 1 is Enabled
            handleActiveButton={setInDetailedView}
          />
        </div>
      </div>
    </div>
  );
};
export default memo(SettingsForm);
