import React, { FC, useCallback, useState } from "react";
import { FileError, FileWithPath, useDropzone } from "react-dropzone";
import { CircularProgress } from "@mui/material";

import { captureError } from "@:lite/helpers/captureError";
import { sleep } from "@:lite/helpers/sleep";
import useRegisterAttachment from "@:lite/hooks/mutation/useRegisterAttachment";
import DownloadIcon from "@:lite/style/img/download.svg";
import UploadIcon from "@:lite/style/img/upload.svg";
import { Attachment } from "@:lite/types";
import { useSettingsStore, useUserData } from "@:lite/zustand/useSettingsStore";
import { useSnackbar } from "@:lite/zustand/useSnackbar";

import styles from "./sidePanel.module.scss";
import textStyles from "@:lite/style/textStyles.module.scss";

type Props = {
  readOnly: boolean;
  orderType: "vessel" | "workorder";
  rentLine?: string;
  rentLineNumber?: string;
  attachments?: Attachment[];
  setTempFiles?: (att: File[]) => void;
  refetch?: any;
};
type CustomFileValues = {
  uploading: boolean;
};

const supportedFileExtensions = [
  "png",
  "gif",
  "jpeg",
  "jpg",
  "pdf",
  "doc",
  "docx",
  "txt",
  "xls",
  "xlsx",
  "msg",
  "pptx",
  "docm",
  "dotx",
  "dot",
  "xlsm",
  "rtf",
];

function fileExtensionValidator(file: File) {
  const noExtension = file.name.indexOf(".") === -1;
  const fileExtension = file.name.split(".").pop() || "";
  if (noExtension || !supportedFileExtensions.includes(fileExtension)) {
    return {
      code: "not-allowed-extension",
      message: `Oops! We don't support that file type. Try again with a: ${supportedFileExtensions.join(
        ", ",
      )} file.`,
    };
  }

  return null;
}

const absoluteURL = window.PB_BACKEND_URL;

const uploadFile = async (
  file: FileWithPath,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  uploadParams: any,
  refetch?: any,
): Promise<void> => {
  const {
    orderType,
    rentLineNumber,
    rentLine,
    facilityId,
    issuedBy,
    registerAttachment,
  } = uploadParams;
  const formData = new FormData();
  formData.append("attachment", file, file.name);
  // TODO: Try with parallel requests, using Promise.all

  const uploadURL = `${absoluteURL}/attachments/customers/${orderType}/send?${
    orderType === "workorder"
      ? `mCOorderNumber=${rentLineNumber}&mCOorderLine=${rentLine}`
      : `rentalAgreementNumber=${rentLineNumber}&rentalAgreementLine=${rentLine}`
  }`;
  // eslint-disable-next-line no-await-in-loop
  const uploadResponse = await fetch(uploadURL, {
    method: "POST",
    headers: { Authorization: `Bearer ${window.BEARER}` },
    body: formData,
  });
  if (!uploadResponse.ok) {
    const message = `An uploading error has occured: ${uploadResponse.status}`;
    throw new Error(message);
  }

  // eslint-disable-next-line no-await-in-loop
  const uploadResponseJSON = await uploadResponse.json();
  const variables = {
    attachmentId: uploadResponseJSON?.content?.headers?.[0]?.value[0],
    displayName: file.path,
    facilityId,
    issuedBy,
    filename: file.path, // or maybe wait for final value from REST response
    fileSizeBytes: parseInt(
      uploadResponseJSON?.content?.headers?.[1]?.value[0],
      10,
    ),
    mimeType: file.type,
    // GraphQL will filter the right values for each mutation:
    // If Vessel:
    rentalAgreementLine: rentLine,
    rentalAgreementNumber: rentLineNumber,
    // If WorkOrder:
    mCOorderLine: rentLine,
    mCOorderNumber: rentLineNumber,
  };
  // eslint-disable-next-line no-await-in-loop
  await registerAttachment(orderType, variables);
  setTimeout(() => {
    refetch();
  }, 4000);
};

const DropZone: FC<Props> = ({
  attachments = [],
  // We want to know if it's undefined or not. Disable eslint rule:
  // eslint-disable-next-line react/require-default-props
  setTempFiles,
  orderType,
  rentLine = "",
  rentLineNumber = "",
  readOnly,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refetch = () => {},
}) => {
  const isNewGhostActivity = !!setTempFiles;
  const userData = useUserData();
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);
  const { registerAttachment } = useRegisterAttachment();
  const setSnack = useSnackbar((state) => state.setSnack);
  // We need to track the files list, because react-dropzone doesn't provide an API for removing items
  // from acceptedFiles https://github.com/react-dropzone/react-dropzone/issues/805
  const [localFilesList, setLocalFilesList] = useState<
    (FileWithPath & CustomFileValues)[]
  >([]);

  // TODO : manage multiple files with same filename so React key is unique.
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const onDrop = useCallback(
    async (acceptedFiles) => {
      // Mutates acceptedFiles too. TODO: Make it more granular, one file at the time.
      const inProgressFiles = acceptedFiles.map(
        (file: FileWithPath & CustomFileValues) => {
          const mutatedFile = file;
          // We don't track uploading progress for instant new ghost activity attachments
          if (!isNewGhostActivity) {
            mutatedFile.uploading = true;
          }
          return file;
        },
      );
      if (!isNewGhostActivity) {
        setIsUploading(true); // can be multiple ones
      }
      setLocalFilesList([...localFilesList, ...inProgressFiles]);
      try {
        // In the Ghost creation form, we hold the files in local state until user saves the whole form.
        // Otherwise, we upload files as soon as we have them.
        if (isNewGhostActivity) {
          setTempFiles(acceptedFiles);
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const file of acceptedFiles) {
            try {
              // eslint-disable-next-line no-await-in-loop
              await uploadFile(
                file,
                {
                  orderType,
                  rentLineNumber,
                  rentLine,
                  facilityId: selectedFacility,
                  issuedBy: userData.mail,
                  registerAttachment,
                },
                refetch,
              );
            } catch (e) {
              setSnack(
                true,
                "Sorry, it looks like we couldn't handle that file. Please try again!",
                true,
                "error",
              );
            }
          }
        }
      } catch (error) {
        setSnack(
          true,
          "Something went wrong uploading the files.",
          true,
          "error",
        );
        captureError(error);
      }
      await sleep(4000); // to try to match the timing when the the file will be already present in the attachments props.

      // Remove the "uploading" file from the list because it will show up as an already-uploaded "attachment"
      if (!isNewGhostActivity) {
        setLocalFilesList([...localFilesList]);
      }
      setIsUploading(false);
    },
    [
      isNewGhostActivity,
      localFilesList,
      setTempFiles,
      orderType,
      rentLineNumber,
      rentLine,
      selectedFacility,
      userData.mail,
      registerAttachment,
      refetch,
      setSnack,
    ],
  );

  const { getRootProps, getInputProps, fileRejections, isDragActive } =
    useDropzone({
      onDrop,
      maxSize: 52428800, // 50 Mb = 52,428,800 bytes
      validator: fileExtensionValidator,
      // accept attribute is a mess when you have to declare also mime types. https://github.com/react-dropzone/react-dropzone/issues/276
      // The benefit is that it will apply the filter when user opens their OS file picker
      // Instead we are applying an extensions check inside onDrop()
      // accept: {},
    });

  // Solution inspired on:
  // https://stackoverflow.com/questions/29200256/wait-for-user-to-finish-downloading-a-blob-in-javascript/66905746#66905746
  // https://github.com/eligrey/FileSaver.js/issues/774

  const downloadFile = async (file: Attachment) => {
    const apiURL = `${absoluteURL}/attachments/customers/${orderType}/get?${
      orderType === "workorder"
        ? `mCOorderNumber=${rentLineNumber}&mCOorderLine=${rentLine}&attachmentId=${file.id}&fileName=${file.filename}`
        : `rentalAgreementNumber=${rentLineNumber}&rentalAgreementLine=${rentLine}&attachmentId=${file.id}&fileName=${file.filename}`
    }`;
    try {
      const response = await fetch(apiURL, {
        headers: {
          Authorization: `Bearer ${window.BEARER}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to download file");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.target = "_blank";
      link.download = file.filename;
      document.body.appendChild(link);
      setTimeout(() => link.click(), 0);
      window.setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 40 /* sec */ * 1000);
    } catch (error) {
      captureError(error);
    }
  };

  const alreadyUploadedJSX = attachments?.map((file: Attachment) => (
    <div className={`${textStyles.primaryText} drop-file`} key={file.id}>
      <div>
        <img src={DownloadIcon} alt="download" />
        <button
          className={styles.downloadButton}
          type="button"
          onClick={() => downloadFile(file)}
        >
          {file.filename}
        </button>
      </div>
    </div>
  ));

  const acceptedFilesJSX = localFilesList.map(
    (file: FileWithPath & CustomFileValues, i: number, index) => (
      <div
        className={`${textStyles.primaryText} drop-file`}
        key={`${index}-${file.path}`}
      >
        <div>
          <span>{file.path}</span>
        </div>

        {file.uploading && "uploading..."}
      </div>
    ),
  );

  const rejectedFilesJSX = fileRejections.map(
    ({ file, errors }: { file: FileWithPath; errors: FileError[] }) => (
      <span key={file.path}>
        <strong>{file.path}</strong>

        {errors.map((e) =>
          e.code === "file-too-large" ? (
            <div key="file-too-large">
              Sorry! We can handle file this large, only smaller than 50MB.
            </div>
          ) : (
            <div key={e.code}> {e.message}</div>
          ),
        )}
      </span>
    ),
  );

  if (readOnly) {
    return (
      <aside className="attachments">
        {alreadyUploadedJSX}
        {acceptedFilesJSX}
      </aside>
    );
  }
  return (
    <>
      <aside className="attachments">
        {alreadyUploadedJSX}
        {acceptedFilesJSX}
      </aside>

      {!isUploading ? (
        <div
          {...getRootProps({
            className: `dropzone${isDragActive ? " isDragging" : ""}`,
          })}
        >
          <input {...getInputProps()} />
          <p className={textStyles.secondaryText}>Upload or drag files here</p>
          <p className={textStyles.secondaryText}>
            <img src={UploadIcon} alt="upload" /> Upload
          </p>
        </div>
      ) : (
        <div className="dropzone">
          <CircularProgress />
        </div>
      )}
      <aside className="error-message">{rejectedFilesJSX}</aside>
    </>
  );
};

export default DropZone;
