import React, { FC, useState } from "react";
import buttonStyle from "./fullWidthButton.module.scss";
import textStyles from "@/style/textStyles.module.scss";

interface Props extends React.HTMLProps<HTMLButtonElement> {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  className?: string;
}

const fulWidthButton: FC<Props> = ({
  children,
  onClick,
  className = "",
  disabled = false,
  title = "",
}) => {
  return (
    <button
      className={`${textStyles.primaryText} ${buttonStyle.fullWidthButton} ${className}`}
      type="button"
      disabled={disabled}
      onClick={onClick}
      title={title}
    >
      {children}
    </button>
  );
};

export default fulWidthButton;
