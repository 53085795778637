import { gql, useMutation } from "@apollo/client";

// Example values:
//     facilityId: "230"
//     filename: "Conduktor1.png"
//     fileSizeBytes: 28
//     issuedBy: "aleksandar@dolittle.com"
//     mimeType: "text/plain"
//     mCOorderLine: "182"
//     mCOorderNumber: "0001004005"
//     attachmentId: "58118459-3f85-4937-a08d-66b1020cf456"
const REGISTER_WORKORDER_ATTACHMENT_M3 = gql`
  mutation registerWorkOrderAttachmentInM3(
    $displayName: String!
    $facilityId: String!
    $filename: String!
    $fileSizeBytes: Int!
    $issuedBy: String!
    $mimeType: String!
    $attachmentId: String!
    # Specific to WorkOrder:
    $mCOorderLine: String!
    $mCOorderNumber: String!
  ) {
    registerWorkOrderAttachmentInM3(
      displayName: $displayName
      facilityId: $facilityId
      filename: $filename
      fileSizeBytes: $fileSizeBytes
      issuedBy: $issuedBy
      mimeType: $mimeType
      attachmentId: $attachmentId
      # Specific to WorkOrder:
      mCOorderLine: $mCOorderLine
      mCOorderNumber: $mCOorderNumber
    )
  }
`;

// Example values:
//     facilityId: "230"
//     filename: "Conduktor1.png"
//     fileSizeBytes: 28
//     issuedBy: "aleksandar@dolittle.com"
//     mimeType: "image/png"
//     rentalAgreementLine: "501"
//     rentalAgreementNumber: "A103045"
//     attachmentId: "7ad00b8c-ca52-4f3a-902a-c992c510d4c2"

const REGISTER_VESSEL_ATTACHMENT_M3 = gql`
  mutation registerVesselAttachmentInM3(
    $facilityId: String!
    $displayName: String!
    $filename: String!
    $fileSizeBytes: Int!
    $issuedBy: String!
    $mimeType: String!
    $attachmentId: String!
    # Specific to Vessel:
    $rentalAgreementLine: String!
    $rentalAgreementNumber: String!
  ) {
    registerVesselAttachmentInM3(
      facilityId: $facilityId
      displayName: $displayName
      filename: $filename
      fileSizeBytes: $fileSizeBytes
      issuedBy: $issuedBy
      mimeType: $mimeType
      attachmentId: $attachmentId
      # Specific to Vessel:
      rentalAgreementLine: $rentalAgreementLine
      rentalAgreementNumber: $rentalAgreementNumber
    )
  }
`;

const useRegisterAttachment = (): {
  registerAttachment: typeof registerAttachment;
} => {
  const [registerVesselAttachment, { error }] = useMutation(
    REGISTER_VESSEL_ATTACHMENT_M3,
  );
  const [registerWorkOrderAttachment, { error: error2 }] = useMutation(
    REGISTER_WORKORDER_ATTACHMENT_M3,
  );

  if (error) {
    console.error(error);
  }
  if (error2) {
    console.error(error2);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const registerAttachment = async (orderType: string, variables: any) => {
    if (orderType === "vessel") {
      await registerVesselAttachment({
        variables,
      });
    } else if (orderType === "workorder") {
      await registerWorkOrderAttachment({
        variables,
      });
    }
  };

  return { registerAttachment };
};

export default useRegisterAttachment;
