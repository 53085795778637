import React, { FC, useCallback, useState } from "react";
import { useDrag } from "react-dnd";

import { ORDER_DRAGGABLE_SIDE_WIDTH } from "@:lite/helpers/constants";
import {
  DraggableItemType,
  DraggableSideDnDItem,
  DraggableSideProps,
  FunctionParams,
  Nullable,
} from "@:lite/types";
import { useSettingsStore } from "@:lite/zustand/useSettingsStore";

import { OnDragLayerStateChangedFn } from "../dragLayer";

import styles from "./draggableSide.module.scss";

export const NonDraggableSide: FC<DraggableSideProps> = ({
  styles: { position, backgroundColor, cursor },
}) => {
  return (
    <div
      className={styles.nonDraggableRoot}
      style={{
        ...position,
        backgroundColor,
        cursor,
        maxHeight: "62px",
        width: ORDER_DRAGGABLE_SIDE_WIDTH,
      }}
    />
  );
};
export const DraggableSide: FC<DraggableSideProps> = ({
  orderId,
  quayId,
  styles: { position, backgroundColor, cursor },
  position: itemPosition,
  parentItemType,
  onDrop,
}) => {
  const isDragging = useSettingsStore((state) => state.isDragging);
  const [rootRefHost, setRootRefHost] = useState<Nullable<HTMLElement>>();
  const [, dragRef] = useDrag<DraggableSideDnDItem>(
    () => ({
      type: DraggableItemType.DraggableSide,
      item: {
        orderId,
        draggingTheSide: true,
        quayId,
        position: itemPosition,
        parentItemType,
        element: rootRefHost,
      },
      end: (item, monitor) => {
        const data: FunctionParams<OnDragLayerStateChangedFn> = {
          clientOffset: monitor.getClientOffset(),
          differenceByXFromInitialOffset:
            monitor.getDifferenceFromInitialOffset()?.x,
          item,
          itemType: monitor.getItemType() as Nullable<DraggableItemType>,
          parentItemType: item.parentItemType,
        };

        onDrop(data);
      },
    }),
    [orderId, quayId, itemPosition, parentItemType, rootRefHost, onDrop],
  );

  const rootRef: React.RefCallback<HTMLElement> = useCallback((element) => {
    setRootRefHost(element);
  }, []);

  return (
    <div
      ref={rootRef}
      className={styles.root}
      style={{
        ...position,
        backgroundColor,
        cursor,
        maxHeight: "62px",
        width: ORDER_DRAGGABLE_SIDE_WIDTH,
        // This hackish manipulation of zIndex is done to "remove" this out of the way and doesn't conflict when drag&dropping an allocation.
        // When you drag an allocation on top this draggable side,
        // the droppable areas (group and tableRow) are not able to detect there is a draggable item on top of them (isOver) because this item "eats" the event (as I understand)
        zIndex: isDragging ? -1 : 2,
      }}
    >
      <div className={styles.draggableHandle} ref={dragRef} />
    </div>
  );
};
