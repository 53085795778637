/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */

import moment, { Moment } from "moment-timezone";

interface Task {
  normalizedTotalPipeLoad: number;
  normalizedTotalRegularLoad: number;
  earliestPlannedStart?: string;
  latestPlannedStop?: string;
  plannedStartDate: string;
  plannedStopDate: string;
  adjustedStopDate: string;
  stackIndex: number;
  allocHeight: number;
  yPosition: number;
  yPositionPx: number;
  hideInTimeline: boolean;
}

export const MINIMUM_MINUTES = 3 * 60; // 3 hours
const MINIMUM_ORDER_DURATION = MINIMUM_MINUTES * 60 * 1000; // 3 hours in milliseconds

const DEFAULT_ALLOCATION_HEIGHT = 68; // I know, it's a duplicate const, but if I import it, it creates a circular dependency.
export const Y_LEVEL_IN_PIXELS = 17;

function forceMinimumDuration(
  plannedStartDate: string,
  plannedStopDate: string,
): Date {
  const startDate = new Date(plannedStartDate);
  const stopDate = new Date(plannedStopDate);

  // Calculate the difference
  const duration = stopDate.getTime() - startDate.getTime();
  // If the duration is less than 3 hours, adjust the stop date
  if (duration < MINIMUM_ORDER_DURATION) {
    return new Date(startDate.getTime() + MINIMUM_ORDER_DURATION);
  }

  return stopDate;
}

function isPositionAvailable(
  task: Task,
  yPosPx: number,
  tasks: Task[],
): boolean {
  // eslint-disable-next-line no-restricted-syntax
  for (const other of tasks) {
    // eslint-disable-next-line no-continue
    if (other === task) continue;

    // eslint-disable-next-line no-continue
    if (other.yPosition === undefined) continue;
    // let earliestStart = task.adjustedStartDate
    //   ? task.adjustedStartDate
    //   : task.earliestPlannedStart;

    // earliestStart =
    //   earliestStart < new Date(task.normalizedStartDate)
    //     ? task.normalizedStartDate
    //     : earliestStart;

    // // eslint-disable-next-line no-nested-ternary
    // let latestStop = task.adjustedStopDate
    //   ? task.adjustedStopDate
    //   : task.latestPlannedStop;

    // latestStop =
    //   latestStop > new Date(task.normalizedStopDate)
    //     ? task.normalizedStopDate
    //     : latestStop;

    // const adjustedMinStopDate = forceMinimumDuration(earliestStart, latestStop);

    // const otherEarliestStart = other.adjustedStartDate
    //   ? other.adjustedStartDate
    //   : other.earliestPlannedStart;

    // const otherLatestStop = other?.adjustedStopDate
    //   ? other.adjustedStopDate
    //   : other.latestPlannedStop;
    // const otherAdjustedMinStopDate = forceMinimumDuration(
    //   otherEarliestStart,
    //   otherLatestStop
    // );

    let start = task.visibleStart;
    let stop = task.visibleStop;

    let otherStart = other.visibleStart;
    let otherStop = other.visibleStop;

    // For vessels:
    if (task.adjustedStartDate) {
      start = task.adjustedStartDate;
    }
    if (task.adjustedStopDate) {
      stop = task.adjustedStopDate;
    }
    if (other.adjustedStartDate) {
      otherStart = other.adjustedStartDate;
    }
    if (other.adjustedStopDate) {
      otherStop = other.adjustedStopDate;
    }

    const visibleStopAdjustedToMinimumDuration = forceMinimumDuration(
      start,
      stop,
    );
    const otherVisibleStopAdjustedToMinimumDuration = forceMinimumDuration(
      otherStart,
      otherStop,
    );
    const isXOverlap =
      new Date(start) < otherVisibleStopAdjustedToMinimumDuration &&
      visibleStopAdjustedToMinimumDuration > new Date(start);

    // if (task?.id.includes("A103045:723:094916")) {
    //   // REM ART
    //   // 12 dec 1006911634
    //   // debugger;
    //   console.log(
    //     isXOverlap,
    //     other.id,
    //     new Date(start),
    //     " < ",
    //     otherVisibleStopAdjustedToMinimumDuration,
    //     new Date(start) < otherVisibleStopAdjustedToMinimumDuration
    //   );
    //   console.log(
    //     isXOverlap,
    //     other.id,
    //     visibleStopAdjustedToMinimumDuration,
    //     " > ",
    //     new Date(start),
    //     visibleStopAdjustedToMinimumDuration > new Date(start)
    //   );
    //   console.log(other, otherStart, otherStop);
    // }

    // const isXOverlap =
    //   new Date(task.visibleStart) < new Date(other.visibleStop) &&
    //   new Date(task.visibleStop) > new Date(other.visibleStart);

    // const isXOverlap =
    //   new Date(earliestStart) < otherAdjustedMinStopDate &&
    //   new Date(task.visibleStop) > new Date(other.visibleStart);
    // console.log(other.adjustedStopDate);
    const isYOverlap =
      yPosPx < other.yPositionPx! + other.allocHeight &&
      yPosPx + task.allocHeight > other.yPositionPx!;

    if (isXOverlap && isYOverlap) {
      return false;
    }
  }

  return true;
}

function findAvailableYPosition(task: Task, tasks: Task[]): number {
  let yPos = 0;
  while (true) {
    if (isPositionAvailable(task, yPos * Y_LEVEL_IN_PIXELS, tasks)) {
      return yPos;
    }
    yPos += 1;
  }
}

export function intervalGraphColoring(
  tasks: Task[],
  parentOrder: any,
  visibleStartDate: Moment,
  visibleEndDate: Moment,
): number {
  if (!tasks) {
    return 0;
  }
  let totalHeight = 0;
  // Sort tasks by their start time
  tasks.sort((task1: Task, task2: Task) => {
    // const start1 = task1.earliestPlannedStart
    //   ? task1.earliestPlannedStart
    //   : task1.plannedStartDate;

    // const start2 = task2.earliestPlannedStart
    //   ? task2.earliestPlannedStart
    //   : task2.plannedStartDate;
    return (
      new Date(task1.earliestPlannedStart).getTime() -
      new Date(task2.earliestPlannedStart).getTime()
    );
  });

  // Assign initial values to the first task.
  // tasks[0].yPosition = 0;
  // tasks[0].yPositionPx = 0;

  if (parentOrder?.id) {
    // if (parentOrder?.id === "A103051:425:142317") {
    // const adjustedStopDate0 = adjustStopDate(
    //   tasks[0].plannedStartDate,
    //   tasks[0].plannedStopDate
    // );
    // tasks[0].adjustedStopDate = adjustedStopDate0.toISOString();

    // Because we have some edge cases where attached work order is the day before the vessel, creating negative width allocations
    // if (new Date(adjustedStopDate0) > new Date(parentOrder.plannedStopDate)) {
    //   parentOrder.adjustedStopDate = adjustedStopDate0.toISOString();
    // } else {

    // Just initial values, so algo doesn't break:
    parentOrder.adjustedStartDate = parentOrder?.startDate
      ? parentOrder?.startDate
      : parentOrder.plannedStartDate;
    parentOrder.adjustedStopDate = parentOrder?.stopDate
      ? parentOrder?.stopDate
      : parentOrder.plannedStopDate;

    // } else if (!tasks[0]?.adjustedStopDate) {
    //    tasks[0].adjustedStopDate = tasks[0].plannedStopDate;
  }

  let firstProcessed = false;

  // Preparations before stacking algo
  for (let i = 0; i < tasks.length; i++) {
    const thisTask = tasks[i];
    delete thisTask.yPosition;
    delete thisTask.yPositionPx;

    for (let m = 0; m < tasks.length; m++) {
      if (thisTask.hideInTimeline) {
        // eslint-disable-next-line no-continue
        continue;
      }

      let finalStartDate = thisTask?.plannedStartDate;
      // For vessels and sub-wos
      if (
        thisTask?.normalizedOrderType !== "wo" &&
        thisTask?.normalizedOrderType !== "subwo"
      ) {
        finalStartDate = thisTask?.startDate
          ? thisTask?.startDate
          : thisTask.plannedStartDate;
      }

      thisTask.visibleStart = moment(finalStartDate).isSameOrBefore(
        visibleStartDate,
      )
        ? visibleStartDate.toISOString()
        : finalStartDate;

      let finalStopDate = thisTask?.plannedStopDate;
      if (
        thisTask?.normalizedOrderType !== "wo" &&
        thisTask?.normalizedOrderType !== "subwo"
      ) {
        finalStopDate = thisTask?.stopDate
          ? thisTask?.stopDate
          : thisTask.plannedStopDate;
      }

      thisTask.visibleStop = finalStopDate;
      // This is for the case of a attached WO that has "actual start" in the future but not yet "actual stop". And the planned stop is today.
      // if (moment(finalStartDate).isSameOrAfter(visibleEndDate)) {
      //   const temp = forceMinimumDuration(
      //     finalStartDate,
      //     finalStartDate, // Notice here, a bit hacky, but I don't want to rewrite it
      //   );
      //   // if (thisTask.id === "1007164208") {
      //   //   debugger;
      //   // }
      //   thisTask.visibleStop = temp.toISOString();
      // } else if (moment(finalStopDate).isSameOrAfter(visibleEndDate)) {
      //  thisTask.visibleStop = visibleEndDate.toISOString();
      if (moment(finalStopDate).isSameOrAfter(visibleEndDate)) {
        thisTask.visibleStop = visibleEndDate.toISOString();
      }
    }

    // TODO: This belongs outside of this algo:
    if (parentOrder?.id) {
      let minimumStartDate = parentOrder.adjustedStartDate;
      for (let m = 0; m < tasks.length; m++) {
        if (thisTask.hideInTimeline) {
          // eslint-disable-next-line no-continue
          continue;
        }

        // adjustedStartDate is for comparison purposes, but never visualized.
        minimumStartDate =
          new Date(thisTask.visibleStart) < new Date(minimumStartDate)
            ? thisTask.visibleStart
            : minimumStartDate;

        // const adjustedStopDate = forceMinimumDuration(
        //   thisTask.visibleStart,
        //   thisTask.visibleStop
        // );
        // tasks[m].adjustedStopDate = adjustedStopDate.toISOString();
        // Because we have some edge cases where attached work order is the day before the vessel, creating negative width allocations
        if (
          new Date(thisTask.visibleStart) <
          new Date(parentOrder.adjustedStartDate)
        ) {
          parentOrder.adjustedStartDate = thisTask.visibleStart;
        }

        if (
          new Date(thisTask.visibleStop) >
          new Date(parentOrder.adjustedStopDate)
        ) {
          parentOrder.adjustedStopDate = thisTask.visibleStop;
        }
      }
      parentOrder.adjustedStartDate = minimumStartDate;
    } else if (!thisTask?.adjustedStopDate) {
      thisTask.adjustedStopDate = thisTask?.stopDate
        ? thisTask?.stopDate
        : thisTask.plannedStopDate;
    }
  }
  for (let i = 0; i < tasks.length; i++) {
    const thisTask = tasks[i];
    // if (thisTask?.id.includes("A103048:858:2245302")) {
    // console.log(thisTask.id);
    // }
    if (thisTask.hideInTimeline) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (!firstProcessed) {
      thisTask.yPosition = 0;
      thisTask.yPositionPx = 0;
      firstProcessed = true;
    } else {
      tasks[i].yPosition = findAvailableYPosition(tasks[i], tasks);
      tasks[i].yPositionPx = Y_LEVEL_IN_PIXELS * tasks[i].yPosition;
    }

    totalHeight = Math.max(
      totalHeight,
      tasks[i].yPositionPx +
        tasks[i].allocHeight +
        (!parentOrder ? DEFAULT_ALLOCATION_HEIGHT / 2 : 0), // we add the half height of the parent vessel
    );
  }

  // TODO: Return 0, take care of Math.max outside of this function.
  return totalHeight;
}
