import React, { forwardRef, memo, RefObject, useEffect } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import moment from "moment-timezone";

import useWindowDimensions from "@:lite/helpers/useWindowDimensions";
import arrow from "@:lite/style/img/arrow.svg";
import { Nullable } from "@:lite/types";
import { useSettingsStore } from "@:lite/zustand/useSettingsStore";

import styles from "./index.module.scss";
import textStyles from "@:lite/style/textStyles.module.scss";

type Props = {
  timeLine: string[];
  elementWidth: number;
  visibleDays: number;
  headerRef: RefObject<{ element: Nullable<HTMLDivElement> }>;
  selectedDate: Date;
};
const TimelineHeader = forwardRef<HTMLDivElement, Props>(
  ({ elementWidth, timeLine, headerRef, selectedDate, visibleDays }, ref) => {
    const setSelectedDate = useSettingsStore((state) => state.setSelectedDate);
    const dateLabels = Array.from({ length: visibleDays }, () =>
      React.createRef<HTMLDivElement>(),
    );

    const { width } = useWindowDimensions();

    useEffect(() => {
      const el = headerRef?.current?.element;

      const onScroll = () => {
        // TODO: Make variables dynamic, based on visibleDays
        const label1 = dateLabels[0].current;
        const label2 = dateLabels[1].current;

        if (!el?.scrollLeft || !el?.scrollWidth) {
          return;
        }

        // Using percentages: it works no matter what browser zoom level
        const scrollPercent = (el.scrollLeft * 100) / el.scrollWidth;
        if (scrollPercent < 29) {
          label1?.classList?.add("pinned");
          if (label2) label2.className = "to-left";
        } else if (scrollPercent < 33.8) {
          if (label1) label1.className = "to-right";
          if (label2) label2.className = "to-left";
        } else if (scrollPercent < 56.1) {
          label2?.classList?.add("pinned");
        }
      };

      // Code for 3 days view.
      // const onScroll = () => {
      //   const label1 = dateLabels[0].current;
      //   const label2 = dateLabels[1].current;
      //   const label3 = dateLabels[2].current;

      //   if (!el?.scrollLeft || !el?.scrollWidth) {
      //     return;
      //   }
      //   const scrollPercent = (el.scrollLeft * 100) / el.scrollWidth;
      //   if (scrollPercent < 19.58) {
      //     label1?.classList?.add("pinned");
      //     if (label2) label2.className = "to-left";
      //     if (label3) label3.className = "to-left";
      //   } else if (scrollPercent < 22.82) {
      //     if (label1) label1.className = "to-right";
      //     if (label2) label2.className = "to-left";
      //     if (label3) label3.className = "to-left";
      //   } else if (scrollPercent < 52.14) {
      //     if (label1) label1.className = "to-right";
      //     label2?.classList?.add("pinned");
      //     if (label3) label3.className = "to-left";
      //   } else if (scrollPercent < 55.1) {
      //     if (label1) label1.className = "to-right";
      //     if (label2) label2.className = "to-right";
      //     if (label3) label3.className = "to-left";
      //   } else {
      //     if (label1) label1.className = "to-right";
      //     if (label2) label2.className = "to-right";
      //     label3?.classList?.add("pinned");
      //   }
      // };

      el?.addEventListener("scroll", onScroll, { passive: true });
      return () => el?.removeEventListener("scroll", onScroll);
    }, [dateLabels, elementWidth, headerRef, width]);

    const handleNext = () => {
      setSelectedDate(moment(selectedDate).add(1, "day").toDate());
    };

    const handlePrev = () => {
      setSelectedDate(moment(selectedDate).subtract(1, "day").toDate());
    };
    const style = {
      "--timeline--elementWidth": `${elementWidth}px`,
      "--timeline--tickWidth": `${elementWidth}px`,
    } as React.CSSProperties;

    return (
      <div className={styles.timelineHeader} style={style}>
        <div className={styles.extraPadding} />
        <ScrollContainer
          vertical={false}
          className={styles.scrollable}
          innerRef={ref}
        >
          <div className={styles.navigationButtons}>
            <div
              tabIndex={0}
              onKeyDown={() => null}
              role="button"
              onClick={handlePrev}
            >
              <img src={arrow} alt="arrow" />
              <span className={textStyles.primaryText}>PREVIOUS</span>
            </div>
            <div
              tabIndex={0}
              onKeyDown={() => null}
              role="button"
              onClick={handleNext}
            >
              <span className={textStyles.primaryText}>NEXT</span>
              <img src={arrow} alt="arrow" />
            </div>
          </div>
          <div
            className={`${styles.timelineDates} ${textStyles.primaryText}`}
            style={{
              width: `${(timeLine.length * elementWidth) / 2}px`,
            }}
          >
            {/* <div ref={dateLabels[0]} key="date-label-0">
              <span style={{ visibility: "hidden" }}>.</span>
              <div className="inner-fixed">
                {moment(selectedDate)
                  // .subtract(visibleDays, "day")
                  .format("dddd, DD. MMM YYYY")}
              </div>
            </div> */}
            {/* Before: <div ref={dateLabels[1]} key="date-label-1"></div> */}
            <div ref={dateLabels[0]} key="date-label-1">
              <span style={{ visibility: "hidden" }}>.</span>
              <div className="inner-fixed">
                {moment(selectedDate).format("dddd, DD. MMM YYYY")}
              </div>
            </div>
            {/* Before: <div ref={dateLabels[2]} key="date-label-1"></div> */}
            <div ref={dateLabels[1]} key="date-label-2">
              <span style={{ visibility: "hidden" }}>.</span>
              <div className="inner-fixed">
                {moment(selectedDate)
                  .add(1, "day")
                  .format("dddd, DD. MMM YYYY")}
              </div>
            </div>
          </div>

          <div className={`${styles.timeline}`}>
            <div className="timeline-oclock-labels">
              {timeLine.map((el, idx) => {
                if (idx % 2 === 0) {
                  const onlyTime = el.split("-")[1];
                  return (
                    <span key={el}>
                      {`${parseInt(onlyTime.split(":")[0], 10)
                        .toString()
                        .padStart(2, " ")}:${onlyTime.split(":")[1]}`}
                    </span>
                  );
                }
                return null;
              })}
            </div>
            <div className="timeline-ticks">
              {Array.from(Array(timeLine.length / 2).keys()).map((el) => (
                <span key={el} />
              ))}
            </div>
          </div>
        </ScrollContainer>
        <div />
      </div>
    );
  },
);
export default memo(TimelineHeader);
