import React from "react";

import { WorkOrder } from "@:lite/types";
import { useSettingsStore } from "@:lite/zustand/useSettingsStore";

import styles from "./index.module.scss";

type Props = {
  subWo: WorkOrder;
};

const FULL_WIDTH = 210;

// Minimum width for each element
const minWidth = 18;

// Pre-allocated width
const preAllocatedWidth = {
  BACKLOAD: minWidth * 4,
  LOADOUT: minWidth * 5, // it has an extra "Can Not Process" state
};

const commonStyles = {
  width: `${minWidth}px`,
};

const possiblePlanTypes = {
  BACKLOAD: ["vesselQty", "quaySideQty", "warehouseQty", "roundTripQty"],
  LOADOUT: [
    "canNotProcessQty",
    "warehouseQty",
    "quaySideQty",
    "vesselQty",
    "roundTripQty",
  ],
};
const template = {
  vesselQty: {
    title: "Vessel",
    style: {
      ...commonStyles,
      backgroundColor: "#00ADBB",
    },
    hoverColor: "#018893",
  },
  quaySideQty: {
    title: "Quayside",
    style: {
      ...commonStyles,
      backgroundColor: "#86CBD1",
    },
    hoverColor: "#2FC2CF",
  },
  warehouseQty: {
    title: "Warehouse",
    style: {
      ...commonStyles,
      backgroundColor: "#B3D6D8",
    },
    hoverColor: "#87AFB1",
  },
  roundTripQty: {
    title: "Roundtrip",
    style: {
      ...commonStyles,
      backgroundColor: "#727D7D",
      color: "white",
    },
    hoverColor: "#618181",
  },
  canNotProcessQty: {
    title: "Can Not Process",
    style: {
      ...commonStyles,
      backgroundColor: "#F3C7C7",
    },
    hoverColor: "#C39595",
  },
};
const VesselSubWorkOrderLoad: React.FC<Props> = ({ subWo }) => {
  const inDetailedView = useSettingsStore((state) => state.inDetailedView);
  const loadStatus = subWo?.loadingStatusSummary;
  if (!loadStatus?.planType) {
    return null;
  }
  const { planType, planId } = loadStatus;

  const openKonciv = () => {
    const subDomain =
      window.DOLITTLE_ENVIRONMENT === "prod" ? "flow" : "flowqa";
    window.open(
      `https://${subDomain}.norseagroup.com/vessel-loading/plans/tracking/${
        planType === "LOADOUT" ? "loadout" : "backload"
      }/${planId}`,
      "_blank",
    );
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={
        inDetailedView
          ? styles.konciv
          : `${styles.konciv} ${styles.koncivCompact}`
      }
      style={{ width: `${FULL_WIDTH}px` }}
      onClick={openKonciv}
    >
      {/* REGULAR LOAD */}
      {/* ============ */}
      {subWo.normalizedTotalRegularLoad > 0 ? (
        <div className="konciv-regularLoad">
          {possiblePlanTypes[planType].map((categoryKey, index, arr) => {
            const lastIteration = index === arr.length - 1;
            const category = template[categoryKey];
            const thisQty = loadStatus?.regularLoad?.[categoryKey] ?? 0;
            // Remaining width to distribute
            const remainingWidth = FULL_WIDTH - preAllocatedWidth[planType];
            return (
              <span
                key={categoryKey}
                className="konciv-load-stat"
                title={`${category.title} | CCU`}
                style={{
                  ...category.style,
                  width: `${
                    minWidth +
                    (thisQty / subWo.normalizedTotalRegularLoad) *
                      remainingWidth
                  }px`,
                  borderRight: lastIteration ? "none" : "2px solid white",
                  "--dynamic-hover-color": template[categoryKey]?.hoverColor,
                }}
              >
                {thisQty}
              </span>
            );
          })}
        </div>
      ) : null}
      {/* PIPE LOAD */}
      {/* ========= */}
      {subWo.normalizedTotalPipeLoad > 0 ? (
        <div className="konciv-pipeLoad">
          {possiblePlanTypes[planType].map((categoryKey, index, arr) => {
            const lastIteration = index === arr.length - 1;
            const category = template[categoryKey];
            const thisQty = loadStatus?.pipeLoad?.[categoryKey] ?? 0;
            // Remaining width to distribute
            const remainingWidth = FULL_WIDTH - preAllocatedWidth[planType];

            return (
              <span
                key={categoryKey}
                className="konciv-load-stat"
                title={`${category.title} | Pipe`}
                style={{
                  ...category.style,
                  width: `${
                    minWidth +
                    (thisQty / subWo.normalizedTotalPipeLoad) * remainingWidth
                  }px`,
                  borderRight: lastIteration ? "none" : "2px solid white",
                  "--dynamic-hover-color": template[categoryKey]?.hoverColor,
                }}
              >
                {thisQty}
              </span>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default VesselSubWorkOrderLoad;
