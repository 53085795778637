// import take from "lodash/take";
// import moment from "moment-timezone";
// import InfiniteScroll from "react-infinite-scroller";
import React, { FC, memo } from "react";

import { WorkOrder } from "@:lite/types";

import ListItem from "./searchListItem";

import styles from "./index.module.scss";

type Props = {
  orders: WorkOrder | undefined;
  // startDate: Date | undefined;
  // endDate: Date | undefined;
  // searchValue: string;
};
const FilteredList: FC<Props> = ({
  orders,
  // startDate,
  // endDate,
  // searchValue,
}) => {
  // const [showOptionLimit, setOptionLimit] = useState(10);

  // const loadMoreTasks = useCallback(() => {
  //   setOptionLimit(showOptionLimit + 4);
  // }, [showOptionLimit]);

  // const filteredOrders = useMemo(() => {
  //   if (!orders) {
  //     return [];
  //   }
  //   return orders.filter((el) => {
  //     const includeName =
  //       el.description &&
  //       el.description.toLowerCase().includes(searchValue.toLowerCase());
  //     const includeCustomerName =
  //       el.customerName &&
  //       el.customerName.toLowerCase().includes(searchValue.toLowerCase());
  //     const includeOrderNumber =
  //       el?.orderNumber &&
  //       el.orderNumber.toLowerCase().includes(searchValue.toLowerCase());
  //     const isBetween =
  //       startDate &&
  //       endDate &&
  //       moment(el?.startDate).isBetween(startDate, endDate);
  //     if (
  //       (searchValue &&
  //         (includeName ||
  //           includeCustomerName ||
  //           (includeOrderNumber && isBetween))) ||
  //       (!searchValue && isBetween)
  //     ) {
  //       return el;
  //     }
  //     return null;
  //   });
  // }, [endDate, orders, searchValue, startDate]);

  // const renderFilteredOrders = () => {
  // <InfiniteScroll
  //   pageStart={0}
  //   loadMore={loadMoreTasks}
  //   hasMore={filteredOrders.length > showOptionLimit}
  //   useWindow={false}
  // >
  //   {take(filteredOrders, showOptionLimit).map((el) => {
  //     return <ListItem key={el.id} data={el} />;
  //   })}
  // </InfiniteScroll>

  //   <ListItem key={el.id} data={el} />;
  // };

  return (
    <div className={styles.filteredList}>
      {orders && <ListItem key={orders.id} data={orders} />}
    </div>
  );
};
export default memo(FilteredList);
