import { gql, useQuery } from "@apollo/client";

import { Facility } from "@:lite/types";

const QUERY = gql`
  query {
    facilities {
      id
      name
    }
  }
`;

const useGetFacilities = (): Facility[] => {
  const { data } = useQuery(QUERY);
  const facilities = data?.facilities || [];
  return facilities;
};

export default useGetFacilities;
