import React, { FC } from "react";

import {
  ORDER_CARD_BORDER_WIDTH,
  ORDER_DRAGGABLE_SIDE_WIDTH,
} from "@:lite/helpers/constants";
import {
  DroppableItemType,
  OnDraggableSideDropFn,
  // VisualColorTheme,
} from "@:lite/types";

import { DraggableSide, NonDraggableSide } from "./draggableSide";

type Props = {
  orderId: string;
  onDrop: OnDraggableSideDropFn;
  parentItemType: DroppableItemType;
  height: string;
  canDrag: boolean;
  orderRegularWidth: number;
  orderExpandedWidth: number;
  // Not sure if we neeed this?
  // statusCustomisations?: VisualColorTheme;
  inDetailedView: boolean;
  canDragStartSide: boolean;
  canDragStopSide: boolean;
};

const verticalPositionAlignment = {
  top: ORDER_CARD_BORDER_WIDTH,
  bottom: ORDER_CARD_BORDER_WIDTH,
};

const OrderCardDraggableSides: FC<Props> = ({
  orderRegularWidth,
  orderExpandedWidth,
  // statusCustomisations = undefined,
  inDetailedView,
  canDragStartSide,
  canDragStopSide,
  canDrag,
  ...factoryParams
}) => {
  return (
    <>
      {canDrag && canDragStartSide ? (
        <DraggableSide
          {...(factoryParams as any)}
          styles={{
            position: {
              ...verticalPositionAlignment,
              left: ORDER_CARD_BORDER_WIDTH,
            },
            backgroundColor: "transparent",
            cursor: canDragStartSide ? "col-resize" : "not-allowed",
          }}
          position="start"
        />
      ) : (
        <NonDraggableSide
          {...(factoryParams as any)}
          styles={{
            position: {
              ...verticalPositionAlignment,
              left: ORDER_CARD_BORDER_WIDTH,
            },
            backgroundColor: "transparent",
            cursor: canDragStartSide ? "col-resize" : "not-allowed",
          }}
          position="start"
        />
      )}
      {canDrag && canDragStopSide ? (
        <DraggableSide
          {...(factoryParams as any)}
          styles={{
            position: {
              ...verticalPositionAlignment,
              left:
                orderRegularWidth -
                (ORDER_CARD_BORDER_WIDTH + ORDER_DRAGGABLE_SIDE_WIDTH),
            },
            backgroundColor: "transparent",
            cursor: canDragStopSide ? "col-resize" : "not-allowed",
          }}
          position="end"
        />
      ) : (
        <NonDraggableSide
          {...(factoryParams as any)}
          styles={{
            position: {
              ...verticalPositionAlignment,
              left:
                orderRegularWidth -
                (ORDER_CARD_BORDER_WIDTH + ORDER_DRAGGABLE_SIDE_WIDTH),
            },
            backgroundColor: "transparent",
            cursor: canDragStopSide ? "col-resize" : "not-allowed",
          }}
          position="end"
        />
      )}
      {canDrag && canDragStopSide && inDetailedView ? (
        <DraggableSide
          {...(factoryParams as any)}
          styles={{
            position: {
              ...verticalPositionAlignment,
              left:
                orderExpandedWidth -
                (ORDER_CARD_BORDER_WIDTH + ORDER_DRAGGABLE_SIDE_WIDTH),
            },
            backgroundColor: "transparent",
            cursor: canDragStopSide ? "col-resize" : "not-allowed",
          }}
          position="end"
        />
      ) : (
        inDetailedView && (
          <NonDraggableSide
            {...(factoryParams as any)}
            styles={{
              position: {
                ...verticalPositionAlignment,
                left:
                  orderRegularWidth -
                  (ORDER_CARD_BORDER_WIDTH + ORDER_DRAGGABLE_SIDE_WIDTH),
              },
              backgroundColor: "transparent",
              cursor: canDragStopSide ? "col-resize" : "not-allowed",
            }}
            position="end"
          />
        )
      )}
    </>
  );
};

export default OrderCardDraggableSides;
