/* eslint-disable no-param-reassign */
import React, { RefObject, useRef } from "react";
import { useDrop } from "react-dnd";

import {
  DraggableDnDItem,
  DraggableItemType,
  DropEventEmitter,
  DroppableItemType,
  OnDropPayload,
} from "@:lite/types";
import { normalizeKey } from "@:lite/utils/match";
import { useSettingsStore } from "@:lite/zustand/useSettingsStore";

import styles from "./index.module.scss";

type Props = DropEventEmitter & {
  timeLine: string[];
  elementWidth: number;
  quayId: string;
  accept: DraggableItemType[] | string[];
  children: React.ReactNode;
  draggingTooltipRef: RefObject<HTMLDivElement>;
};

export const DroppableCell: React.FC<Props> = ({
  timeLine,
  elementWidth,
  onDrop,
  quayId,
  accept,
  children,
  draggingTooltipRef,
}) => {
  const containerRef = useRef<HTMLDivElement | null>();
  const tooltipRef = useSettingsStore((state) => state.tooltipRef);

  const [{ isOver }, drop] = useDrop<
    DraggableDnDItem,
    unknown,
    { isOver: boolean }
  >(
    () => ({
      accept,
      drop: (item, monitor) => {
        const relative = containerRef?.current.getBoundingClientRect();
        if (!relative) {
          // TODO capturemessage
          return;
        }
        // this doesn't work when you drop it in the nested vesselGroup.
        // We also have to get the x coordinate through getDropResult(). See vesselDroppableGroup drop() return
        const dropCoordinatesRelativeToViewPort = monitor.getClientOffset(); // like {"x": 763,"y": 299}
        const groupDropExtraInfo = monitor.getDropResult() as {
          viewportX: number;
          orderId: string;
        };
        let finalViewportX;
        if (dropCoordinatesRelativeToViewPort) {
          const { x: thisViewportX } = dropCoordinatesRelativeToViewPort;
          finalViewportX = thisViewportX;
        } else {
          const { viewportX } = groupDropExtraInfo;
          finalViewportX = viewportX;
        }
        const { x } = relative;
        const finalX = finalViewportX - x;

        // Convert pixels to time slot:
        const position = Math.floor(finalX / (elementWidth / 2));
        const time = timeLine[position];
        const payload: OnDropPayload = {
          orderId: item.orderId,
          toTime: time,
          toQuay: quayId,
          type: monitor.getItemType() as DroppableItemType,
        };
        console.log("After dropping data: ", groupDropExtraInfo?.orderId, {
          payload,
        });
        onDrop(payload);
      },
      hover: (item, monitor) => {
        // More info https://github.com/react-dnd/react-dnd/issues/151#issuecomment-888268928
        const dropCoordinatesRelativeToViewPort = monitor.getClientOffset(); // like {"x": 763,"y": 299}
        const draggedCoordsRelativeToViewPort = monitor.getSourceClientOffset();
        const relative = containerRef?.current.getBoundingClientRect();
        if (!relative) {
          // TODO capturemessage sentry
          return;
        }
        const { x: viewportX } = dropCoordinatesRelativeToViewPort;
        const { y: viewportY } = draggedCoordsRelativeToViewPort;
        const { x } = relative;
        const finalX = viewportX - x;
        // Convert pixels to time slot:
        const position = Math.floor(finalX / (elementWidth / 2));
        const time = timeLine[position];

        if (draggingTooltipRef.current) {
          draggingTooltipRef.current.innerText = time?.substring(2);
          draggingTooltipRef.current.style.left = `${viewportX}px`;
          draggingTooltipRef.current.style.top = `${viewportY}px`;
        }
      },

      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: false }) && monitor.canDrop(),
      }),
      canDrop: (item) => {
        const hasScopingParams = [item.dragScope, item.excludeDragScopes]
          .map(Array.isArray)
          .some(Boolean);

        if (!hasScopingParams) {
          return true;
        }

        const currentScope = normalizeKey(quayId);

        const itemIsWithinAllowedScope = item.dragScope
          ? item.dragScope.map(normalizeKey).includes(currentScope)
          : true;

        const itemIsOutOfExcludedScope = item.excludeDragScopes
          ? !item.excludeDragScopes.map(normalizeKey).includes(currentScope)
          : true;

        return itemIsWithinAllowedScope && itemIsOutOfExcludedScope;
      },
    }),
    [onDrop, accept, quayId, tooltipRef, containerRef, draggingTooltipRef],
  );

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          background: isOver ? "#f0f4f5" : "#fff",
        }}
        className={styles.tableCell}
        ref={drop}
      >
        {children}
      </div>
    </div>
  );
};
