import React, { memo } from "react";
import { LinearProgress } from "@mui/material";

import { useSettingsStore } from "@:lite/zustand/useSettingsStore";

const TimelineLoadingProgress: React.FC = () => {
  const isFetching = useSettingsStore((state) => state.isFetching);
  return isFetching ? <LinearProgress /> : null;
};

export default memo(TimelineLoadingProgress);
