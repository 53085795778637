import React, { FC, memo, useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";

import arrow from "@:lite/style/img/arrow.svg";
import { WorkOrder } from "@:lite/types";
import { useSettingsStore } from "@:lite/zustand/useSettingsStore";

import FilteredList from "./filteredList";
import SearchFilters from "./searchFilters";

import styles from "./index.module.scss";
import textStyles from "@:lite/style/textStyles.module.scss";

const GET_WORK_ORDER_BY_ID = gql`
  query getWorkOrderById($workOrderId: String!) {
    workOrderById(workOrderId: $workOrderId) {
      id
      customerName
      name
      description
      orderNumber
      orderDate
      derivedStatus
      workOrderType {
        name
      }
    }
  }
`;
const Search: FC = () => {
  const closeSidebarTab = useSettingsStore((state) => state.closeSidebarTab);
  const latestOrderId = useSettingsStore((state) => state.latestOrderId);

  const [searchValue, setSearchFilter] = useState("");
  const [finalSearchValue, setFinalSearchValue] = useState("");

  useEffect(() => {
    if (searchValue) {
      // Determine the length of the prefix by subtracting the length of the search value from the latest order ID length
      const prefixLength = latestOrderId.length - searchValue.length;
      const prefix = latestOrderId.substring(0, prefixLength);
      const completedValue = prefix + searchValue;
      setFinalSearchValue(completedValue);
    } else {
      setFinalSearchValue("");
    }
  }, [searchValue, latestOrderId]);

  const { data: workOrders } = useQuery<
    { workOrderById: WorkOrder } | undefined
  >(GET_WORK_ORDER_BY_ID, {
    variables: {
      workOrderId: finalSearchValue,
    },
    skip: !searchValue,
  });

  const getHighlightedText = (text: string, highlight: string) => {
    if (!highlight) return text;
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts.map((part, i) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <strong key={i}>{part}</strong>
          ) : (
            part
          ),
        )}
      </span>
    );
  };

  const handleSearch = (value: string) => {
    setSearchFilter(value);
  };
  const workOrder = workOrders?.workOrderById;
  return (
    <div className={styles.searchWrapper}>
      <nav>
        <button type="button" onClick={() => closeSidebarTab()}>
          <img src={arrow} alt="arrow" />
          SEARCH
        </button>
      </nav>
      <SearchFilters handleSearch={handleSearch} />
      {!searchValue && (
        <p className={textStyles.secondaryText}>
          Search by the full order number, or the last few digits.
        </p>
      )}

      {finalSearchValue ? (
        <p className={textStyles.secondaryText}>
          Searching for {getHighlightedText(finalSearchValue, searchValue)}
        </p>
      ) : (
        ""
      )}

      {/* eslint-disable-next-line no-nested-ternary */}
      {workOrder ? (
        <FilteredList orders={workOrder} />
      ) : finalSearchValue ? (
        <p className={textStyles.secondaryText}>No results</p>
      ) : null}
    </div>
  );
};
export default memo(Search);
