import React from "react";
import { useMsal } from "@azure/msal-react";

import buttons from "@:lite/style/buttons.module.scss";

const SignIn: React.FC = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginPopup().catch((error) => {
      console.error(error);
    });
  };

  return (
    <div className="login-block">
      <img
        alt="NSG welcome"
        width="400px"
        style={{ marginTop: "-10%" }}
        src="/port-bg.png"
      />
      <br />
      <div>Planning & Collaboration Board</div>
      <br />
      <button
        className={buttons.mainButton}
        type="button"
        onClick={handleLogin}
        style={{ border: "1px solid #00ADBB" }}
      >
        Log In
      </button>
    </div>
  );
};

export default SignIn;
