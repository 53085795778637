import { gql } from "@apollo/client";

export const GET_VESSELS_QUERY = gql`
  query getVesselWorkOrders(
    $facilityId: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    facility(facilityId: $facilityId) {
      vesselWorkOrders(startDate: $startDate, stopDate: $endDate) {
        id
        stopDate
        startDate
        plannedStartDate
        plannedStopDate
        rentalAgreementNumber
        rentalAgreementLine
        vesselImo
        vesselName
        customerId
        customerName
        quay
        poNumber
        shorePowerUsage {
          # aPM
          # connectionStartTime
          consumedKWH
          # powerUnit
        }
        orderedBy
        status
        __typename
        vessel {
          id
          name
          imoNumber
        }
        attachments {
          id
          filename
        }
        workOrders {
          customerDisplayName
          id
          name
          customerName
          description
          name
          orderBy
          poNumber
          orderedBy
          orderNumber
          plannedStartDate
          plannedStopDate
          startDate
          status
          derivedStatus
          stopDate
          mCO {
            referenceOrderLine
            referenceOrderNumber
          }
          workOrderType {
            id
          }
          loadingStatusSummary {
            planId
            planType
            regularLoad {
              canNotProcessQty
              quaySideQty
              roundTripQty
              vesselQty
              warehouseQty
            }
            pipeLoad {
              canNotProcessQty
              quaySideQty
              roundTripQty
              vesselQty
              warehouseQty
            }
          }
          attachments {
            filename
            id
          }
          workOrderNotes {
            specifyDescription
          }
        }
      }
    }
  }
`;
