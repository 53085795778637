/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from "react";
import { gql, useSubscription } from "@apollo/client";
import isEqual from "lodash/isEqual";

import { useSettingsStore, useUserData } from "@:lite/zustand/useSettingsStore";

import NotificationResolver from "./NotificationFromBackendResolver";
import usePrevious from "./usePrevious";

type NotificationType = {
  message: string;
  messageType: string;
  notificationType: string;
  payload: string;
  payloadType: string;
  recipient: string;
  scope: string;
  __typename: string;
};

const NOTIFICATION_QUERY = gql`
  subscription OnNotificationFromBackend(
    $facilityId: String!
    $userEmail: String!
  ) {
    notificationFromBackend(
      where: {
        or: [
          { scope: { eq: EVERYONE } }
          {
            and: [
              { scope: { eq: FACILITY } }
              { recipient: { eq: $facilityId } }
            ]
          }
          { and: [{ scope: { eq: USER } }, { recipient: { eq: $userEmail } }] }
        ]
      }
    ) {
      notificationType
      scope
      recipient
      message
      messageType
      payload
      payloadType
    }
  }
`;

const useNotificationFromBackend = (): NotificationType | undefined => {
  const selectedFacility = useSettingsStore((state) => state.selectedFacility);
  const [subscriptionState, setSubscriptionState] = useState({
    data: undefined,
    loading: true,
    cacheBusting: "",
  });
  const userData = useUserData();

  const subscriptionVariables = useMemo(
    () => ({
      facilityId: selectedFacility,
      userEmail: userData?.mail,
    }),
    [selectedFacility, userData?.mail],
  );
  const prevSubscriptionVariables = usePrevious(subscriptionVariables);
  // We have previously suggested a custom Hook called usePrevious to hold the previous value.
  // However, we’ve found that most use cases fall into the two patterns described above.
  // If your use case is different, you can hold a value in a ref and manually update it when needed.
  // Avoid reading and updating refs during rendering because this makes your component’s behavior difficult to predict and understand.

  useEffect(() => {
    if (!isEqual(subscriptionVariables, prevSubscriptionVariables)) {
      setSubscriptionState({
        data: undefined,
        loading: true,
        cacheBusting: "",
      });
    }
  }, [subscriptionVariables, prevSubscriptionVariables]);

  useSubscription(NOTIFICATION_QUERY, {
    variables: subscriptionVariables,
    // Don't use useSubscription directly, instead we use onSubscriptionData (EDIT: deprecated. use "onData")
    // Bug upstream: https://github.com/apollographql/apollo-client/issues/7198
    onData: ({ data }) => {
      setSubscriptionState({
        data: data.data,
        loading: false,
        cacheBusting: new Date().toISOString(),
      });
    },
  });
  const finalData = subscriptionState?.data?.notificationFromBackend;

  return useMemo(() => {
    if (finalData) {
      if (window?.DEBUG_WS) {
        console.debug(
          "%c[WS - BACKEND] ",
          "background: #F3F3F9",
          finalData?.message,
          finalData,
        );
      }
      // [Hackish fix] to make sure useEffect is executed (Cache busting). For those cases when notification is the same value, but still the consumer wants to evaluate it.
      // Debouncing,etc mitigates the possible performance downgrade.
      finalData.cacheBusting = new Date().toISOString();

      NotificationResolver.resolve(finalData);
    }
    return finalData;
  }, [finalData]);
};

export default useNotificationFromBackend;
