/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import isEmpty from "lodash/isEmpty";
import moment from "moment-timezone";
import { create } from "zustand";
import { devtools, subscribeWithSelector } from "zustand/middleware";

import { captureError, captureMessage } from "@:lite/helpers/captureError";
import { STATIC_TIMELINE_ROWS } from "@:lite/helpers/constants";
import { Quay } from "@:lite/types";
import { normalizeKey } from "@:lite/utils/match";

import { intervalGraphColoring } from "./intervalGraphColoring";
import { DELETE_VESSEL_MUTATION } from "./mutations/deleteVessel";
import {
  UPDATE_VESSEL_PO_NUMBER,
  UPDATE_WORKORDER_PO_NUMBER,
} from "./mutations/updatePONumber";
// import { TRIGGER_NOTIF } from "./mutations/testTriggerNotification";
// import allMockOrders from "./mockOrders";
import { UPDATE_VESSEL_MUTATION } from "./mutations/updateVessel";
import { UPDATE_WO_MUTATION } from "./mutations/updateWorkOrder";
import { GET_QUAYS_QUERY } from "./queries/getQuays";
import { GET_VESSELS_QUERY } from "./queries/getVessels";
import { GET_WOS_QUERY } from "./queries/getWorkOrders";
import {
  removeIdFromPoInPogressList,
  removeIdFromVesselInPogressList,
  removeIdFromWoInPogressList,
  useInProgressStore,
} from "./useInProgressStore";
import {
  setAllRows,
  setVisibleRows,
  useSettingsStore,
} from "./useSettingsStore";

export const DEFAULT_ALLOCATION_HEIGHT = 68;

// Useful to know:
// * raw__ and __ById contains all the info to display data in the timeline.  (part of it lazy loaded. See extraVessels, extraWOs)
// * sidePanelOrder has all the info to display data in the sidePanel (part of it lazy loaded for vessels),
//   except Notes and Attachments, those are handled in their own components, not centralized here.
// * mutate___ actions are optimistic. It will send the mutation, and merge it into 1) raw, 2) byId and 3) sidepanelOrder
// * merge___ actions are shallow. (with a couple of exceptions, see comments)
type State = {
  allOrders: any[];
  visibleRows: any[];
  arrayToRender: any;

  quays: Quay[];

  sidePanelOrder: any;
};

const OrderTypes = {
  Vessel: "vessel",
  WorkOrder: "wo",
  SubWorkOrder: "subwo",
} as const;

// Convert it to a type
// It can be used both ways: foo("vessel") or foo(OrderTypesKeys.Vessel)
type OrderTypesKeys = (typeof OrderTypes)[keyof typeof OrderTypes];

// When stored inside the useOrderStore, it loses object properties, like .query()
// That's why is kept here:
let _apolloClient;

export const useOrderStore = create<State>()(
  // log(
  devtools(
    // Redux devtools broken. Follow up: https://github.com/pmndrs/zustand/issues/705
    subscribeWithSelector(() => ({
      allOrders: [],
      visibleRows: [],
      arrayToRender: [],

      quays: [],

      // clicked allocation => open order details in the sidepanel
      sidePanelOrder: null,
    })),
    { name: "orderStore" },
  ),
  // )
);

// ====================
//
//      ACTIONS
//
// ====================

const hasMatchingStatus = (order, selectedStatus): boolean => {
  const allowedLabels = selectedStatus?.map((el) => el.label);
  return (
    isEmpty(allowedLabels) ||
    allowedLabels?.includes(order?.status) ||
    allowedLabels?.includes(order?.derivedStatus)
  );
};

// To be called just pre-merge, or pre-setting the sidePanelOrder.
const normalizeOrderAttributes = (updatedOrder, typeKey) => {
  const { allCollapsed } = useSettingsStore.getState();
  if (!updatedOrder?.normalizedOrderType) {
    updatedOrder.normalizedOrderType = typeKey;
  }
  if (typeof updatedOrder.allocationCollapsed === "undefined") {
    updatedOrder.allocationCollapsed = allCollapsed;
  }
  if (
    (updatedOrder?.quay || updatedOrder?.reserve) &&
    !updatedOrder?.normalizedQuay
  ) {
    if (typeKey === "vessel") {
      updatedOrder.normalizedQuay = normalizeKey(updatedOrder?.quay);
    } else if (typeKey === "wo") {
      updatedOrder.normalizedQuay = normalizeKey(
        updatedOrder?.workOrderType?.id,
      );
    }
  }
};

// Don't export. It's internal, very custom thing.
function mergeSubWorkOrders(oldOrders = [], newOrders = {}) {
  // Map over old orders and merge with new ones if they exist
  return oldOrders.map((oldOrder) => ({
    ...oldOrder,
    ...(newOrders[oldOrder.id] || {}),
  }));
}

// Shallow* merge into
// - raw Collection
// - __ByID collection
// - sidePanelOrder
//
//             *except for .workOrders (where it will make a shallow merge for this attribute)
export const mergeOrderIntoCollections = (
  typeKey: OrderTypesKeys,
  updatedOrder,
) => {
  if (!updatedOrder?.id) {
    return;
  }

  normalizeOrderAttributes(updatedOrder, typeKey);
  if (typeKey === "subwo") {
    useOrderStore.setState((draftState) => {
      const { id } = updatedOrder;
      const { allOrders } = draftState;

      const theIndex = allOrders?.findIndex((order) => order.id === id);
      if (theIndex) {
        const previously = allOrders?.[theIndex];
        const updated = {
          ...previously,
          ...updatedOrder,
        };
        const normalizedOrder = normalizeSubWos([updated]);

        // Decide if pushing to the array, or update existing.
        if (theIndex !== -1) {
          allOrders[theIndex] = normalizedOrder?.[0];
        } else {
          allOrders.push(normalizedOrder?.[0]);
          captureMessage(
            `this id wasn't found in the store "allOrders" with updatedOrder id ${id}`,
          );
        }
        return {
          allOrders,
        };
      }
      captureMessage(
        `this vessel id wasn't found in allOrders with updatedOrder id ${id}`,
      );
      return {};
    });
  } else {
    useOrderStore.setState((draftState) => {
      const { allOrders } = draftState;
      const { id } = updatedOrder;

      const theIndex = allOrders?.findIndex((order) => order.id === id);
      if (theIndex) {
        const previously = allOrders?.[theIndex];
        const updated = updatedOrder?.workOrders
          ? {
              ...previously,
              ...updatedOrder,
              workOrders: normalizeSubWos(
                mergeSubWorkOrders(
                  previously?.workOrders,
                  updatedOrder.workOrders,
                ),
                undefined,
                updatedOrder?.quay,
              ),
            }
          : {
              ...previously,
              ...updatedOrder,
            };

        // Decide if pushing to the array, or update existing.
        if (theIndex !== -1) {
          allOrders[theIndex] = updated;
        } else {
          allOrders.push(updated);
          captureMessage(
            `this id wasn't found in the store "allOrders" with updatedOrder id ${id}`,
          );
        }
        return {
          allOrders,
        };
      }
      captureMessage(
        `this wo id wasn't found in allOrders with updatedOrder id ${id}`,
      );
      return {};
    });
  }
  const sidePanelOrderId = useOrderStore.getState().sidePanelOrder?.id;
  if (sidePanelOrderId === updatedOrder?.id) {
    setSidePanelOrder(updatedOrder, true);
  }
  stackOrders();
};

export const mutateVesselPONumber = (params: any) => {
  const { selectedFacility, userData } = useSettingsStore.getState();
  const { mail } = userData;
  const { id, poNumber, rentalAgreementNumber, rentalAgreementLine } = params;

  const tweakedVessel = {
    id,
    poNumber,
  };

  // Internal orderStore, for an optimistic update.
  mergeOrderIntoCollections("vessel", tweakedVessel);

  useInProgressStore.setState(({ poChangeInProgress }) => {
    return {
      poChangeInProgress: [...poChangeInProgress, { id, created: Date.now() }],
    };
  });
  // Remove it after 30 seconds (timeout)
  setTimeout(() => {
    removeIdFromPoInPogressList(id);
  }, 30000);

  const variables = {
    facilityId: selectedFacility,
    issuedBy: mail,
    purchaseOrderId: poNumber,
    rentalAgreementId: rentalAgreementNumber,
    rentalAgreementLineId: rentalAgreementLine,
  };
  _apolloClient
    .mutate({
      mutation: UPDATE_VESSEL_PO_NUMBER,
      variables,
    })
    .then((apolloData: any) => {
      // TODO : Catch a "false" and revert optimistic update?
      // const { data, loading } = apolloData;
    });
};

export const mutateWorkOrderPONumber = (params: any, isSubWO = false) => {
  const { selectedFacility, userData } = useSettingsStore.getState();
  const { mail } = userData;
  const { id, poNumber, orderNumber, orderLineNumber } = params;
  const tweakedWO = {
    id,
    poNumber,
  };

  // Internal orderStore, for an optimistic update.
  if (isSubWO) {
    mergeOrderIntoCollections("subwo", tweakedWO);
  } else {
    mergeOrderIntoCollections("wo", tweakedWO);
  }

  useInProgressStore.setState(({ poChangeInProgress }) => {
    return {
      poChangeInProgress: [...poChangeInProgress, { id, created: Date.now() }],
    };
  });
  // Remove it after 30 seconds (timeout)
  setTimeout(() => {
    removeIdFromPoInPogressList(id);
  }, 30000);

  const variables = {
    facilityId: selectedFacility,
    issuedBy: mail,
    purchaseOrderId: poNumber,
    orderNumber,
    orderLineNumber,
  };
  _apolloClient
    .mutate({
      mutation: UPDATE_WORKORDER_PO_NUMBER,
      variables,
    })
    .then((apolloData: any) => {
      refreshTimeline();
      // TODO : Catch a "false" and revert optimistic update?
      // const { data, loading } = apolloData;
    });
};

export const mutateVessel = (params: any) => {
  const { selectedFacility, userData } = useSettingsStore.getState();
  const { mail } = userData;
  const { id, plannedStartDate, plannedStopDate, workOrders } = params;

  useInProgressStore.setState(({ vesselChangeInProgress }) => {
    return {
      vesselChangeInProgress: [
        ...vesselChangeInProgress,
        { id, created: Date.now() },
      ],
    };
  });
  // Remove it after 30 seconds (timeout)
  setTimeout(() => {
    removeIdFromVesselInPogressList(id);
  }, 30000);

  const startDate = moment(plannedStartDate).set({ second: 0 }).toISOString();
  const stopDate = moment(plannedStopDate).set({ second: 0 }).toISOString();

  const tweakedVessel = {
    id,
    plannedStartDate: startDate,
    plannedStopDate: stopDate,
    workOrders,
  };

  // Internal orderStore, for an optimistic update.
  mergeOrderIntoCollections("vessel", tweakedVessel);
  // Last normalization step
  const variables = {
    vesselId: id,
    startDate,
    stopDate,
    facilityId: selectedFacility,
    issuedBy: mail,
  };

  // Optimistic update
  _apolloClient
    .mutate({
      mutation: UPDATE_VESSEL_MUTATION,
      variables, // Notice that variables that are not part of GraphQL schema swill be just ignored
    })
    .then((apolloData: any) => {
      // TODO : Catch a "false" and revert optimistic update?
      const { data, loading } = apolloData;
    });
};

export const mutateWorkOrder = (params: any, isSubWO = false) => {
  const { selectedFacility, userData } = useSettingsStore.getState();
  const { mail } = userData;
  const { id, plannedStartDate, plannedStopDate } = params;

  useInProgressStore.setState(({ woChangeInProgress }) => {
    return {
      woChangeInProgress: [...woChangeInProgress, { id, created: Date.now() }],
    };
  });
  // Remove it after 30 seconds (timeout)
  setTimeout(() => {
    removeIdFromWoInPogressList(id);
  }, 30000);

  const startDate = moment(plannedStartDate).set({ second: 0 }).toISOString();
  const stopDate = moment(plannedStopDate).set({ second: 0 }).toISOString();

  const tweakedWO = {
    id,
    plannedStartDate: startDate,
    plannedStopDate: stopDate,
  };

  // const isSubWO = getSubWOById(id);
  // Merge into the internal orderStore, for an optimistic update.
  // We want to avoid merge when we are editin a Vessel's work order (those don't belong to WO collection)
  if (isSubWO) {
    mergeOrderIntoCollections("subwo", tweakedWO);
  } else {
    mergeOrderIntoCollections("wo", tweakedWO);
  }
  // Last normalization step
  const variables = {
    workOrderId: id,
    startDate,
    stopDate,
    facilityId: selectedFacility,
    issuedBy: mail,
  };
  _apolloClient
    .mutate({
      mutation: UPDATE_WO_MUTATION,
      variables,
    })
    .then((apolloData: any) => {
      // TODO : Catch a "false" and revert optimistic update?
      const { data, loading } = apolloData;
    });
};

// Convert an array [ obj, obj, obj ] to a map { id: obj, id: obj, id: obj }
// It doesn't mutate the original
export const createMapById = (originalArray) => {
  const mapById = originalArray.reduce((obj, item) => {
    if (!item?.id) {
      return obj;
    }
    if (obj[item.id]) {
      captureMessage(`Duplicate id found: ${item.id}`);
    } else if (item.id) {
      obj[item.id] = item;
    } else {
      console.error("error in createMapById");
    }
    return obj;
  }, {});
  return mapById;
};

// Convert an array [ obj, obj, obj ] to
// [
//   {orders: [order1, order2], row: "108", rowHeight: 320},
//   {orders: [order1, order2], row: "kay2", rowHeight: 320}
//  ]
// This way, we trust the array for the order of the rows.
const createArrayToRender = (allOrders) => {
  const { selectedStatus, visibleDateRange } = useSettingsStore.getState();
  const { visibleStartDate, visibleEndDate } = visibleDateRange;
  let result = [];
  // A map to track the aggregates by row
  const aggregates = {};

  // We don't want subWOs in the arrayToRender, it's easier if we render them directly from vessel.workOrders data.
  const mainOrders = allOrders.filter(
    (item) => item.normalizedOrderType !== "subwo",
  );
  // eslint-disable-next-line no-restricted-syntax
  for (const order of mainOrders) {
    let finalStartDate = order?.plannedStartDate;
    if (
      order?.normalizedOrderType !== "wo" &&
      order?.normalizedOrderType !== "subwo" // TODO: It's already filtered, so there is no subwo in this array. why again filtering?
    ) {
      finalStartDate = order?.startDate
        ? order?.startDate
        : order.plannedStartDate;
    }
    let finalStopDate = order?.plannedStopDate;
    if (
      order?.normalizedOrderType !== "wo" &&
      order?.normalizedOrderType !== "subwo"
    ) {
      finalStopDate = order?.stopDate ? order?.stopDate : order.plannedStopDate;
    }
    const isInsideTheRange =
      moment(finalStartDate).isBefore(visibleEndDate) &&
      moment(finalStopDate).isSameOrAfter(visibleStartDate);
    const matchesVisibleStatus = hasMatchingStatus(order, selectedStatus);
    if (order?.normalizedOrderType === "subwo") {
      break;
    }
    if (
      !aggregates[order.normalizedQuay] &&
      matchesVisibleStatus &&
      isInsideTheRange
    ) {
      aggregates[order.normalizedQuay] = {
        orders: [],
        row: order.normalizedQuay,
        rowHeight: DEFAULT_ALLOCATION_HEIGHT,
      };
    }

    if (matchesVisibleStatus && isInsideTheRange) {
      // Filter out attached wos that are not going to be visible.
      if (order?.workOrders && order.workOrders.length > 0) {
        order.workOrders = order.workOrders
          .map((wo) => {
            // Why is this needed?
            const mostUpToDate = allOrders?.find((o) => o.id === wo.id);
            return mostUpToDate?.id ? { ...mostUpToDate } : wo;
          })
          .map((wo) => {
            // We can't just remove them, because when clicking an allocation, we want to show all attached work orders, not only visible ones
            wo.hideInTimeline = !(
              moment(wo.plannedStartDate).isSameOrBefore(visibleEndDate) &&
              moment(wo.plannedStopDate).isSameOrAfter(visibleStartDate)
            );
            return wo;
          });
      }
      aggregates[order.normalizedQuay].orders.push(order);
    }
  }

  // Convert the map values to an array using Object.values
  result = Object.values(aggregates);
  return result;
};

const mergeVesselOrders = (vessels) => {
  const { userData } = useSettingsStore.getState();
  const { userCompany } = userData;
  const { allOrders } = useOrderStore.getState();
  if (!Array.isArray(vessels) || vessels.length === 0) {
    return [];
  }
  // vessels is frozen object (because Apollo), let's clone it:
  const vesselsClone = JSON.parse(JSON.stringify(vessels));

  const sidePanelOrderId = useOrderStore.getState().sidePanelOrder?.id;
  let allNewSubWOs = [];
  vesselsClone.forEach((obj1, index) => {
    if (obj1) {
      obj1.normalizedQuay = normalizeKey(obj1?.quay);
      obj1.normalizedOrderType = "vessel";

      // Preserve the previous "allocationCollapsed" vessel status
      const theIndex = allOrders?.findIndex((order) => order?.id === obj1?.id);
      if (theIndex >= 0) {
        obj1.allocationCollapsed = allOrders?.[theIndex]?.allocationCollapsed;
      }

      obj1.userHasAccessToVesselSubWOs = obj1?.workOrders?.some(
        (subWo) => subWo?.customerName?.toString() === userCompany.toString(),
      );

      if (obj1?.workOrders && obj1.userHasAccessToVesselSubWOs) {
        obj1.workOrders = normalizeSubWos(
          obj1.workOrders,
          undefined,
          obj1.quay,
        );
        obj1.workOrders.forEach((wo) => {
          // Check if the updated order is the currently selected order
          if (sidePanelOrderId === wo?.id) {
            setSidePanelOrder(wo, true);
          }
        });
        allNewSubWOs = [...allNewSubWOs, ...(obj1.workOrders || [])];
      } else {
        // Meaning: if user doesn't have access, make sure this attribute is deleted
        delete obj1.workOrders;
      }
      vesselsClone[index] = obj1;

      // Check if the updated order is the currently selected order
      if (sidePanelOrderId === obj1?.id) {
        setSidePanelOrder(obj1, true);
      }
    } else {
      captureMessage(`Null or undefined vessel object inside array : ${obj1}`);
    }
  });

  return vesselsClone;
};

const normalizeSubWos = (wos, extra = undefined, toQuay = undefined) => {
  if (!Array.isArray(wos) || wos.length === 0) {
    return [];
  }
  wos.forEach((wo) => {
    delete wo.theRef;
  });
  const sidePanelOrderId = useOrderStore.getState().sidePanelOrder?.id;
  const wosClone = JSON.parse(JSON.stringify(wos)).filter((wo) => !!wo?.id);
  wosClone.forEach((wo) => {
    if (wo) {
      // Fake the quay, to set it up to its parent's Vessel quay. That way we solve the drag scope.
      wo.normalizedQuay = normalizeKey(toQuay || wo?.workOrderType?.id);
      wo.normalizedOrderType = "subwo";
      const regLoad = wo?.loadingStatusSummary?.regularLoad;
      const pipeLoad = wo?.loadingStatusSummary?.pipeLoad;
      wo.normalizedTotalPipeLoad =
        (pipeLoad?.canNotProcessQty ?? 0) +
        (pipeLoad?.quaySideQty ?? 0) +
        (pipeLoad?.roundTripQty ?? 0) +
        (pipeLoad?.vesselQty ?? 0) +
        (pipeLoad?.warehouseQty ?? 0);
      wo.normalizedTotalRegularLoad =
        (regLoad?.canNotProcessQty ?? 0) +
        (regLoad?.quaySideQty ?? 0) +
        (regLoad?.roundTripQty ?? 0) +
        (regLoad?.vesselQty ?? 0) +
        (regLoad?.warehouseQty ?? 0);

      // Check if the updated order is the currently selected order
      if (sidePanelOrderId === wo?.id) {
        setSidePanelOrder(wo, true);
      }
    }
  });

  return wosClone;
};

const mergeWOs = (wos, extra = undefined) => {
  if (!Array.isArray(wos) || wos.length === 0) {
    return [];
  }
  const wosClone = JSON.parse(JSON.stringify(wos));

  const sidePanelOrderId = useOrderStore.getState().sidePanelOrder?.id;
  wosClone.forEach((obj1, index) => {
    if (obj1) {
      // Fake the quay, to set it up to its parent's Vessel quay. That way we solve the drag scope.
      obj1.normalizedQuay = normalizeKey(obj1?.workOrderType?.id);
      obj1.normalizedOrderType = "wo";
      wosClone[index] = obj1;
      // Check if the updated order is the currently selected order
      if (sidePanelOrderId === obj1?.id) {
        setSidePanelOrder(obj1, true);
      }
    } else {
      captureMessage(`Null or undefined wo object inside array : ${obj1}`);
    }
  });

  return wosClone;
};

const fetchVessels = (facilityId, startDate, endDate) => {
  const variables = {
    facilityId,
    startDate,
    endDate,
  };
  return _apolloClient
    .query({
      query: GET_VESSELS_QUERY,
      variables,
    })
    .then((apolloData: any) => {
      const { data } = apolloData;
      const vesselWorkOrders = data?.facility?.vesselWorkOrders || [];
      return mergeVesselOrders(vesselWorkOrders);
      // return _apolloClient
      //   .query({
      //     query: GET_VESSELS_EXTRA_QUERY,
      //     variables,
      //   })
      //   .then((apolloData2: any) => {
      //     const { data: extra } = apolloData2;
      //     return mergeVesselOrders(previousVessels, extra);
      //   });
    });
};

const fetchWOs = (facilityId, startDate, endDate) => {
  const variables = {
    facilityId,
    startDate,
    endDate,
  };
  return _apolloClient
    .query({
      query: GET_WOS_QUERY,
      variables,
    })
    .then((apolloData: any) => {
      const { data } = apolloData;
      const workOrders = data?.facility?.workOrders || [];
      const { setLatestOrderId } = useSettingsStore.getState();

      if (workOrders?.[0]?.id?.length === 10) {
        setLatestOrderId(workOrders?.[0].id);
      }

      return mergeWOs(workOrders);
      // return _apolloClient
      //   .query({
      //     query: GET_WOS_EXTRA_QUERY,
      //     variables,
      //   })
      //   .then((apolloData2: any) => {
      //     const { data: extra } = apolloData2;
      //     return mergeWOs(previousWOs, extra);
      //   });
    });
};

const DEBUG_MODE = false;
function calculateTimelineStyles(
  arrayToRender: any[],
  visibleRows: any[],
  inDetailedView: boolean,
): any {
  let aggregatedPreviousRowsBottom = 0; // it will become the top of the next row

  visibleRows.map((eachRow) => {
    const thisRow = arrayToRender.find((item) => item.row === eachRow.id);
    if (!thisRow?.orders || !Array.isArray(thisRow.orders)) {
      aggregatedPreviousRowsBottom += DEFAULT_ALLOCATION_HEIGHT;
    } else {
      const thisRowOrders = thisRow?.orders;
      if (DEBUG_MODE) {
        console.debug("--------------  ", thisRow.row, "  -------");
      }

      for (let i = 0; i < thisRowOrders.length; i += 1) {
        const thisOrder = thisRowOrders[i];
        const visibleAttachedWorkOrders = thisOrder?.workOrders;

        if (thisOrder) {
          thisOrder.visualTop =
            (aggregatedPreviousRowsBottom || 0) + (thisOrder.yPositionPx || 0);

          thisOrder.groupTop = 0;

          if (
            !thisOrder?.allocationCollapsed &&
            visibleAttachedWorkOrders?.length > 0
          ) {
            // Nested loop (vessel's attached WOs)
            // let highestSubVerticalPosition = 0;
            // let progressiveSubWosHeight = 0;
            for (let k = 0; k < visibleAttachedWorkOrders.length; k += 1) {
              const thisAttachedWO = visibleAttachedWorkOrders[k];
              thisAttachedWO.visualTop =
                thisOrder.visualTop +
                DEFAULT_ALLOCATION_HEIGHT / 2 +
                thisAttachedWO.yPositionPx;

              if (k === 0) {
                thisOrder.groupTop =
                  thisOrder.visualTop + DEFAULT_ALLOCATION_HEIGHT / 2;
              }
            }
          }
        }
      }
      // Add up all those level's heights.
      aggregatedPreviousRowsBottom += thisRow.rowHeight;
    }

    return thisRow;
  });
}

const renormalizeVessels = () => {
  const { allOrders } = useOrderStore.getState();
  const { allCollapsed } = useSettingsStore.getState();
  const reAllOrders = allOrders.map((order) => {
    if (order.normalizedOrderType === "vessel") {
      order.allocationCollapsed = allCollapsed;
    }
    return order;
  });
  useOrderStore.setState({
    allOrders: reAllOrders,
  });
};

const setAllOrders = (newOrders: any[]) => {
  let newSubWOs = [];
  const newVessels = (newOrders?.[0] ?? [])
    .filter((order) => !!order?.id)
    .map((nonExtensible) => {
      const order = { ...nonExtensible };
      normalizeOrderAttributes(order, "vessel");
      if (order?.workOrders) {
        order.workOrders = normalizeSubWos(
          order.workOrders,
          undefined,
          order.quay,
        );

        newSubWOs = [...newSubWOs, ...order.workOrders];
      }
      return order;
    });

  // Regular work orders
  const newWOs = (newOrders?.[1] ?? [])
    .filter((order) => !!order?.id)
    .filter((order) => {
      const shouldNotHappen = newSubWOs.find((obj) => obj.id === order.id);
      if (shouldNotHappen) {
        console.error(
          "we shouldn't have a sub wo id, also in the Work Orders array",
        );
        return false;
      }
      return true;
    })
    .map((nonExtensible) => {
      // Clone orders to remove the Apollo "non extensible object" protection
      const order = { ...nonExtensible };
      normalizeOrderAttributes(order, "wo");
      return order;
    });

  useOrderStore.setState({
    allOrders: [...newVessels, ...newWOs, ...newSubWOs],
  });
};

function calculateEarliestPlannedStart(
  orders: any[],
  vesselPlannedStart,
): string {
  if (!orders?.length) {
    return vesselPlannedStart;
  }
  // We need to calculate vessel's witdh considerig the minimum (earliest) plannedStart of any of its attached WOS, and same for the (latest) plannedStop
  const minSubWoPlannedStart = orders.reduce((earliest, task) => {
    const currentStart = new Date(task.plannedStartDate);
    return currentStart < new Date(earliest) ? task.plannedStartDate : earliest;
  }, orders[0].plannedStartDate);

  return new Date(minSubWoPlannedStart).getTime() >
    new Date(vesselPlannedStart).getTime()
    ? vesselPlannedStart
    : minSubWoPlannedStart;
}

function calculateLatestPlannedStop(orders: any[], vesselPlannedStop): string {
  if (!orders?.length) {
    return vesselPlannedStop;
  }
  const maxSubWoPlannedStop = orders.reduce((latest, task) => {
    const currentStop = new Date(task.plannedStopDate);
    return currentStop > new Date(latest) ? task.plannedStopDate : latest;
  }, orders[0].plannedStopDate);

  return new Date(maxSubWoPlannedStop).getTime() >
    new Date(vesselPlannedStop).getTime()
    ? maxSubWoPlannedStop
    : vesselPlannedStop;
}

const stackOrders = () => {
  const { allOrders } = useOrderStore.getState();
  const { visibleRows } = useSettingsStore.getState();
  const { inDetailedView } = useSettingsStore.getState();
  const { visibleDateRange } = useSettingsStore.getState();

  const { visibleStartDate, visibleEndDate } = visibleDateRange;
  const arrayToRender = createArrayToRender(allOrders);
  /* 

  arrayToRender only holds Vessels, WOs and Ghost. No SubWos.

  1st challenge: To be able to stack Vessel allocations we need to know the dimensions ..

  1 calculate height of each subWo (if Konciv mode enabled)
  2 Run the stacking algo for subWorkOrders, so I can know the height of the vessel allocation.
  3 Run the stacking algo for all Vessel/regularWO/Ghosts

*/

  // Calculate and add ".allocHeight" that depends on Konciv data displayed.
  // We don't know the group height because at this point we still don't know the stacking values,
  visibleRows.map((eachRow: any) => {
    const thisRow = arrayToRender.find((item) => item.row === eachRow.id);
    if (thisRow?.orders) {
      let anyOrderHasSubWOs = false;
      // LOOP
      // ------------
      for (let i = 0; i < thisRow.orders.length; i += 1) {
        const thisOrder = thisRow.orders?.[i];
        if (thisOrder?.workOrders && thisOrder.workOrders.length > 0) {
          anyOrderHasSubWOs = true;
          break; // it breaks only the for loop.
        }
      }
      // LOOP
      // ------------
      for (let i = 0; i < thisRow.orders.length; i += 1) {
        const thisOrder = thisRow.orders?.[i];

        // If it's a vessel with Sub WOs, then:
        if (thisOrder?.workOrders && thisOrder.workOrders.length > 0) {
          for (let k = 0; k < thisOrder.workOrders?.length; k += 1) {
            const thisSubWO = thisOrder.workOrders[k];
            if (thisSubWO) {
              // This way, the sort of intervalGraphColoring will work for all allocation types.
              thisSubWO.earliestPlannedStart = thisSubWO.plannedStartDate;
              thisSubWO.latestPlannedStop = thisSubWO.plannedStopDate;

              // --START
              thisSubWO.visibleStart = moment(
                thisSubWO.plannedStartDate,
              ).isSameOrBefore(visibleStartDate)
                ? visibleStartDate.toISOString()
                : thisSubWO.plannedStartDate;

              thisSubWO.visibleStop = moment(
                thisSubWO.plannedStopDate,
              ).isSameOrAfter(visibleEndDate)
                ? visibleEndDate.toISOString()
                : thisSubWO.plannedStopDate;

              // if (thisSubWO?.vessel && thisSubWO?.adjustedStopDate) {
              //   thisSubWO.normalizedStopDate = moment(
              //     thisSubWO.adjustedStopDate
              //   ).isSameOrAfter(visibleEndDate)
              //     ? visibleEndDate.toISOString()
              //     : thisSubWO.adjustedStopDate;
              // }

              // ___ END

              thisSubWO.allocHeight = thisOrder.allocationCollapsed
                ? 0
                : DEFAULT_ALLOCATION_HEIGHT +
                  (inDetailedView
                    ? (thisSubWO.normalizedTotalRegularLoad > 0 ? 17 : 0) +
                      (thisSubWO.normalizedTotalPipeLoad > 0 ? 17 : 0)
                    : 0);
            }
          }

          // intervalGraphColoring mutates subWOs, and adds these attributes:
          // * yPosition   (relative to the top, starting from 0)
          // * yPositionPx  (y positions are discrete positions, like 0, 1, 2, 3. We multiply them by for example 34px, to get the real value)
          // * adjustedStopDate (is same than plannedStopDate, only changes for vessels, aka the parentOrder)
          if (thisOrder.workOrders && thisOrder.workOrders.length > 0) {
            thisOrder.groupHeight = intervalGraphColoring(
              thisOrder.workOrders,
              thisOrder,
              visibleStartDate,
              visibleEndDate,
            );
          }
        }

        const isVesselRow =
          Boolean(thisOrder?.quay) || Boolean(thisOrder?.reserve); // TODO: make it an util

        // Height of the vessel allocation itself, without the group of attached WOs
        thisOrder.innerAllocHeight = isVesselRow
          ? DEFAULT_ALLOCATION_HEIGHT / 2
          : DEFAULT_ALLOCATION_HEIGHT;

        // Notice. We don't know for sure if allocHeight should be full Height
        // until later when stack algo has been run and we get yPositions.
        // BUT We know that if 1 order has attached WOS, all the orders in the row, should be half-height
        // And for other cases, if we make a mistake it's easy to correct later in the calculation of styles.
        thisOrder.allocHeight = anyOrderHasSubWOs
          ? (thisOrder?.groupHeight ?? 0) + DEFAULT_ALLOCATION_HEIGHT / 2
          : thisOrder.innerAllocHeight;
      }
    }
    return eachRow;
  });
  // Calculate Vessel / Ghosts / Regular WO allocHeight for each:
  // Mutate arrayToRender adding stack index to each order:
  for (let i = 0; i < arrayToRender.length; i += 1) {
    if (arrayToRender[i]?.orders) {
      for (let j = 0; j < arrayToRender[i]?.orders.length; j += 1) {
        const thisOrder = arrayToRender[i].orders[j];
        thisOrder.earliestPlannedStart = calculateEarliestPlannedStart(
          thisOrder?.workOrders,
          thisOrder.plannedStartDate,
        );
        thisOrder.latestPlannedStop = calculateLatestPlannedStop(
          thisOrder?.workOrders,
          thisOrder.plannedStopDate,
        );

        // --START
        thisOrder.normalizedStartDate = moment(
          thisOrder.plannedStartDate,
        ).isSameOrBefore(visibleStartDate)
          ? visibleStartDate.toISOString()
          : thisOrder.plannedStartDate;

        thisOrder.normalizedStopDate = moment(
          thisOrder.plannedStopDate,
        ).isSameOrAfter(visibleEndDate)
          ? visibleEndDate.toISOString()
          : thisOrder.plannedStopDate;

        // if (thisOrder?.vessel && thisOrder?.adjustedStopDate) {
        //   thisOrder.normalizedStopDate = moment(
        //     thisOrder.adjustedStopDate
        //   ).isSameOrAfter(visibleEndDate)
        //     ? visibleEndDate.toISOString()
        //     : thisOrder.adjustedStopDate;
        // }

        // ___ END
      }

      // Mutates arrayToRender
      const rowHeight = intervalGraphColoring(
        arrayToRender[i].orders,
        {},
        visibleStartDate,
        visibleEndDate,
      );
      arrayToRender[i].rowHeight = Math.max(
        rowHeight,
        DEFAULT_ALLOCATION_HEIGHT,
      );
    }
  }

  const koncivView = true; // TODO
  calculateTimelineStyles(arrayToRender, visibleRows, koncivView);
  useOrderStore.setState({ arrayToRender });
};

export const deleteVessel = async (variables) => {
  return _apolloClient
    .mutate({
      mutation: DELETE_VESSEL_MUTATION,
      variables,
    })
    .then((apolloData: any) => {
      if (apolloData?.errors?.[0]?.message) {
        captureMessage(apolloData.errors[0].message);
      }
    });
};

export const toggleCollapsableVesselAllocation = (id) => {
  const { allOrders } = useOrderStore.getState();
  const thisVessel = allOrders.find((item) => item.id === id);
  if (thisVessel) {
    thisVessel.allocationCollapsed = !thisVessel.allocationCollapsed;
    mergeOrderIntoCollections("vessel", thisVessel);
  }
};

export const refreshTimeline = async () => {
  const { selectedFacility } = useSettingsStore.getState();
  if (!selectedFacility) {
    return;
  }
  const { setIsFetching } = useSettingsStore.getState();
  const { visibleDateRange } = useSettingsStore.getState();
  const { visibleStartDate, visibleEndDate } = visibleDateRange;
  setIsFetching(true);
  // setTimeout(() => {
  //   _apolloClient
  //     .mutate({
  //       mutation: TRIGGER_NOTIF,
  //       variables: {
  //         messageType: "RentalAgreementToQuayConnectedToShorePower",
  //         sender: "david@nordlys.studio",
  //         message: "RentalAgreementToQuaysUpdated",
  //         recipient: "Everyone",
  //         payload:
  //           '{"Id":"A102335:645:2039750","RentalAgreementNumber":"A102335","RentalOrderLine":"645","QuaySerialNumber":"230_KAI-3","PlannedFromDate":"2022-11-06T10:00:00Z","PlannedToDate":"2022-11-06T23:32:00Z","ActualFromDate":"2022-11-06T10:00:00Z","ActualToDate":null,"Key":"2039750","Status":300,"IsSynchronized":null,"ShorePowerUsage":{"PowerUnit":"Test","ConnectionStartTime":"2023-09-12T08:14:58.223272Z","APM":"Test","ConsumedKWH":"Test"}}',
  //       },
  //     })
  //     .then((apolloData: any) => {
  //       const { data, loading } = apolloData;
  //     });
  // }, 2000);
  // Parallel execution
  Promise.all([
    fetchVessels(selectedFacility, visibleStartDate, visibleEndDate),
    fetchWOs(selectedFacility, visibleStartDate, visibleEndDate),
  ])
    .then((results) => {
      setIsFetching(false);
      setAllOrders(results);
      stackOrders();
    })
    .catch((error) => {
      setIsFetching(false);
      stackOrders();
      captureError(error);
    });
};

// Prefer to use getOrderByIdAndType.
// This is to be used as last-resource. We don't know if we can rely on ids following different patterns
export const getOrderById = (id) => {
  const { allOrders } = useOrderStore.getState();

  return allOrders.find((a) => a.id === id);
};

export const closeSidePanel = () => {
  useOrderStore.setState({ sidePanelOrder: undefined });
};

export const setSidePanelOrder = (order, fullOrder = false) => {
  if (!order) {
    return;
  }
  const { id } = order;
  if (fullOrder) {
    useOrderStore.setState({ sidePanelOrder: order });
  } else {
    useOrderStore.setState({ sidePanelOrder: getOrderById(id) });
  }
};

// Doing this in this file to avoid "circular dependencies" between useSettingsStore and useOrderStore
const fetchQuays = (facilityId) => {
  return _apolloClient
    .query({
      query: GET_QUAYS_QUERY,
      variables: {
        facilityId,
      },
    })
    .then((apolloData: any) => {
      return apolloData?.data?.facility?.quays;
    });
};

const isRowVisible = (rowId: string) => {
  const { selectedOrderType, userData } = useSettingsStore.getState();
  const { userOrderTypes } = userData;

  const selectedOrderTypeValues = selectedOrderType?.map((el) =>
    el?.value ? el?.value?.toString() : "",
  );

  return (
    // "All deselected" means "all selected". That's why we check if isEmpty()
    (isEmpty(selectedOrderType) || selectedOrderTypeValues.includes(rowId)) &&
    // But in this case, it's important to check exactly empty array, that will allow access to all facilities. Not to confuse with undefined (when initializing the app). isEmpty(undefined) is true
    ((Array.isArray(userOrderTypes) && userOrderTypes.length === 0) ||
      userOrderTypes.includes(rowId))
  );
};

let isFetchingQuays = false;
const calculateAndSetVisibleRows = async () => {
  const { selectedFacility } = useSettingsStore.getState();
  const { quays } = useOrderStore.getState();
  let newQuays = quays ?? [];
  if (quays?.length === 0 && !isFetchingQuays) {
    if (selectedFacility) {
      isFetchingQuays = true;
      const rawQuays = (await fetchQuays(selectedFacility)) as Quay[];
      if (rawQuays) {
        newQuays = rawQuays.map((quay) => ({
          ...quay,
          id: normalizeKey(quay.id),
          name: quay.name.toUpperCase(),
          isQuay: true,
        }));
        useOrderStore.setState({ quays: newQuays });
        isFetchingQuays = false;
      }
    }
  }

  const staticRows = STATIC_TIMELINE_ROWS.map((row) => ({
    ...row,
    id: normalizeKey(row.id),
  }));

  setAllRows([...newQuays, ...staticRows]);

  const visibleRows = [];
  if (isRowVisible("104")) {
    visibleRows.push({
      id: "not allocated",
      isQuay: true,
      name: "NOT ALLOCATED",
    });
    visibleRows.push(...newQuays);
  }

  const filteredStaticRows = staticRows.filter((row) => isRowVisible(row.id));
  visibleRows.push(...filteredStaticRows);

  setVisibleRows(visibleRows);
};

export const setApolloClient = (initialApolloClient) => {
  _apolloClient = initialApolloClient;
  calculateAndSetVisibleRows();
  refreshTimeline();
};

useSettingsStore.subscribe(
  (state) => state.selectedDate,
  () => {
    // If refreshing the timeline takes some seconds, we want the current allocations to be "placed" correctly in the timeline.
    stackOrders();
    refreshTimeline();
  },
);

useSettingsStore.subscribe(
  (state) => state.selectedFacility,
  () => {
    console.error("change on selectedFacility");
    refreshTimeline();
  },
);

useSettingsStore.subscribe(
  (state) => state.selectedStatus,
  async () => {
    stackOrders();
  },
);

useSettingsStore.subscribe(
  (state) => state.selectedOrderType,
  async () => {
    await calculateAndSetVisibleRows();
    stackOrders();
  },
);

useSettingsStore.subscribe(
  (state) => state.allCollapsed,
  async () => {
    renormalizeVessels();
    stackOrders();
  },
);

useSettingsStore.subscribe(
  (state) => state.inDetailedView,
  async () => {
    await calculateAndSetVisibleRows();
    stackOrders();
  },
);
