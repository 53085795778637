/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from "react";
import Select, { SingleValue } from "react-select";

import CustomMenu from "./menuSelectCustom";

interface Props {
  onChange: (val: SingleValue<Element>) => void;
  value: any;
  options?: any;
  placeholder?: string;
  disabled?: boolean;
  isMulti?: boolean;
  onBlur?: (e: React.FocusEvent) => void;
  error?: boolean;
  errorMessage?: string;
}

type Element = { value: string; label: string };

const CustomSelect: FC<Props> = ({
  onChange,
  value,
  options = [{ value: "", label: "" }],
  placeholder = "",
  disabled = false,
  isMulti = false,
  onBlur = () => null,
  error = false,
  errorMessage = "",
}) => {
  const [isOpen, setOpen] = useState(false);
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: state.isDisabled
        ? "white"
        : isOpen
          ? "#FFFFFF"
          : "#F7F7F7",
      borderColor:
        // eslint-disable-next-line no-nested-ternary
        !isOpen && error
          ? "#BB0000"
          : // eslint-disable-next-line no-nested-ternary
            state.isDisabled
            ? "#C4C4C4"
            : state.isFocused
              ? "#00ADBB"
              : "#F7F7F7",
      minWidth: "187px",
      padding: "0 0 0 2px",
      boxShadow: state.isFocused
        ? "0px 0px 2px rgba(90, 90, 90, 0.14), 0px 2px 2px rgba(90, 90, 90, 0.12), 0px 1px 3px rgba(90, 90, 90, 0.2);"
        : "none",
      cursor: "pointer",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "18px",
      letterSpacing: "0.04em",
      color: "#2F2F2F",
      "&:hover": {
        borderColor: "#00ADBB",
        boxShadow:
          "0px 0px 2px rgba(90, 90, 90, 0.14), 0px 2px 2px rgba(90, 90, 90, 0.12), 0px 1px 3px rgba(90, 90, 90, 0.2);",
      },
    }),
    option: (styles: any, status: any) => {
      return {
        ...styles,
        color: status.isSelected ? "#2F2F2F" : "#5a5a5a",
        fontWeight: "bold",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: status.isSelected
          ? "#CAE6EA !important"
          : status.isFocused
            ? "#F0F0F0 !important"
            : "#fff",
      };
    },
  };
  return (
    <div>
      <Select
        inputId={placeholder}
        value={value}
        onChange={(val: SingleValue<Element>) => {
          onChange(val);
        }}
        styles={customStyles}
        options={options}
        className="select_custom"
        placeholder={placeholder}
        defaultValue={value}
        isDisabled={disabled}
        onBlur={onBlur}
        isMulti={isMulti}
        onMenuOpen={() => setOpen(true)}
        onMenuClose={() => setOpen(false)}
        components={{ Menu: CustomMenu }}
      />
      {error && (
        <p style={{ marginBlockEnd: "0" }} className="error-message">
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default CustomSelect;
