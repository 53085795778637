import React, { FC } from "react";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
  customStyles?: Record<string, unknown>;
};

const CircularLoading: FC<Props> = ({ customStyles = {} }) => {
  const baseStyles = {
    zIndex: 999,
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  };
  return (
    <CircularProgress
      size={24}
      color="secondary"
      style={{ ...baseStyles, ...customStyles }}
    />
  );
};

export default CircularLoading;
