import React from "react";

import styles from "./index.module.scss";
import textStyles from "@:lite/style/textStyles.module.scss";

type Props = { children: string; height: number };

const TableRowLabel: React.FC<Props> = ({ children, height }) => {
  return (
    <div style={{ height }} className={styles.rowName}>
      <span className={textStyles.primaryText}>{children}</span>
    </div>
  );
};

export default TableRowLabel;
