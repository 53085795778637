import React, { FC } from "react";
import { Switch } from "@mui/material";

const label = { inputProps: { "aria-label": "Switch demo" } };

type Props = {
  checked: boolean;
  onChange: () => void;
};
const BasicSwitch: FC<Props> = ({ checked, onChange }) => {
  return (
    <div>
      <Switch {...label} checked={checked} onChange={onChange} />
    </div>
  );
};

export default BasicSwitch;
