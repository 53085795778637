import { gql } from "@apollo/client";

export const DELETE_VESSEL_MUTATION = gql`
  mutation deleteVessel(
    $facilityId: String!
    $issuedBy: String!
    $vesselId: String!
  ) {
    deleteVessel(
      facilityId: $facilityId
      issuedBy: $issuedBy
      vesselId: $vesselId
    )
  }
`;
