import React from "react";

import {
  AssignmentLinkTooltip,
  LiveDomainEvents,
} from "@:lite/components/rootComponent";
import TimelineComponent from "@:lite/components/timeline/timeline";

const Dashboard: React.FC = () => {
  return (
    <>
      <TimelineComponent />
      <LiveDomainEvents />
      <AssignmentLinkTooltip />
    </>
  );
};

export default Dashboard;
